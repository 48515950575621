import React from "react";
import { FlexContainer, H2, H3, H4 } from "../../../Styles/Global.Styled";
import ClientsReviews from "../../../Components/ClientsReviews/ClientsReviews";
import { clientsReviewsSample } from "../../../data";
import InvisibleCarousel from "../../../Components/Carousel/InvisibleCarousel";

const TestimonialsSection = () => {
  return (
    <FlexContainer $flexD="column" $padding="5vh 0" $gap="3vh" $align="center">
      <H3 $fSize="3vh" $color="main" $fWeight="400">
        Testimonials
      </H3>
      <H2 $fSize="3.5vh" $color="black">
        What Our Partners Say
      </H2>
      <H4
        $width="45vw"
        $mWidth="90vw"
        $fSize="2.25vh"
        $color="thirdColor"
        $fWeight="400"
      >
        Discover the inspiring stories of our satisfied customers, where joy
        blossoms and excellence knows no bounds.
      </H4>
      <InvisibleCarousel
        items={clientsReviewsSample}
        Component={ClientsReviews}
      />
    </FlexContainer>
  );
};

export default TestimonialsSection;
