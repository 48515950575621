import React from "react";
import { Dot, FlexContainer, H2, TxtSpan } from "../../Styles/Global.Styled";
import { PiFlowerFill } from "react-icons/pi";
import { findMatchRGB } from "../../helpers/utilis";

const SpecsProduct = ({ product }) => {
  const matchingRGB = findMatchRGB(product["RHS main color flower"]);
  const productsSpecs = [
    { label: "Genus", value: product["Genus (type)"] },
    { label: "Cultivar", value: product["Cultivar (variant)"] },
    {
      label: "Color",
      value: (
        <>
          <PiFlowerFill color={matchingRGB} />
          {product["RHS main color flower"] + " - "}
          {product["Main color flower"]}
        </>
      ),
    },
    {
      label: "Height",
      value: `${product.minHeight} - ${product.maxHeight} cm`,
    },
    {
      label: "Diameter",
      value: "Not available",
    },
  ];

  return (
    <FlexContainer $flexD="column" $padding='0 0 0 2vw' $gap='.5vh'>
      <H2 $color="secColor" $fSize="1vw" $mFSize='4vw'>
        Specifications
      </H2>
      {productsSpecs.map((spec, index) => (
        <FlexContainer key={index} $align="center">
          <Dot $width=".75vh" $height=".75vh" />
          <TxtSpan $width="7.5vw" $mWidth="25vw" $mFSize="4vw">
            {spec.label}
          </TxtSpan>
          <TxtSpan $align="center">
            {spec.value}
          </TxtSpan>
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};

export default SpecsProduct;
