import React, { useState, createContext } from "react";
import { useEffect } from "react";
import { productsSample } from "../data";

const initialState =
  JSON.parse(localStorage.getItem("products")) || productsSample || [];
export const ProductsContext = createContext(initialState);

export const ProductsContextProvider = (props) => {
  const [products, setProducts] = useState(initialState);

  useEffect(() => {
    localStorage.setItem("products", JSON.stringify(products));
  }, [products]);

  return (
    <ProductsContext.Provider
      value={{ products: products, setProducts }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};
