import React from "react";
import {
  FlexContainer,
  FlexH4,
  H3,
  H4,
  Paragraph,
  TxtSpan,
} from "../../Styles/Global.Styled";
import TwitterIcon from "../../Components/Icons/TwitterIcon";
import FacebookIcon from "../../Components/Icons/FacebookIcon";
import InstagramIcon from "../../Components/Icons/InstagramIcon";
import LocationIcon from "../../Components/Icons/LocationIcon";
import EnvelopeIcon from "../../Components/Icons/CheckmarkIcon";
import PhoneIcon from "../../Components/Icons/PhoneIcon";

export const Footer = () => {
  return (
    <FlexContainer
      $mFlexD='column'
      $bgColor="bgColor"
      $padding="5vh 7.5%"
      $mPadding="5vh 2.5%"
      $justifyC="space-between"
      $mGap="2vh"
    >
      <FlexContainer $flexD="column" $width="30%" $mWidth="auto" $gap="1vh" $justifyC="center">
        <TxtSpan $color='main' $fSize="1.75vw" $mFSize="7.5vw" $fWeight="600">
          Fresh Flower Supply
        </TxtSpan>
        <Paragraph $fSize=".95vw" $mFSize="3.5vw">
          At Fresh Flowers Supply, we are more than just florist, we are floral
          enthusiasts dedicated to bringing the beauty of nature to your
          doorstep.
        </Paragraph>
        <FlexContainer $height="5vh" $gap="2%" $padding="0 0 0 2%">
          <FacebookIcon
            style={{
              width: "3vh",
              height: "3vh",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: ".5vh",
            }}
          />
          <TwitterIcon
            style={{
              width: "3vh",
              height: "3vh",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: ".5vh",
            }}
          />
          <InstagramIcon
            style={{
              width: "2vh",
              height: "3vh",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: ".5vh 1vh",
            }}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer $width="65%"  $mWidth="auto" $justifyC='space-evenly' style={{flexWrap:'wrap'}}>
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400" >
            Home
          </H3>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Categories
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Our process
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            About us
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Featured Collection
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Become a Partner
          </H4>
        </FlexContainer>
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Shop
          </H3>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Visit our Shop
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Product Prices
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Featured Selection
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Products on Sale
          </H4>
        </FlexContainer>
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            About us
          </H3>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Our History
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Our Team
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Our Partners
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Join us
          </H4>
        </FlexContainer>
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Contact us
          </H3>
          <FlexH4 $fSize=".95vw" $mFSize="3.75vw" $fWeight="300" $align="center" $gap="5%">
            <LocationIcon style={{ height: "1.75vh", width: "1.75vh" }} /> Find
            us
          </FlexH4>
          <FlexH4 $fSize=".95vw" $mFSize="3.75vw" $fWeight="300" $align="center" $gap="5%">
            <PhoneIcon style={{ height: "1.75vh", width: "1.75vh" }} /> Call us
          </FlexH4>
          <FlexH4 $fSize=".95vw" $mFSize="3.75vw" $fWeight="300" $align="center" $gap="5%">
            <EnvelopeIcon style={{ height: "1.75vh", width: "1.75vh" }} /> Email
            us
          </FlexH4>
        </FlexContainer>
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Get a Qoute
          </H3>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Request a Qoute
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Become a Partner
          </H4>
          <H4 $txtAlign="left" $fSize=".95vw" $mFSize="3.75vw" $fWeight="300">
            Login
          </H4>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
