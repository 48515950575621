import styled from "styled-components";
import { css } from "styled-components";
import { fontSizes, sizes, theme } from "./theme";

export const FlexContainer = styled.div`
  border: ${({ $border }) => $border};
  box-sizing: border-box;
  position: relative;
  display: ${({ $display }) => $display || "flex"};
  flex-direction: ${({ $flexD }) => $flexD};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  width: ${({ $width }) => $width};
  max-width: ${({ $maxW }) => $maxW};
  min-width: ${({ $minW }) => $minW};
  height: ${({ $height }) => $height};
  flex-grow: ${({ $flexG }) => $flexG};
  gap: ${({ $gap }) => $gap};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  color: ${({ theme, $color }) => theme[$color]};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  border-radius: ${({ $bRadius }) => $bRadius};
  box-shadow: ${({ $bShadow }) => $bShadow};
  /* overflow: ${({ $overflow }) => $overflow || "hidden"}; */
  @media (max-width: ${sizes.tablet}) {
    border: ${({ $mBorder }) => $mBorder};
    display: ${({ $mDisplay }) => $mDisplay};
    align-items: ${({ $mAlign }) => $mAlign};
    justify-content: ${({ $mJustifyC }) => $mJustifyC};
    flex-direction: ${({ $mFlexD }) => $mFlexD};
    font-size: ${({ $mFSize }) => $mFSize};
    gap: ${({ $mGap }) => $mGap};
    margin: ${({ $mMargin }) => $mMargin};
    padding: ${({ $mPadding }) => $mPadding};
    width: ${({ $mWidth }) => $mWidth};
    max-width: ${({ $mMaxW }) => $mMaxW};
    min-width: ${({ $mMinW }) => $mMinW};
    height: ${({ $mHeight }) => $mHeight};
  }
`;
export const FlexDivWithBG = styled(FlexContainer)`
  ${({ $bgImage }) =>
    $bgImage
      ? css`
          background-image: ${({ $bgImage }) => `url(${$bgImage})`};
          background-size: ${({ $imageS }) => $imageS || "cover"};
          background-position: center center;
        `
      : null}
`;
export const ScrollContainer = styled(FlexContainer)`
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1vh;
    /* width: 50vw; */
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  }
  /* Set the color and size for the scrollbar thumb (handle) */
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.secColor}; /* Set the color of the scrollbar thumb */
    border-radius: 50px; /* Set the border radius for a rounded appearance */
    height: 10vh;
  }
`;
export const GridContainer = styled.div`
  border: ${({ $border }) => $border};
  display: grid;
  grid-template-columns: ${({ $colCount }) => $colCount || "repeat(3, 1fr)"};
  grid-template-rows: ${({ $rowCount }) => $rowCount};
  /* grid-template-columns: repeat(3, 1fr); */
  gap: ${({ $gap }) => $gap};
  @media (max-width: ${sizes.tablet}) {
    grid-template-columns: ${({ $mColCount }) =>
      $mColCount || "repeat(3, 1fr)"};
  }
  gap: ${({ $mGap }) => $mGap};
`;
export const AbsFlexContainer = styled(FlexContainer)`
  position: absolute;
  display: ${({ $display }) => $display};
  align-items: ${({ $align }) => $align || "center"};
  justify-content: ${({ $justifyC }) => $justifyC || "center"};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
  bottom: ${({ $bottom }) => $bottom};
  color: white;
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay};
  }
`;
// export const StickyFlexContainer = styled(FlexContainer)`
//   position: sticky;
//   top: 0;
//   z-index: 5;
// `;

export const LogoImage = styled.img`
  /* border:2px solid red; */
  height: 100%;
  aspect-ratio: 1/1;
`;
export const ImgContainer = styled(LogoImage)`
  object-fit: cover;
`;
export const FlexImg = styled.img`
  border: ${({ $border }) => $border};
  box-shadow: ${({ $bShadow }) => $bShadow};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  aspect-ratio: ${({ $aspectR }) => $aspectR};
  border-radius: ${({ $bRadius }) => $bRadius};
  object-fit: ${({ $objectFit }) => $objectFit || "cover"};
  padding: ${({ $padding }) => $padding};
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay};
    padding: ${({ $mPadding }) => $mPadding};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;

export const CustomButton = styled.button`
  border: ${({ $border }) => $border || "none"};
  position: relative;
  font-family: "Poppins", sans-serif;
  display: ${({ $display }) => $display || "flex"};
  align-items: ${({ $align }) => $align || "center"};
  justify-content: ${({ $justifyC }) => $justifyC || "center"};
  flex-direction: ${({ $flexD }) => $flexD};
  font-size: ${({ $fontSize, $fSize }) => fontSizes[$fontSize] || $fSize};
  font-weight: ${({ $fWeight }) => $fWeight};
  border-radius: ${({ $bRadius }) => $bRadius};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding || 0};
  margin: ${({ $margin }) => $margin};
  color: ${({ $color }) => $color || "black"};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor] || "inherit"};
  gap: ${({ $gap }) => $gap};
  box-shadow: ${({ $bShadow }) => $bShadow};
  cursor: pointer;
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay || "flex"};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
    font-size: ${({ $mFSize }) => $mFSize};
    gap: ${({ $mGap }) => $mGap};
  }

  ${({ $bgImage }) =>
    $bgImage
      ? css`
          background-image: ${({ $bgImage }) => `url(${$bgImage})` || null};
          background-size: cover;
          background-position: center center;
        `
      : null}
`;
export const MainButton = styled(CustomButton)`
  color: #ffffff;
  background: #008000;
  box-shadow: 0 0 2px ${theme.shadowColor};
`;
export const SecButton = styled(CustomButton)`
  color: #008000;
  border: 2px solid #008000;
`;
export const InlineButton = styled(CustomButton)`
  /* border:none; */
  text-decoration: ${({ $underL }) => ($underL ? "underline" : "none")};
  /* text-decoration: underline; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AbsButton = styled(InlineButton)`
  position: absolute;
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  @media (max-width: ${sizes.tablet}) {
    top: ${({ $mTop }) => $mTop};
    right: ${({ $mRight }) => $mRight};
    left: ${({ $mLeft }) => $mLeft};
    bottom: ${({ $mBottom }) => $mBottom};
  }
`;

export const H1 = styled.h1`
  font-size: 58px;
  margin: ${({ $margin }) => $margin || 0};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight};
  text-align: ${({ $txtAlign }) => $txtAlign};
  color: ${({ theme, $color }) => theme[$color]};
  font-size: ${({ $fSize }) => $fSize};
  @media (max-width: ${sizes.tablet}) {
    text-align: ${({ $mTxtAlign }) => $mTxtAlign};
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const H2 = styled.h2`
  margin: ${({ $margin }) => $margin || 0};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight};
  color: ${({ theme, $color }) => theme[$color] || "#008000"};
  text-align: ${({ $txtAlign }) => $txtAlign};
  font-size: ${({ $fSize }) => $fSize};
  @media (max-width: ${sizes.tablet}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const H3 = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  width: ${({ $width }) => $width || null};
  height: ${({ $height }) => $height || null};
  margin: ${({ $margin }) => $margin || 0};
  font-weight: ${({ $fWeight }) => $fWeight};
  color: ${({ $color, theme }) => theme[$color]};
  @media (max-width: ${sizes.tablet}) {
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
  }
`;
export const H4 = styled.h4`
  font-family: "Poppins", sans-serif;
  text-align: ${({ $txtAlign }) => $txtAlign || "center"};
  color: ${({ theme, $color }) => ($color ? theme[$color] : theme.secColor)};
  font-weight: ${({ $fWeight }) => $fWeight || 600};
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  width: ${({ $width }) => $width || null};
  height: ${({ $height }) => $height || null};
  margin: ${({ $margin }) => $margin || 0};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  @media (max-width: ${sizes.tablet}) {
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
  }
`;
export const FlexH4 = styled(H4)`
  display: flex;
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  gap: ${({ $gap }) => $gap};
  @media (max-width: ${sizes.tablet}) {
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;

export const Form = styled.form`
  border: ${({ $border }) => $border};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: ${({ $flexD }) => $flexD || "column"};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  gap: ${({ $gap }) => $gap};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  border-radius: ${({ $bRadius }) => $bRadius};
  box-shadow: ${({ $bShadow }) => $bShadow};
  @media (max-width: ${sizes.tablet}) {
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
    gap: ${({ $mGap }) => $mGap};
  }
`;
export const InputSearch = styled.input`
  /* border:2px solid #008000; */
  /* display:flex; */
  border: ${({ $border }) => $border || "none"};
  padding: ${({ $padding }) => $padding};
  box-sizing: border-box;
  flex-grow: 1;
  flex-grow: ${({ $flexG }) => $flexG || 1};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height || "100%"};
  background-color: inherit;
  font-size: 24px;
  outline: none;
  &::placeholder {
    color: #008000;
  }
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;
export const CheckBoxInput = styled.input`
  flex-grow: 0;

  &:checked {
    background-color: #008000; /* Background color when checked */
    background-color: red; /* Background color when checked */
    border-color: #008000; /* Border color when checked */
  }

  /* Hover state */
  &:hover {
    cursor: pointer;
  }
`;
export const Input = styled.input`
  box-sizing: border-box;
  display: flex;
  align-items: ${({ $align }) => $align || "center"};
  justify-content: ${({ $justifyC }) => $justifyC};
  border: ${({ $border }) => $border || "none"};
  padding: ${({ $padding }) => $padding};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height || "100%"};
  background-color: inherit;
  font-size: 1vw;
  outline: none;
  border-radius: ${({ $bRadius }) => $bRadius};
  text-align: ${({ $txtAlign }) => $txtAlign};
  &::placeholder {
    color: #008000;
  }
  -webkit-appearance: textfield; /* WebKit browsers */
  -moz-appearance: none; /* Firefox */
  appearance: textfield; /* Standard */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay};
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;
export const Label = styled.label`
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight};
  font-size: ${({ $fSize }) => $fSize};
  color: ${({ theme, $color }) => theme[$color] || theme.thirdColor};
`;
export const DynamicLabel = styled.label`
  display: flex;
  align-items: center;
  z-index: ${({ $zIndex }) => $zIndex || 0};
  position: ${({ $position }) => $position};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight || 200};
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  padding: ${({ $padding }) => $padding};
  height: ${({ $height }) => $height};
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
`;
export const Select = styled.select`
  box-sizing: border-box;
  border: ${({ $border }) => $border};
  font-family: "Poppins", sans-serif;
  flex-grow: 1;
  height: 100%;
  border-radius: 6px;
  font-weight: ${({ $fWeight }) => $fWeight};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  font-size: ${({ $fSize }) => $fSize};
  box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.shadowColor};
  color: ${({ theme, $color }) => theme[$color] || theme.color4};
  @media (max-width: ${sizes.tablet}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const TextArea = styled.textarea`
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.medium};
  padding: ${({ $padding }) => $padding};
  border-radius: ${({ $bRadius }) => $bRadius || "6px"};
  font-size: ${({ $fSize }) => $fSize};
  /* line-height: 1.5; */
  white-space: pre-line;
  outline: none;
  resize: none;
  /* transition: border 0.3s; */
  /* border: 1px solid ${({ theme }) => theme.color1}; */
`;

export const Paragraph = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: ${({ $fWeight }) => $fWeight};
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  margin: 0;
  color: #2d2d2d;
  text-align: ${({ $txtAlign }) => $txtAlign};
  color: ${({ theme, $color }) => theme[$color] || theme.thirdColor};
  @media (max-width: ${sizes.tablet}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const TxtSpan = styled.span`
  font-family: "Poppins", sans-serif;
  display: ${({ $display }) => $display || "flex"};
  align-items: ${({ $align }) => $align || "center"};
  color: ${({ $color, theme }) => theme[$color] || "black"};
  font-size: ${({ $fSize }) => $fSize};
  font-weight: ${({ $fWeight }) => $fWeight};
  font-style: ${({ $fStyle }) => $fStyle};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay};
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
  }
`;
export const AbsTxtSpan = styled(TxtSpan)`
  position: absolute;
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
`;

export const CompanyName = styled.span`
  /* color: ${({ $color, theme }) => $color}; */
  color: #008000;
  font-size: ${({ $fontSize, $fSize }) =>
    $fontSize ? fontSizes[$fontSize] : $fSize};
  font-weight: ${({ $fWeight }) => $fWeight};
`;

export const Arrow = styled.div`
  border-style: solid;
  border-color: ${({ $bColor }) => $bColor || "#008000"};
  border-width: ${({ $bWidth }) =>
    $bWidth ? `0 ${$bWidth} ${$bWidth} 0` : "0 3px 3px 0"};
  display: inline-block;
  padding: ${({ $padding }) => $padding || "10px"};
  margin: ${({ $margin }) => $margin};
  @media (max-width: ${sizes.tablet}) {
    display: ${({ $mDisplay }) => $mDisplay};
    padding: ${({ $mPadding }) => $mPadding};
    border-width: ${({ $mbWidth }) =>
    $mbWidth ? `0 ${$mbWidth} ${$mbWidth} 0` : "0 3px 3px 0"};
  };
  ${({ $direction }) =>
    $direction === "right"
      ? css`
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        `
      : $direction === "left"
      ? css`
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        `
      : $direction === "up"
      ? css`
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        `
      : $direction === "down"
      ? css`
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        `
      : null}
`;
export const Line = styled.div`
  border-style: solid;
  border-top: none;
  border-width: ${({ $bWidth }) => $bWidth || "2px"};
  border-color: ${({ $bColor }) => $bColor || "#008000"};
  width: ${({ $width }) => $width || "100%"};
  height: ${({ $height }) => $height || "0"};
  border-radius: 75px;
  margin: ${({ $margin }) => $margin};
`;
export const Dot = styled.div`
  width: ${({ $width }) => $width || "10px"};
  height: ${({ $height }) => $height || "10px"};
  background-color: ${({ $active, theme }) =>
    $active ? theme.main : theme.shadowColor};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;
export const IndexedDot = styled.div`
  position: absolute;
  padding: ${({ $padding }) => $padding};
  font-size: ${({ $fontSize, $fSize }) => fontSizes[$fontSize] || $fSize};
  border-radius: 50%;
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
  color: white;
  background-color: ${({ theme }) => theme.main};
  width: ${({ $width }) => $width || "2vh"};
  height: ${({ $height }) => $height || "2vh"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IsRequiredStar = styled.span`
  /* z-index: 2; */
  color: red !important;
  /* font-weight: bold; */
  &::before {
    content: "*";
  }
`;
