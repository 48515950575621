import React from "react";
import { CustomButton, FlexContainer } from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";

const OptionsInput = ({ options, selected }) => {
  return (
    <FlexContainer $flexD="column" $padding="0 2vw" $mGap='1vh'>
      {options.map((option) => (
        <CustomButton $justifyC="flex-start" $mFSize="4vw" $mGap='1.5vw'>
          <FlexContainer
            $border={`1px solid ${theme.secColor}`}
            $bRadius="50%"
            $width="4vw"
            $height="4vw"
            $align="center"
            $justifyC="center"
          >
            <FlexContainer 
            $bRadius="50%"
            $width="2vw"
            $height="2vw"
            $bgColor={selected===option ? "main" : null}/>
          </FlexContainer>
          {option}
        </CustomButton>
      ))}
    </FlexContainer>
  );
};

export default OptionsInput;
