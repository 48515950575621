import React from "react";
import { FlexContainer } from "../../Styles/Global.Styled";
import NavBar from "../NavBar/NavBar";
import SectionOne from "./SubSections/SectionOne";
import CompanyPromo from "./SubSections/CompanyPromo";
import FeatureProduct from "./SubSections/FeatureProduct";
import SignUpPromo from "./SubSections/SignUpPromo";
import ProcessSection from "./SubSections/ProccessSection";
import Footer from "../Footer/Footer";
import MobileNavBar from "../NavBar/MobileNavBar";

const Home = ({ menuOpen, setOpen }) => {
  return (
    <>
      {menuOpen ? <MobileNavBar setOpen={setOpen} /> : null}
      <FlexContainer $flexD="column">
        <NavBar setOpen={setOpen} />
        <SectionOne />
        <ProcessSection />
        <CompanyPromo />
        <FeatureProduct />
        <SignUpPromo />
        <Footer />
      </FlexContainer>
    </>
  );
};

export default Home;
