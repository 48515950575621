import React from "react";
import { AbsButton, Arrow } from "../../Styles/Global.Styled";
import { useNavigate } from "react-router-dom";

const GoBackButton = ({ top, mTop, mLeft }) => {
  const navigate = useNavigate();
  return (
    <AbsButton
      $top={top}
      $mTop={mTop}
      $fSize=".95vw"
      $mFSize="3.5vw"
      // $right={right}
      // $bottom={bottom}
      $mLeft={mLeft}
      onClick={()=>navigate(-1)}
      $padding=".5vh"
      // $border='2px solid red'
    >
      <Arrow
        $direction="left"
        $padding="10%"
        $bWidth="2px"
        $bColor="black"
      />
      Back
    </AbsButton>
  );
};

export default GoBackButton;
