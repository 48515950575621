import React from "react";
import { FlexDivWithBG, H1, H2 } from "../../../Styles/Global.Styled";
import rectangle8 from "../../../assets/flowersPictures/rectangle8.png";
import { theme } from "../../../Styles/theme";

const LandingSection = () => {
  return (
    <FlexDivWithBG
      // $height="30vh"
      $padding="10vh 0"
      $bgImage={rectangle8}
      $flexD="column"
      $align="center"
      $justifyC="center"
      $bShadow={`0px 0px 4px ${theme.shadowColor}`}
    >
      <H1 $fSize="1.5vw" $mFSize="5vw">
        About us
      </H1>
      <H2 $mFSize="3vw">Wholesale Blooms, Business Booms</H2>
      <H1 $mFSize="6vw">Fresh Flowers Supply</H1>
      {/* <FlexContainer
        $width="35%"
        $height="5vh"
        $align="center"
        $border="2px solid #008000"
        $padding="0 1%"
        $margin="1% 0"
        $bRadius="6px"
      >
        <InputSearch placeholder="What are you looking for?" />
        <FaMagnifyingGlass
          color="#008000"
          style={{ height: "50%", width: "10%" }}
        />
      </FlexContainer> */}
      {/* <FlexContainer $width="35%" $height="5vh" $justifyC="space-between">
        <MainButton $width="47%" $bRadius="25px" $fSize="20px">
          Shop now
        </MainButton>
        <SecButton $width="47%" $bRadius="25px" $fSize="20px">
          Get a Qoute
        </SecButton>
      </FlexContainer> */}
    </FlexDivWithBG>
  );
};

export default LandingSection;
