import React, { useContext } from "react";
import { FlexContainer, H1 } from "../../../Styles/Global.Styled";
import { CartContext } from "../../../appContext/CartContext";
import ProductReview from "../../../Components/Product/ProductReview";

const CartSection = () => {
    const {cart} = useContext(CartContext)
  return (
    <FlexContainer  $gap='2vh' $flexD="column">
      <H1 $fSize="2vh" $fWeight="400">
        In your Basket
      </H1>
      {cart.map((item)=>(
        <ProductReview product={item}/>
      ))}
    </FlexContainer>
  );
};

export default CartSection;
