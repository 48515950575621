import React, { useState } from "react";
import {
  Dot,
  FlexContainer,
  FlexH4,
  TxtSpan,
} from "../../Styles/Global.Styled";

const ExtraProductSpecs = ({ product }) => {
  const [open, setOpen] = useState(false);
  const keysToExclude = [
    "Genus (type)",
    "Cultivar (variant)",
    "RHS main color flower",
    "Main color flower",
    "Color",
    "minHeight",
    "maxHeight",
    "Plant height",
    "Flower diameter",
    "Product image",
    "Product name",
    "Product abbreviation",
    "Registrator",
    "Registration Code",
    "Group",
    "Group code",
    "Selling units",
    "Availability",
  ];

  // $border="2px solid red"
  // style={{ border: "4px solid red" }}

  return (
    <FlexContainer $flexD="column" $padding="0 0 0 2vw" $gap=".5vh">
      <FlexH4
        $color="secColor"
        $fSize="1vw"
        $mFSize="4vw"
        $justifyC="space-between"
        $margin="0 3vw 0 0"
      >
        Additional details
        <TxtSpan
          $display="none"
          $mDisplay="flex"
          onClick={() => setOpen(!open)}
        >
          {open ? "-" : "+"}
        </TxtSpan>
      </FlexH4>
      {Object.entries(product)
        .filter(([key, value]) => value && !keysToExclude.includes(key))
        .map(([key, value]) => (
          <FlexContainer $align="center" $mDisplay={!open ? "none" : null}>
            <Dot $width=".75vh" $height=".75vh" />
            <TxtSpan $width="7.5vw" $mFSize="3.5vw" $mWidth="35vw">
              {key}
            </TxtSpan>
            {console.log("value length", key, value.length, 22 / value.length)}
            <TxtSpan
              $fSize={`${value.length > 70 ? 0.6 : 0.85}vw`}
              $mFSize={`${value.length > 70 ? 0.6 : 3.5}vw`}
              $mWidth="70vw"
              $align="center"
              // style={{ border: "2px solid blue" }}
            >
              {value}
            </TxtSpan>
          </FlexContainer>
        ))}
    </FlexContainer>
  );
};

export default ExtraProductSpecs;
