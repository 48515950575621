import React from "react";
import { theme } from "../../Styles/theme";
import {
  DynamicLabel,
  FlexContainer,
  IsRequiredStar,
  TextArea,
} from "../../Styles/Global.Styled";

const TextAreaInput = ({ width, height, required, label, value, setForm }) => {
  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    // if (errors !== undefined) {
    //   runValidation(e);
    // }
  };

  const charCount = value.length;
  // const isOverLimit = charCount > 200;
  return (
    <FlexContainer
      $width={width}
      $height={height}
      $padding="0 1vw"
      // $border="2px solid"
    >
      <TextArea
        id={label}
        $width="100%"
        $border={`1px solid ${theme.secColor}`}
        placeholder="Questions, comments, or feedback? Type your message here...."
        $padding="1vh .5vw"
        $fSize="1.5vh"
        onChange={handleChange}
        // onBlur={runValidation}
      />
      <DynamicLabel
        $position="absolute"
        // style={{ border: "2px solid red" }}
        $height="1.5vh"
        $top="-1vh"
        $left="1.5vw"
        $fSize="1.5vh"
        $zIndex="1"
        $bgColor="white"
        >
        {label}
        {required ? <IsRequiredStar /> : null}
      </DynamicLabel>
      <DynamicLabel
        $position="absolute"
        $right="1.5vw"
        $bottom="-1vh"
        $zIndex="1"
        $fSize="1.5vh"
        $bgColor="white"
      >
        {`${charCount}/250 characters`}
      </DynamicLabel>
    </FlexContainer>
  );
};

export default TextAreaInput;
