import React from "react";
import {
  FlexContainer,
  FlexImg,
  H3,
  H4,
  Paragraph,
} from "../../Styles/Global.Styled";
import styled from "styled-components";
import { theme } from "../../Styles/theme";

export const InlineBlock = styled(FlexContainer)`
  display: inline-block;
`;

const ClientsReviews = ({
  image = "",
  reviewText = "",
  name = "",
  title = "",
  item,
}) => {
  return (
    <FlexContainer
      $bShadow={`0px 0px 20px 0px ${theme.shadowColor}`}
      $width="50vw"
      $minW="40vw"
      $mWidth="85vw"
      $mMinW="85vw"
      // $height="35vh"
      $gap='2vh'
      $bRadius="50px"
      $padding="0 2vw 2vw 2vw"
      $mPadding="0 2vw 5vh 2vw"
      $flexD="column"
      $align="center"
      // $justifyC="space-between"
      // style={{ minWidth: "40vw" }}
      // $border='2px solid blue'
    >
      <FlexImg
        $height="10vh"
        $width="10vh"
        src={item.image}
        alt="Image"
        style={{ marginTop: "-5vh" }}
        $border="2px solid white"
        $bShadow={`0px 0px 20px 0px ${theme.shadowColor}`}
        $bRadius="50%"
      />

      <Paragraph
        $fSize="18px"
        $txtAlign="center"
        $width="100%"
        $height="10vh"
      >
        {item.reviewText}
      </Paragraph>
      <FlexContainer $flexD="column" $align="center">
        <H3 $color="main" $fSize="28px" $fWeight="400">
          {item.name}
        </H3>
        <H4>{item.jobTitle}</H4>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ClientsReviews;
