import React from "react";
import {
  FlexContainer,
  FlexH4,
  FlexImg,
  H3,
} from "../../../Styles/Global.Styled";
import shoppingCartFlowers from "../../../assets/IconPictures/shoppingCartFlowers.png";
import contractQoute from "../../../assets/IconPictures/contractQoute.png";
import articleWriting from "../../../assets/IconPictures/articleWriting.png";
import pickupCar from "../../../assets/IconPictures/pickupCar.png";
import LineArrow from "../../../Components/Arrows/LineArrow";

const ProcessStep = ({ icon, label }) => (
  <FlexContainer $flexD="column" $align="center" >
    <FlexContainer
      $align="center"
      $justifyC="center"
      $height="10vh"
      $width="10vh"
      $mHeight="6.5vh"
      $mWidth="6.5vh"
      $padding="2vh"
      $bRadius="50%"
      $border="2px solid #008000"
      $flexD="column"
      $gap="3vh"
    >
      <FlexImg src={icon} $width="6vh" $height="6vh" $mHeight="4vh" $mWidth="4vh" />
    </FlexContainer>
    <FlexH4
      $mFSize="2.5vw"
      $width="10vh"
      $mWidth="6.5vw"
      $align="center"
      $justifyC="center"
      $whiteSpace="nowrap"
    >
      {label}
    </FlexH4>
  </FlexContainer>
);

const ProcessSection = () => {
  const steps = [
    { icon: shoppingCartFlowers, label: "Add Products" },
    { icon: contractQoute, label: "Request a Quote" },
    { icon: articleWriting, label: "Review Quote" },
    { icon: pickupCar, label: "Accept & Ship" },
  ];

  return (
    <FlexContainer $flexD="column" $align="center" $padding="5vh">
      <H3 $fSize="48px" $mFSize="7.5vw" $margin="0 0 5vh 0">
        Our Process
      </H3>
      <FlexContainer $width="40vw" $mWidth="85vw" $align="center">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <ProcessStep {...step} />
            {index < steps.length - 1 && (
              <LineArrow direction="right" style={{ marginTop: "-2vh" }} />
            )}
          </React.Fragment>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProcessSection;
