import React, { useEffect, useState } from "react";
import {
  AbsButton,
  Arrow,
  Dot,
  FlexContainer,
} from "../../Styles/Global.Styled";
import { groupArrayByNumber } from "../../helpers/utilis";

const Carousel = ({ items = [], itemsPerSlide = 4, Component = "" }) => {
  console.log("items", items);
  const [slideIndex, setSlideIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);
  // const visibleItems = groupArrayByNumber(items, itemsPerSlide);

  useEffect(() => {
    const updateVisibleItems = () => {
      const screenWidth = window.innerWidth;
      let itemsPerSlide = 4;

      // Adjust itemsPerSlide for mobile view
      if (screenWidth <= 768) {
        itemsPerSlide = 1;
      }

      setVisibleItems(groupArrayByNumber(items, itemsPerSlide));
    };

    updateVisibleItems();

    // Update visible items when the window is resized
    window.addEventListener("resize", updateVisibleItems);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateVisibleItems);
    };
  }, [items]);

  const handleClick = (value) => {
    const newIndex = slideIndex + value;

    // Check if newIndex is out of bounds and adjust accordingly
    const adjustedIndex =
      newIndex < 0 ? visibleItems.length - 1 : newIndex % visibleItems.length;

    setSlideIndex(adjustedIndex);
  };

  return (
    <FlexContainer $flexD="column" $align="center" $gap="4vh">
      <FlexContainer
        $width="100%"
        $justifyC="space-between"
        $mJustifyC="center"
        $align="center"
      >
        <AbsButton
          $height="5vh"
          $width="5vh"
          $left="-7.5vh"
          $mLeft="-1vh"
          $bRadius="12px"
          onClick={() => handleClick(-1)}
        >
          <Arrow $direction="left" />
        </AbsButton>
        {visibleItems[slideIndex]?.map((item, index) => (
          <Component key={index} item={item} />
        ))}
        <AbsButton
          $height="5vh"
          $width="5vh"
          $bRadius="12px"
          $right="-7.5vh"
          $mRight="-1vh"
          onClick={() => handleClick(1)}
        >
          <Arrow $direction="right" />
        </AbsButton>
      </FlexContainer>
      <FlexContainer>
        {visibleItems?.map((_, index) => (
          <Dot
            key={index}
            $active={index === slideIndex}
            onClick={() => setSlideIndex(index)}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Carousel;
