import React from "react";
import { FlexColumn, FlexRow } from "../../Styles/Styles";
import { PiFlowerFill } from "react-icons/pi";
import { TxtSpan } from "../../Styles/Global.Styled";

const DescrShortProduct = ({ label, value, matchingObject }) => {
  return (
    <FlexColumn $width="auto" $justifyC="center">
      <TxtSpan $color="secColor" $mFSize='3vw' >
        {label}
      </TxtSpan>
      <FlexRow>
        {matchingObject && <PiFlowerFill color={matchingObject} />}
        <TxtSpan $color="secColor" $mFSize='3vw' >
          {value ? value : "-"}
        </TxtSpan>
      </FlexRow>
    </FlexColumn>
  );
};

export default DescrShortProduct;
