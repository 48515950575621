import React from "react";
import {
  CustomButton,
  FlexContainer,
  Label,
  Select,
} from "../../../Styles/Global.Styled";
import { theme } from "../../../Styles/theme";
import { sortByOptions } from "../../../data";
import { IoFilter } from "react-icons/io5";

const ShopHeader = ({setOpen}) => {
  return (
    <FlexContainer
      $align="center"
      $justifyC="flex-end"
      // $height="auto"
      $margin="0 0 5vh 0"
      $mMargin="0 0 2.5vh 0"
      // $border="4px solid red"
    >
      {/* <SecButton $height="50%" $bRadius="25px">
        filters show
      </SecButton> */}
      {/* <FlexContainer
        $border={`1px solid ${theme.main2}`}
        $align="center"
        $bRadius="6px"
        $height="5vh"
        $bShadow={`0px 0px 6px 0px ${theme.shadowColor}`}
      >
        <InputSearch
          placeholder="Search"
          $padding="1%"
          // $border="4px solid red"
        />
        <FaMagnifyingGlass
          color="#008000"
          style={{ height: "3vh", width: "5vh", padding: "1%" }}
        />
      </FlexContainer> */}
      <FlexContainer
        $gap=".5vw"
        $align="center"
        $width="20vw"
        $mWidth="auto"
        $height="5vh"
        $fSize="22px"
        $mDisplay="none"
      >
        <Label>Sort by:</Label>
        <Select $border="1px solid #008000" $fSize="20px">
          {sortByOptions.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </Select>
      </FlexContainer>
      <CustomButton
        $fSize="4vw"
        $gap="1vw"
        $bRadius="12px"
        $bShadow={`0px 0 2px ${theme.shadowColor} inset`}
        $display='none'
        $padding='.25vh 1vh'
        onClick={()=>setOpen(true)}
      >
        Filter
        <IoFilter style={{ height: "2vh", width: "2vh" }} />
      </CustomButton>
    </FlexContainer>
  );
};

export default ShopHeader;
