import React from 'react'
import { Arrow, FlexContainer, Line } from '../../Styles/Global.Styled'

const LineArrow = ({direction, style}) => {
  return (
    <FlexContainer $align='center' $flexG='1' style={style} >
        <Line style={{position:'absolute'}} $width='98%' />
        <Arrow $padding='7.5px' style={{position:'absolute', right:'0'}} $direction={direction}/>
    </FlexContainer>
  )
}

export default LineArrow