import React from "react";
import {
  FlexContainer,
  FlexH4,
  H3,
  H4,
  Paragraph,
  TxtSpan,
} from "../../Styles/Global.Styled";
import TwitterIcon from "../../Components/Icons/TwitterIcon";
import FacebookIcon from "../../Components/Icons/FacebookIcon";
import InstagramIcon from "../../Components/Icons/InstagramIcon";
import LocationIcon from "../../Components/Icons/LocationIcon";
import EnvelopeIcon from "../../Components/Icons/EnvelopeIcon";
import PhoneIcon from "../../Components/Icons/PhoneIcon";

const Footer = () => {
  return (
    <FlexContainer
      $mFlexD="column"
      $bgColor="bgColor"
      $padding="5vh 7.5%"
      $mPadding="5vh 2.5%"
      $justifyC="space-between"
      $mGap="2vh"
    >
      <FlexContainer
        $flexD="column"
        $width="32.5%"
        $mWidth="auto"
        $gap="1vh"
        $justifyC="center"
      >
        <TxtSpan $color="main" $fSize="1.75vw" $mFSize="7.5vw" $fWeight="600">
          Fresh Flower Supply
        </TxtSpan>
        <Paragraph $fSize=".95vw" $mFSize="3.5vw">
          At Fresh Flowers Supply, we are more than just florists; we are floral
          enthusiasts dedicated to bringing the beauty of nature to your
          doorstep.
        </Paragraph>
        <FlexContainer $height="5vh" $gap="2%" $padding="0 0 0 2%">
          <FacebookIcon
            style={{
              width: "3vh",
              height: "3vh",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: ".5vh",
            }}
          />
          <TwitterIcon
            style={{
              width: "3vh",
              height: "3vh",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: ".5vh",
            }}
          />
          <InstagramIcon
            style={{
              width: "2vh",
              height: "3vh",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: ".5vh 1vh",
            }}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        $width="65%"
        $mWidth="auto"
        $justifyC="space-evenly"
        style={{ flexWrap: "wrap" }}
      >
        {/* Section 1 */}
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Home
          </H3>
          {[
            "Categories",
            "Our process",
            "About us",
            "Featured Collection",
            "Become a Partner",
          ].map((item, index) => (
            <H4
              key={index}
              $txtAlign="left"
              $fSize=".95vw"
              $mFSize="3.75vw"
              $fWeight="300"
            >
              {item}
            </H4>
          ))}
        </FlexContainer>

        {/* Section 2 */}
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Shop
          </H3>
          {[
            "Visit our Shop",
            "Product Prices",
            "Featured Selection",
            "Products on Sale",
          ].map((item, index) => (
            <H4
              key={index}
              $txtAlign="left"
              $fSize=".95vw"
              $mFSize="3.75vw"
              $fWeight="300"
            >
              {item}
            </H4>
          ))}
        </FlexContainer>

        {/* Section 3 */}
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            About us
          </H3>
          {["Our History", "Our Team", "Our Partners", "Join us"].map(
            (item, index) => (
              <H4
                key={index}
                $txtAlign="left"
                $fSize=".95vw"
                $mFSize="3.75vw"
                $fWeight="300"
              >
                {item}
              </H4>
            )
          )}
        </FlexContainer>

        {/* Section 4 */}
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Contact us
          </H3>
          {[
            {
              icon: (
                <LocationIcon style={{ height: "1.75vh", width: "1.75vh" }} />
              ),
              text: "Find us",
            },
            {
              icon: <PhoneIcon style={{ height: "1.75vh", width: "1.75vh" }} />,
              text: "Call us",
            },
            {
              icon: (
                <EnvelopeIcon style={{ height: "1.75vh", width: "1.75vh" }} />
              ),
              text: "Email us",
            },
          ].map((item, index) => (
            <FlexH4
              key={index}
              $fSize=".95vw"
              $mFSize="3.75vw"
              $fWeight="300"
              $align="center"
              $gap="5%"
            >
              {item.icon} {item.text}
            </FlexH4>
          ))}
        </FlexContainer>

        {/* Section 5 */}
        <FlexContainer $flexD="column" $gap="1vh" $padding="3% 2%">
          <H3 $color="secColor" $fSize="1.5vw" $mFSize="6vw" $fWeight="400">
            Get a Quote
          </H3>
          {["Request a Quote", "Become a Partner", "Login"].map(
            (item, index) => (
              <H4
                key={index}
                $txtAlign="left"
                $fSize=".95vw"
                $mFSize="3.75vw"
                $fWeight="300"
              >
                {item}
              </H4>
            )
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Footer;
