import { css, keyframes } from "styled-components";

export const theme = {
  bgColor:"#f6f1f7",
  main:"#008000",
  white:"white",
  black:"#000000",
  adBg:"#849a72",
  secColor: "#353535",
  thirdColor: "#2D2D2D",
  sliderShadow: "rgba(217, 217, 217, 1)",
  color4: "#2B2B2B",
  main2: "#6D966D",
  red: 'red',
  shadowColor:'rgba(0, 0, 0, 0.25)',
  mainFont:"Poppins",
};

export const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "414px",
  tablet: "768px",
  laptop: "992px",
  laptopL: "1200px",
  desktop: "1440px",
  desktopL: "1680px",
  desktopXL: "1920px",
};

export const sizes0 = {
  mobileS: "2.22in",
  mobileM: "2.34in",
  mobileL: "2.75in",
  tablet: "8in",
  laptop: "13.33in",
  laptopL: "16in",
  desktop: "18.75in",
  desktopL: "21in",
  desktopXL: "24in",
};

export const fontSizes2 = {
  xxSmall: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 45%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 55%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 80%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 60%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 65%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 70%;
    }
  `,
  xSmall: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 55%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 65%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 90%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 70%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 75%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 80%;
    }
  `,
  small: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 65%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 75%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 80%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 85%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 90%;
    }
  `,
  medium: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 75%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 85%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 110%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 90%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 95%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 100%;
    }
  `,
  normal: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 85%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 95%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 120%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 100%;
    }
  `,
  large: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 105%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 130%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 115%;
    }
  `,
  xLarge: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 105%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 130%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 125%;
    }
  `,
  xxLarge: css`
    @media (max-width: ${sizes.mobileM}) {
      font-size: 110%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 115%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 140%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 140%;
    }
  `,
};

export const fontSizes = {
  xxSmall: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 45%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 55%;
    }
    @media (min-width: ${sizes.mobileL}) {
      font-size: 60%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 80%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 50%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 55%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 60%;
    }
    @media (min-width: ${sizes.desktopL}) {
      font-size: 65%;
    }
    @media (min-width: ${sizes.desktopXL}) {
      font-size: 70%;
    }
  `,
  xSmall: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 50%;
    }

    @media (min-width: ${sizes.mobileM}) {
      font-size: 60%;
    }

    @media (min-width: ${sizes.mobileL}) {
      font-size: 65%;
    }

    @media (min-width: ${sizes.tablet}) {
      font-size: 75%;
    }

    @media (min-width: ${sizes.laptop}) {
      font-size: 70%;
    }

    @media (min-width: ${sizes.laptopL}) {
      font-size: 75%;
    }

    @media (min-width: ${sizes.desktop}) {
      font-size: 80%;
    }

    @media (min-width: ${sizes.desktopL}) {
      font-size: 85%;
    }

    @media (min-width: ${sizes.desktopXL}) {
      font-size: 90%;
    }
  `,
  small: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 65%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 75%;
    }
    @media (min-width: ${sizes.mobileL}) {
      font-size: 80%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 85%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 90%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 95%;
    }
    @media (min-width: ${sizes.desktopL}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.desktopXL}) {
      font-size: 105%;
    }
  `,
  medium: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 75%;
    }

    @media (min-width: ${sizes.mobileM}) {
      font-size: 85%;
    }

    @media (min-width: ${sizes.mobileL}) {
      font-size: 95%;
    }

    @media (min-width: ${sizes.tablet}) {
      font-size: 115%;
    }

    @media (min-width: ${sizes.laptop}) {
      font-size: 100%;
    }

    @media (min-width: ${sizes.laptopL}) {
      font-size: 100%;
    }

    @media (min-width: ${sizes.desktop}) {
      font-size: 105%;
    }

    @media (min-width: ${sizes.desktopL}) {
      font-size: 110%;
    }

    @media (min-width: ${sizes.desktopXL}) {
      font-size: 115%;
    }
  `,
  large: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 85%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 95%;
    }
    @media (min-width: ${sizes.mobileL}) {
      font-size: 100%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 120%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 105%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 110%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 115%;
    }
    @media (min-width: ${sizes.desktopL}) {
      font-size: 120%;
    }
    @media (min-width: ${sizes.desktopXL}) {
      font-size: 125%;
    }
  `,
  xLarge: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 110%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 120%;
    }
    @media (min-width: ${sizes.mobileL}) {
      font-size: 125%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 140%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 130%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 135%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 140%;
    }
    @media (min-width: ${sizes.desktopL}) {
      font-size: 145%;
    }
    @media (min-width: ${sizes.desktopXL}) {
      font-size: 145%;
    }
  `,
  xxLarge: css`
    @media (max-width: ${sizes.mobileM})and (max-aspect-ratio: 4/5) {
      font-size: 115%;
    }
    @media (min-width: ${sizes.mobileM}) {
      font-size: 130%;
    }
    @media (min-width: ${sizes.mobileL}) {
      font-size: 135%;
    }
    @media (min-width: ${sizes.tablet}) {
      font-size: 165%;
    }
    @media (min-width: ${sizes.laptop}) {
      font-size: 140%;
    }
    @media (min-width: ${sizes.laptopL}) {
      font-size: 145%;
    }
    @media (min-width: ${sizes.desktop}) {
      font-size: 150%;
    }
    @media (min-width: ${sizes.desktopL}) {
      font-size: 155%;
    }
    @media (min-width: ${sizes.desktopXL}) {
      font-size: 160%;
    }
  `,
};

export const buttonColorSchemes = {
  main: css`
    background-color: ${({ theme }) => theme.color1};
    color: ${(props) => props.theme.color5};
    color: #1e2d2f;
    border-color: grey;
    border:none;
  `,
  cancel: css`
    background-color: ${(props) => props.theme.color3};
    color: ${({ theme }) => theme.color1};
    border-color: grey;
  `,
  inlineMain: css`
    background-color: inherit;
    color: ${({ theme }) => theme.color5};
    color: #1e2d2f;
    /* text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px; */
    border: none;
    /* border-color: ${(props) => props.theme.primary.main}; */
  `,
  inlineMain2: css`
    background-color: ${(props) => props.theme.shadow.main};
    color: ${(props) => props.theme.primary.main};
    border: none;
    /* border-color: ${(props) => props.theme.primary.main}; */
  `,
  inlineCancel: css`
    background-color: inherit;
    color: red;
    /* text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px; */
    border: none;
  `,
};

export const horzScrollbar = css`
  ::-webkit-scrollbar {
    height: 5px;
    /* background: #f1f1f1; */
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
  }
`;
export const FlashError = keyframes`
0%{
  transform: scale(1.01);
};
25%{
  transform: scale(1);
};
50%{
  transform: scale(1.02);
};
75%{
  transform: scale(1);
};
100%{
  transform: scale(1.04);
};
`;
