import React, { useContext } from "react";
import { FlexContainer, FlexImg, H1 } from "../../Styles/Global.Styled";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import { Footer } from "../Footer/OriginFooter";
import { ProductsContext } from "../../appContext/ProductsContext";
import SpecsProduct from "../../Components/Product/SpecsProduct";
import ExtraProductSpecs from "../../Components/Product/ExtraProductSpecs";
import ProductPricing from "../../Components/Product/ProductPricing";
import GoBackButton from "../../Components/Buttons/BackButton";

const ProductPage = () => {
  const { productCode } = useParams();
  const { products } = useContext(ProductsContext);

  // Use the provided product prop or find the product based on productCode
  const selectedProduct = products.find(
    (p) => p["Product code"] === parseInt(productCode)
  );

  return (
    <FlexContainer $flexD="column">
      <NavBar />
      <FlexContainer
        $padding="10vh 8%"
        $mPadding="6vh 0"
        $gap="1vw"
        $mGap="2vh"
        $mAlign="center"
        // $border="2px solid red"
        $mFlexD="column"
      >
        <GoBackButton top="3vh" mTop="1.5vh" mLeft="3vw" />
        <FlexImg
          alt="product image"
          src={selectedProduct["Product image"]}
          $height="25vw"
          $width="25vw"
          $mWidth="98vw"
          $mHeight="98vw"
          // $border='2px solid red'
        />
        <FlexContainer
          $flexD="column"
          $gap="2vh"
          $mGap="1vh"
          $width="35vw"
          $mWidth="98vw"
        >
          <H1 $fSize="1.75vw" $mFSize="5vw" $mTxtAlign="center">
            {selectedProduct["Product name"]}
          </H1>
          <SpecsProduct product={selectedProduct} />
          <ExtraProductSpecs product={selectedProduct} />
        </FlexContainer>
        <ProductPricing product={selectedProduct} />
      </FlexContainer>
      <Footer />
    </FlexContainer>
  );
};

export default ProductPage;
