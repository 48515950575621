import React, { useState } from "react";
import {
  FlexContainer,
  Form,
  H4,
  Line,
  MainButton,
} from "../../Styles/Global.Styled";
import TextInput from "../../Components/FormInputs/TextInput";
import { theme } from "../../Styles/theme";
import {
  isValidCity,
  isValidCompanyCode,
  isValidCompanyName,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  validateAddressLine1,
  validatePostcode,
  validateString,
} from "../../helpers/utilis";
import SelectInput from "../../Components/FormInputs/SelectInput";

const QouteForm = () => {
  const [formData, setForm] = useState({
    "Company name": "",
    "Registration No.": "",
    "First name": "",
    "Last name": "",
    Email: "",
    "Phone number": "",
    preferedContact: "",
    "Street Address": "",
    "City/Town": "",
    Postcode: "",
    Country: "",
    termsAndConditionsAgreement: false,
  });
  const [isValid, setIsValid] = useState({});

  const submitForm = (e) => {
    e.preventDefault();
    console.log("formData", formData);
    console.log("isValid", isValid);
    console.log(
      "Object.values(validationErrors).every((error) => !error);",
      Object.values(isValid).every((error) => !error)
    );
    console.log(
      "is form valid",
      Object.values(isValid).every(
        (error) => !error && Object.length(isValid) > 9
      )
    );
  };

  return (
    <Form $gap="3vh" onSubmit={submitForm}>
      {/* Company Details */}
      <FlexContainer $flexD="column" $gap="2.5vh">
        <H4 $fSize="2vh" $txtAlign $fWeight="400">
          Company Details
        </H4>
        <FlexContainer $width="100%" $gap=".5vw" $mFlexD='column' $mGap='3vh'>
          <TextInput
            height="5vh"
            width="65%"
            mWidth="auto"
            label="Company name"
            value={formData["Company name"]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={isValidCompanyName}
            errors={isValid["Company name"]}
          />
          <TextInput
            height="5vh"
            // width="auto"
            label="Registration No."
            required={false}
            value={formData["Registration No."]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={isValidCompanyCode}
            errors={isValid["Registration No."]}
          />
        </FlexContainer>
      </FlexContainer>
      <Line
        $bWidth="1px"
        $width="95%"
        $margin="0 auto"
        $bColor={`${theme.shadowColor}`}
      />
      {/* Business Address */}
      <FlexContainer $flexD="column" $gap="2.5vh" $margin="-1vh 0 0 0">
        <H4 $fSize="2vh" $txtAlign $fWeight="400">
          Business Address
        </H4>
        <TextInput
          height="5vh"
          width="100%"
          label="Street Address"
          value={formData["Street Address"]}
          setForm={setForm}
          setIsValid={setIsValid}
          isValid={validateAddressLine1}
          errors={isValid["Street Address"]}
        />
        <FlexContainer $gap=".5vw" $margin="1vh 0 0 0" $mFlexD='column' $mGap='3vh'>
          <TextInput
            height="5vh"
            width="50%"
            mWidth="auto"
            label="Postcode"
            value={formData["Postcode"]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={validatePostcode}
            errors={isValid["Postcode"]}
          />
          <TextInput
            height="5vh"
            width="50%"
            mWidth="auto"
            label="City/Town"
            value={formData["City/Town"]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={isValidCity}
            errors={isValid["City/Town"]}
          />
          <SelectInput
            height="5vh"
            width="50%"
            mWidth="auto"
            label="Country"
            value={formData["Country"]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={validateString}
            errors={isValid["Country"]}
          />
        </FlexContainer>
      </FlexContainer>
      <Line
        $bWidth="1px"
        $width="95%"
        $margin="0 auto"
        $bColor={`${theme.shadowColor}`}
      />
      {/* Contact Person */}
      <FlexContainer $flexD="column" $gap="2.5vh" $margin="-1vh 0 0 0">
        <H4 $fSize="2vh" $txtAlign $fWeight="400">
          Contact Person
        </H4>
        <FlexContainer $gap=".5vw" $mFlexD='column' $mGap='3vh'>
          <TextInput
            height="5vh"
            width="50%"
            mWidth="auto"
            label="First name"
            value={formData["First name"]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={isValidName}
            errors={isValid["First name"]}
          />
          <TextInput
            height="5vh"
            width="50%"
            mWidth="auto"
            label="Last name"
            value={formData["Last name"]}
            setForm={setForm}
            setIsValid={setIsValid}
            isValid={isValidName}
            errors={isValid["Last name"]}
          />
        </FlexContainer>
        <FlexContainer $gap=".5vw" $margin="1vh 0 0 0" $mFlexD='column' $mGap='3vh'>
          <TextInput
            height="5vh"
            width="70%"
            mWidth="auto"
            label="Email"
            value={formData["Email"]}
            setForm={setForm}
            type="email"
            setIsValid={setIsValid}
            isValid={isValidEmail}
            errors={isValid["Email"]}
          />
          <TextInput
            height="5vh"
            width="50%"
            mWidth="auto"
            label="Phone number"
            value={formData["Phone number"]}
            setForm={setForm}
            type="tel"
            setIsValid={setIsValid}
            isValid={isValidPhoneNumber}
            errors={isValid["Phone number"]}
          />
        </FlexContainer>
      </FlexContainer>
      <MainButton
        $width="40%"
        $height="5vh"
        $bRadius="50px"
        $fSize="2vh"
        $margin="1vh auto"
        type="submit"
      >
        Submit
      </MainButton>
    </Form>
  );
};

export default QouteForm;
