import React from "react";
import {
  AbsTxtSpan,
  DynamicLabel,
  FlexContainer,
  IsRequiredStar,
  Select,
} from "../../Styles/Global.Styled";
import { countryOptions } from "../../data";
import { theme } from "../../Styles/theme";

const SelectInput = ({
  width,
  mWidth,
  height,
  label,
  required = true,
  setForm,
  value,
  setIsValid,
  isValid,
  errors,
}) => {
  const handleBlur = (e) => {
    runValidation(e);
  };
  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const runValidation = (e) => {
    if (typeof setIsValid === "function" && typeof isValid === "function") {
      setIsValid((prev) => ({
        ...prev,
        [e.target.id]: isValid(e.target.value),
      }));
    }
  };
  console.log("SelectInput errors", errors);
  return (
    <FlexContainer $width={width} $mWidth={mWidth} $height={height} $padding="0 1vw">
      <Select
        $border={`1px solid ${errors ? "red" : theme.secColor}`}
        value={value}
        id={label}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
        $color="black"
        $fSize=".85vw"
        $padding="0 .5vw"
        style={{ zIndex: "1", backgroundColor: "transparent" }}
      >
        <option value="" disabled />
        {countryOptions.map((country) => (
          <option>{country}</option>
        ))}
      </Select>
      {value ? (
        <DynamicLabel
          $position="absolute"
          $height="1.5vh"
          $top="-1vh"
          $left="1.5vw"
          $fSize=".75vw"
          $zIndex="1"
          $bgColor="white"
        >
          {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      ) : (
        <DynamicLabel
          $position="absolute"
          $zIndex="0"
          $height="95%"
          $padding="0 .55vw"
        >
          select a {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      )}
      {errors ? (
        <AbsTxtSpan
          $color="red"
          $fSize="1.25vh"
          $height="1.5vh"
          $bottom="-1.9vh"
        >
          {errors}
        </AbsTxtSpan>
      ) : null}
    </FlexContainer>
  );
};

export default SelectInput;
