export const rhsToRgb = [
  { RHS: "155A", RGB: "rgb(238,233,212)" },
  { RHS: "155B", RGB: "rgb(244,236,218)" },
  { RHS: "155C", RGB: "rgb(237,233,220)" },
  { RHS: "155D", RGB: "rgb(245,236,220)" },
  { RHS: "N155A", RGB: "rgb(220,222,229)" },
  { RHS: "N155B", RGB: "rgb(240,230,231)" },
  { RHS: "N155C", RGB: "rgb(240,226,222)" },
  { RHS: "N155D", RGB: "rgb(241,231,226)" },
  { RHS: "NN155A", RGB: "rgb(244,239,223)" },
  { RHS: "NN155B", RGB: "rgb(243,239,232)" },
  { RHS: "NN155C", RGB: "rgb(244,242,239)" },
  { RHS: "NN155D", RGB: "rgb(246,244,242)" },
  { RHS: "156A", RGB: "rgb(199,188,169)" },
  { RHS: "156B", RGB: "rgb(206,199,181)" },
  { RHS: "156C", RGB: "rgb(211,204,188)" },
  { RHS: "156D", RGB: "rgb(220,214,201)" },
  { RHS: "201A", RGB: "rgb(124,110,105)" },
  { RHS: "201B", RGB: "rgb(141,129,125)" },
  { RHS: "201C", RGB: "rgb(158,147,146)" },
  { RHS: "201D", RGB: "rgb(174,164,161)" },
  { RHS: "202A", RGB: "rgb(37,31,36)" },
  { RHS: "202B", RGB: "rgb(110,113,117)" },
  { RHS: "202C", RGB: "rgb(155,156,158)" },
  { RHS: "202D", RGB: "rgb(202,205,205)" },
  { RHS: "203A", RGB: "rgb(41,34,35)" },
  { RHS: "203B", RGB: "rgb(41,32,37)" },
  { RHS: "203C", RGB: "rgb(34,34,40)" },
  { RHS: "203D", RGB: "rgb(35,35,39)" },
  { RHS: "54A", RGB: "rgb(212,79,115)" },
  { RHS: "54B", RGB: "rgb(222,94,121)" },
  { RHS: "54C", RGB: "rgb(223,136,154)" },
  { RHS: "54D", RGB: "rgb(242,172,179)" },
  { RHS: "55A", RGB: "rgb(238,98,131)" },
  { RHS: "55B", RGB: "rgb(239,137,165)" },
  { RHS: "55C", RGB: "rgb(246,171,188)" },
  { RHS: "55D", RGB: "rgb(248,196,203)" },
  { RHS: "56A", RGB: "rgb(242,192,200)" },
  { RHS: "56B", RGB: "rgb(246,202,206)" },
  { RHS: "56C", RGB: "rgb(245,211,214)" },
  { RHS: "56D", RGB: "rgb(245,221,217)" },
  { RHS: "42A", RGB: "rgb(194,19,46)" },
  { RHS: "42B", RGB: "rgb(203,64,62)" },
  { RHS: "42C", RGB: "rgb(220,72,67)" },
  { RHS: "42D", RGB: "rgb(219,99,89)" },
  { RHS: "43A", RGB: "rgb(209,39,52)" },
  { RHS: "43B", RGB: "rgb(232,60,69)" },
  { RHS: "43C", RGB: "rgb(239,87,95)" },
  { RHS: "43D", RGB: "rgb(253,140,138)" },
  { RHS: "44A", RGB: "rgb(200,38,50)" },
  { RHS: "44B", RGB: "rgb(217,28,44)" },
  { RHS: "44C", RGB: "rgb(227,69,72)" },
  { RHS: "44D", RGB: "rgb(237,97,93)" },
  { RHS: "45A", RGB: "rgb(173,18,47)" },
  { RHS: "45B", RGB: "rgb(187,0,44)" },
  { RHS: "45C", RGB: "rgb(202,43,68)" },
  { RHS: "45D", RGB: "rgb(208,58,82)" },
  { RHS: "N45A", RGB: "rgb(158,0,39)" },
  { RHS: "N45B", RGB: "rgb(171,0,47)" },
  { RHS: "N45C", RGB: "rgb(183,21,59)" },
  { RHS: "N45D", RGB: "rgb(196,53,80)" },
  { RHS: "46A", RGB: "rgb(150,26,50)" },
  { RHS: "46B", RGB: "rgb(179,8,52)" },
  { RHS: "46C", RGB: "rgb(205,27,67)" },
  { RHS: "46D", RGB: "rgb(215,59,86)" },
  { RHS: "47A", RGB: "rgb(184,52,73)" },
  { RHS: "47B", RGB: "rgb(199,49,73)" },
  { RHS: "47C", RGB: "rgb(226,83,101)" },
  { RHS: "47D", RGB: "rgb(228,100,116)" },
  { RHS: "48A", RGB: "rgb(212,95,101)" },
  { RHS: "48B", RGB: "rgb(227,108,117)" },
  { RHS: "48C", RGB: "rgb(244,127,137)" },
  { RHS: "48D", RGB: "rgb(255,164,170)" },
  { RHS: "49A", RGB: "rgb(255,152,157)" },
  { RHS: "49B", RGB: "rgb(253,178,171)" },
  { RHS: "49C", RGB: "rgb(250,203,196)" },
  { RHS: "49D", RGB: "rgb(250,218,211)" },
  { RHS: "50A", RGB: "rgb(211,44,75)" },
  { RHS: "50B", RGB: "rgb(223,81,99)" },
  { RHS: "50C", RGB: "rgb(235,129,140)" },
  { RHS: "50D", RGB: "rgb(244,191,190)" },
  { RHS: "51A", RGB: "rgb(208,62,95)" },
  { RHS: "51B", RGB: "rgb(218,95,116)" },
  { RHS: "51C", RGB: "rgb(223,127,138)" },
  { RHS: "51D", RGB: "rgb(229,158,160)" },
  { RHS: "52A", RGB: "rgb(221,60,84)" },
  { RHS: "52B", RGB: "rgb(168,64,81)" },
  { RHS: "52C", RGB: "rgb(243,116,130)" },
  { RHS: "52D", RGB: "rgb(250,149,157)" },
  { RHS: "53A", RGB: "rgb(146,0,51)" },
  { RHS: "53B", RGB: "rgb(173,33,67)" },
  { RHS: "53C", RGB: "rgb(193,38,82)" },
  { RHS: "53D", RGB: "rgb(204,63,98)" },
  { RHS: "178A", RGB: "rgb(128,57,59)" },
  { RHS: "178B", RGB: "rgb(142,61,62)" },
  { RHS: "178C", RGB: "rgb(171,74,67)" },
  { RHS: "178D", RGB: "rgb(189,87,74)" },
  { RHS: "179A", RGB: "rgb(171,57,63)" },
  { RHS: "179B", RGB: "rgb(195,88,80)" },
  { RHS: "179C", RGB: "rgb(222,135,117)" },
  { RHS: "179D", RGB: "rgb(240,174,150)" },
  { RHS: "180A", RGB: "rgb(161,58,67)" },
  { RHS: "180B", RGB: "rgb(185,76,80)" },
  { RHS: "180C", RGB: "rgb(195,91,95)" },
  { RHS: "180D", RGB: "rgb(205,117,116)" },
  { RHS: "181A", RGB: "rgb(150,58,68)" },
  { RHS: "181B", RGB: "rgb(169,74,80)" },
  { RHS: "181C", RGB: "rgb(184,94,101)" },
  { RHS: "181D", RGB: "rgb(199,131,132)" },
  { RHS: "182A", RGB: "rgb(158,61,75)" },
  { RHS: "182B", RGB: "rgb(163,94,96)" },
  { RHS: "182C", RGB: "rgb(185,121,120)" },
  { RHS: "182D", RGB: "rgb(198,129,130)" },
  { RHS: "200A", RGB: "rgb(65,47,45)" },
  { RHS: "200B", RGB: "rgb(81,50,46)" },
  { RHS: "200C", RGB: "rgb(94,62,52)" },
  { RHS: "200D", RGB: "rgb(112,74,56)" },
  { RHS: "N200A", RGB: "rgb(72,57,45)" },
  { RHS: "N200B", RGB: "rgb(108,97,90)" },
  { RHS: "N200C", RGB: "rgb(149,136,133)" },
  { RHS: "N200D", RGB: "rgb(182,175,172)" },
  { RHS: "24A", RGB: "rgb(255,155,43)" },
  { RHS: "24B", RGB: "rgb(255,173,80)" },
  { RHS: "24C", RGB: "rgb(255,190,127)" },
  { RHS: "24D", RGB: "rgb(255,202,153)" },
  { RHS: "25A", RGB: "rgb(255,136,40)" },
  { RHS: "25B", RGB: "rgb(255,157,60)" },
  { RHS: "25C", RGB: "rgb(255,169,92)" },
  { RHS: "25D", RGB: "rgb(255,196,146)" },
  { RHS: "N25A", RGB: "rgb(236,103,21)" },
  { RHS: "N25B", RGB: "rgb(255,129,0)" },
  { RHS: "N25C", RGB: "rgb(255,143,0)" },
  { RHS: "N25D", RGB: "rgb(255,166,0)" },
  { RHS: "26A", RGB: "rgb(238,136,56)" },
  { RHS: "26B", RGB: "rgb(247,159,85)" },
  { RHS: "26C", RGB: "rgb(248,177,127)" },
  { RHS: "26D", RGB: "rgb(246,188,148)" },
  { RHS: "27A", RGB: "rgb(252,209,176)" },
  { RHS: "27B", RGB: "rgb(255,217,193)" },
  { RHS: "27C", RGB: "rgb(249,221,204)" },
  { RHS: "27D", RGB: "rgb(248,226,207)" },
  { RHS: "28A", RGB: "rgb(245,106,34)" },
  { RHS: "28B", RGB: "rgb(255,129,48)" },
  { RHS: "28C", RGB: "rgb(255,167,107)" },
  { RHS: "28D", RGB: "rgb(255,183,135)" },
  { RHS: "29A", RGB: "rgb(255,155,96)" },
  { RHS: "29B", RGB: "rgb(255,168,121)" },
  { RHS: "29C", RGB: "rgb(255,193,159)" },
  { RHS: "29D", RGB: "rgb(254,213,189)" },
  { RHS: "30A", RGB: "rgb(250,109,67)" },
  { RHS: "30B", RGB: "rgb(255,115,60)" },
  { RHS: "30C", RGB: "rgb(255,122,60)" },
  { RHS: "30D", RGB: "rgb(255,137,79)" },
  { RHS: "N30A", RGB: "rgb(228,48,39)" },
  { RHS: "N30B", RGB: "rgb(250,83,46)" },
  { RHS: "N30C", RGB: "rgb(252,98,40)" },
  { RHS: "N30D", RGB: "rgb(255,114,18)" },
  { RHS: "31A", RGB: "rgb(234,104,63)" },
  { RHS: "31B", RGB: "rgb(241,122,81)" },
  { RHS: "31C", RGB: "rgb(236,144,114)" },
  { RHS: "31D", RGB: "rgb(245,162,137)" },
  { RHS: "32A", RGB: "rgb(243,87,57)" },
  { RHS: "32B", RGB: "rgb(253,112,71)" },
  { RHS: "32C", RGB: "rgb(253,137,96)" },
  { RHS: "32D", RGB: "rgb(255,167,136)" },
  { RHS: "33A", RGB: "rgb(232,58,52)" },
  { RHS: "33B", RGB: "rgb(239,86,54)" },
  { RHS: "33C", RGB: "rgb(254,130,98)" },
  { RHS: "33D", RGB: "rgb(253,168,144)" },
  { RHS: "34A", RGB: "rgb(204,58,56)" },
  { RHS: "34B", RGB: "rgb(207,80,63)" },
  { RHS: "34C", RGB: "rgb(221,100,78)" },
  { RHS: "34D", RGB: "rgb(222,131,107)" },
  { RHS: "N34A", RGB: "rgb(157,19,44)" },
  { RHS: "N34B", RGB: "rgb(217,65,55)" },
  { RHS: "N34C", RGB: "rgb(200,84,88)" },
  { RHS: "N34D", RGB: "rgb(213,100,85)" },
  { RHS: "35A", RGB: "rgb(219,89,73)" },
  { RHS: "35B", RGB: "rgb(228,113,96)" },
  { RHS: "35C", RGB: "rgb(228,143,128)" },
  { RHS: "35D", RGB: "rgb(237,169,156)" },
  { RHS: "36A", RGB: "rgb(250,194,179)" },
  { RHS: "36B", RGB: "rgb(248,205,192)" },
  { RHS: "36C", RGB: "rgb(252,211,201)" },
  { RHS: "36D", RGB: "rgb(248,223,212)" },
  { RHS: "37A", RGB: "rgb(238,127,113)" },
  { RHS: "37B", RGB: "rgb(246,153,141)" },
  { RHS: "37C", RGB: "rgb(248,175,163)" },
  { RHS: "37D", RGB: "rgb(251,187,178)" },
  { RHS: "38A", RGB: "rgb(254,143,136)" },
  { RHS: "38B", RGB: "rgb(250,169,163)" },
  { RHS: "38C", RGB: "rgb(255,183,170)" },
  { RHS: "38D", RGB: "rgb(252,202,192)" },
  { RHS: "39A", RGB: "rgb(221,77,82)" },
  { RHS: "39B", RGB: "rgb(232,109,108)" },
  { RHS: "39C", RGB: "rgb(241,168,160)" },
  { RHS: "39D", RGB: "rgb(251,188,181)" },
  { RHS: "40A", RGB: "rgb(238,61,57)" },
  { RHS: "40B", RGB: "rgb(242,73,62)" },
  { RHS: "40C", RGB: "rgb(247,92,80)" },
  { RHS: "40D", RGB: "rgb(254,114,94)" },
  { RHS: "41A", RGB: "rgb(231,64,65)" },
  { RHS: "41B", RGB: "rgb(235,84,82)" },
  { RHS: "41C", RGB: "rgb(255,118,109)" },
  { RHS: "41D", RGB: "rgb(251,139,131)" },
  { RHS: "159A", RGB: "rgb(245,214,177)" },
  { RHS: "159B", RGB: "rgb(246,219,188)" },
  { RHS: "159C", RGB: "rgb(241,223,200)" },
  { RHS: "159D", RGB: "rgb(245,232,211)" },
  { RHS: "163A", RGB: "rgb(200,129,25)" },
  { RHS: "163B", RGB: "rgb(216,158,28)" },
  { RHS: "163C", RGB: "rgb(225,179,92)" },
  { RHS: "163D", RGB: "rgb(232,199,141)" },
  { RHS: "N163A", RGB: "rgb(199,86,29)" },
  { RHS: "N163B", RGB: "rgb(220,110,30)" },
  { RHS: "N163C", RGB: "rgb(232,137,0)" },
  { RHS: "N163D", RGB: "rgb(225,148,0)" },
  { RHS: "164A", RGB: "rgb(174,106,63)" },
  { RHS: "164B", RGB: "rgb(204,145,86)" },
  { RHS: "164C", RGB: "rgb(221,171,107)" },
  { RHS: "164D", RGB: "rgb(236,204,163)" },
  { RHS: "165A", RGB: "rgb(116,73,55)" },
  { RHS: "165B", RGB: "rgb(177,112,62)" },
  { RHS: "165C", RGB: "rgb(214,152,101)" },
  { RHS: "165D", RGB: "rgb(234,187,142)" },
  { RHS: "166A", RGB: "rgb(107,62,56)" },
  { RHS: "166B", RGB: "rgb(148,76,57)" },
  { RHS: "166C", RGB: "rgb(179,102,70)" },
  { RHS: "166D", RGB: "rgb(194,125,90)" },
  { RHS: "167A", RGB: "rgb(198,116,46)" },
  { RHS: "167B", RGB: "rgb(210,127,66)" },
  { RHS: "167C", RGB: "rgb(220,138,76)" },
  { RHS: "167D", RGB: "rgb(224,151,91)" },
  { RHS: "N167A", RGB: "rgb(172,101,32)" },
  { RHS: "N167B", RGB: "rgb(178,97,34)" },
  { RHS: "N167C", RGB: "rgb(214,139,70)" },
  { RHS: "N167D", RGB: "rgb(205,132,83)" },
  { RHS: "168A", RGB: "rgb(211,96,56)" },
  { RHS: "168B", RGB: "rgb(215,110,59)" },
  { RHS: "168C", RGB: "rgb(226,136,73)" },
  { RHS: "168D", RGB: "rgb(235,168,111)" },
  { RHS: "169A", RGB: "rgb(199,74,43)" },
  { RHS: "169B", RGB: "rgb(212,90,45)" },
  { RHS: "169C", RGB: "rgb(218,107,46)" },
  { RHS: "169D", RGB: "rgb(229,120,54)" },
  { RHS: "170A", RGB: "rgb(211,98,57)" },
  { RHS: "170B", RGB: "rgb(219,113,68)" },
  { RHS: "170C", RGB: "rgb(228,140,92)" },
  { RHS: "170D", RGB: "rgb(243,165,123)" },
  { RHS: "N170A", RGB: "rgb(177,94,56)" },
  { RHS: "N170B", RGB: "rgb(126,79,48)" },
  { RHS: "N170C", RGB: "rgb(219,151,126)" },
  { RHS: "N170D", RGB: "rgb(231,183,166)" },
  { RHS: "171A", RGB: "rgb(183,85,60)" },
  { RHS: "171B", RGB: "rgb(200,96,64)" },
  { RHS: "171C", RGB: "rgb(215,113,78)" },
  { RHS: "171D", RGB: "rgb(225,137,102)" },
  { RHS: "172A", RGB: "rgb(152,64,47)" },
  { RHS: "172B", RGB: "rgb(168,78,59)" },
  { RHS: "172C", RGB: "rgb(196,99,66)" },
  { RHS: "172D", RGB: "rgb(212,124,74)" },
  { RHS: "N172A", RGB: "rgb(188,78,38)" },
  { RHS: "N172B", RGB: "rgb(193,91,50)" },
  { RHS: "N172C", RGB: "rgb(213,106,44)" },
  { RHS: "N172D", RGB: "rgb(201,115,51)" },
  { RHS: "173A", RGB: "rgb(157,76,66)" },
  { RHS: "173B", RGB: "rgb(191,97,73)" },
  { RHS: "173C", RGB: "rgb(204,128,93)" },
  { RHS: "173D", RGB: "rgb(224,161,125)" },
  { RHS: "174A", RGB: "rgb(141,82,67)" },
  { RHS: "174B", RGB: "rgb(176,105,86)" },
  { RHS: "174C", RGB: "rgb(192,122,103)" },
  { RHS: "174D", RGB: "rgb(203,135,113)" },
  { RHS: "175A", RGB: "rgb(127,61,51)" },
  { RHS: "175B", RGB: "rgb(143,59,43)" },
  { RHS: "175C", RGB: "rgb(159,72,48)" },
  { RHS: "175D", RGB: "rgb(171,92,69)" },
  { RHS: "176A", RGB: "rgb(133,66,61)" },
  { RHS: "176B", RGB: "rgb(141,82,76)" },
  { RHS: "176C", RGB: "rgb(159,89,78)" },
  { RHS: "176D", RGB: "rgb(175,103,85)" },
  { RHS: "177A", RGB: "rgb(117,73,63)" },
  { RHS: "177B", RGB: "rgb(143,94,78)" },
  { RHS: "177C", RGB: "rgb(162,107,90)" },
  { RHS: "177D", RGB: "rgb(177,126,108)" },
  { RHS: "1A", RGB: "rgb(235,224,67)" },
  { RHS: "1B", RGB: "rgb(232,225,87)" },
  { RHS: "1C", RGB: "rgb(236,232,144)" },
  { RHS: "1D", RGB: "rgb(241,234,164)" },
  { RHS: "2A", RGB: "rgb(246,225,59)" },
  { RHS: "2B", RGB: "rgb(241,227,91)" },
  { RHS: "2C", RGB: "rgb(243,236,149)" },
  { RHS: "2D", RGB: "rgb(244,234,174)" },
  { RHS: "3A", RGB: "rgb(250,226,64)" },
  { RHS: "3B", RGB: "rgb(245,228,85)" },
  { RHS: "3C", RGB: "rgb(246,232,128)" },
  { RHS: "3D", RGB: "rgb(248,237,157)" },
  { RHS: "4A", RGB: "rgb(248,227,93)" },
  { RHS: "4B", RGB: "rgb(247,234,125)" },
  { RHS: "4C", RGB: "rgb(245,233,153)" },
  { RHS: "4D", RGB: "rgb(245,234,191)" },
  { RHS: "5A", RGB: "rgb(247,221,66)" },
  { RHS: "5B", RGB: "rgb(251,226,72)" },
  { RHS: "5C", RGB: "rgb(249,230,115)" },
  { RHS: "5D", RGB: "rgb(248,231,156)" },
  { RHS: "6A", RGB: "rgb(254,221,52)" },
  { RHS: "6B", RGB: "rgb(254,223,66)" },
  { RHS: "6C", RGB: "rgb(253,224,94)" },
  { RHS: "6D", RGB: "rgb(249,228,147)" },
  { RHS: "7A", RGB: "rgb(246,209,39)" },
  { RHS: "7B", RGB: "rgb(252,218,77)" },
  { RHS: "7C", RGB: "rgb(254,224,83)" },
  { RHS: "7D", RGB: "rgb(248,228,116)" },
  { RHS: "8A", RGB: "rgb(254,222,88)" },
  { RHS: "8B", RGB: "rgb(254,226,116)" },
  { RHS: "8C", RGB: "rgb(251,229,148)" },
  { RHS: "8D", RGB: "rgb(245,232,186)" },
  { RHS: "9A", RGB: "rgb(255,217,37)" },
  { RHS: "9B", RGB: "rgb(255,219,62)" },
  { RHS: "9C", RGB: "rgb(255,225,107)" },
  { RHS: "9D", RGB: "rgb(249,229,164)" },
  { RHS: "10A", RGB: "rgb(253,220,107)" },
  { RHS: "10B", RGB: "rgb(251,223,126)" },
  { RHS: "10C", RGB: "rgb(249,225,144)" },
  { RHS: "10D", RGB: "rgb(247,231,179)" },
  { RHS: "11A", RGB: "rgb(249,209,90)" },
  { RHS: "11B", RGB: "rgb(246,216,132)" },
  { RHS: "11C", RGB: "rgb(246,223,164)" },
  { RHS: "11D", RGB: "rgb(246,231,201)" },
  { RHS: "12A", RGB: "rgb(255,210,48)" },
  { RHS: "12B", RGB: "rgb(255,220,84)" },
  { RHS: "12C", RGB: "rgb(255,223,128)" },
  { RHS: "12D", RGB: "rgb(251,227,166)" },
  { RHS: "13A", RGB: "rgb(255,201,34)" },
  { RHS: "13B", RGB: "rgb(255,208,70)" },
  { RHS: "13C", RGB: "rgb(255,214,99)" },
  { RHS: "13D", RGB: "rgb(250,228,176)" },
  { RHS: "14A", RGB: "rgb(255,197,0)" },
  { RHS: "14B", RGB: "rgb(255,200,0)" },
  { RHS: "14C", RGB: "rgb(255,215,105)" },
  { RHS: "14D", RGB: "rgb(250,220,145)" },
  { RHS: "15A", RGB: "rgb(255,191,28)" },
  { RHS: "15B", RGB: "rgb(255,203,64)" },
  { RHS: "15C", RGB: "rgb(255,204,77)" },
  { RHS: "15D", RGB: "rgb(255,216,124)" },
  { RHS: "16A", RGB: "rgb(255,195,57)" },
  { RHS: "16B", RGB: "rgb(255,207,100)" },
  { RHS: "16C", RGB: "rgb(255,213,120)" },
  { RHS: "16D", RGB: "rgb(253,221,153)" },
  { RHS: "17A", RGB: "rgb(255,175,0)" },
  { RHS: "17B", RGB: "rgb(255,188,0)" },
  { RHS: "17C", RGB: "rgb(255,196,24)" },
  { RHS: "17D", RGB: "rgb(255,209,111)" },
  { RHS: "18A", RGB: "rgb(255,210,113)" },
  { RHS: "18B", RGB: "rgb(255,219,157)" },
  { RHS: "18C", RGB: "rgb(251,226,180)" },
  { RHS: "18D", RGB: "rgb(249,224,180)" },
  { RHS: "19A", RGB: "rgb(255,210,100)" },
  { RHS: "19B", RGB: "rgb(255,214,152)" },
  { RHS: "19C", RGB: "rgb(254,219,165)" },
  { RHS: "19D", RGB: "rgb(248,225,193)" },
  { RHS: "20A", RGB: "rgb(252,185,74)" },
  { RHS: "20B", RGB: "rgb(255,205,120)" },
  { RHS: "20C", RGB: "rgb(254,212,156)" },
  { RHS: "20D", RGB: "rgb(253,223,187)" },
  { RHS: "21A", RGB: "rgb(255,177,0)" },
  { RHS: "21B", RGB: "rgb(255,183,43)" },
  { RHS: "21C", RGB: "rgb(255,195,77)" },
  { RHS: "21D", RGB: "rgb(255,203,118)" },
  { RHS: "22A", RGB: "rgb(239,162,60)" },
  { RHS: "22B", RGB: "rgb(255,195,110)" },
  { RHS: "22C", RGB: "rgb(255,200,127)" },
  { RHS: "22D", RGB: "rgb(255,207,142)" },
  { RHS: "23A", RGB: "rgb(255,167,0)" },
  { RHS: "23B", RGB: "rgb(255,181,62)" },
  { RHS: "23C", RGB: "rgb(255,195,111)" },
  { RHS: "23D", RGB: "rgb(253,212,166)" },
  { RHS: "158A", RGB: "rgb(243,220,181)" },
  { RHS: "158B", RGB: "rgb(249,231,197)" },
  { RHS: "158C", RGB: "rgb(247,232,207)" },
  { RHS: "158D", RGB: "rgb(247,235,214)" },
  { RHS: "160A", RGB: "rgb(218,196,108)" },
  { RHS: "160B", RGB: "rgb(226,204,128)" },
  { RHS: "160C", RGB: "rgb(224,209,150)" },
  { RHS: "160D", RGB: "rgb(224,209,166)" },
  { RHS: "161A", RGB: "rgb(213,177,109)" },
  { RHS: "161B", RGB: "rgb(225,187,117)" },
  { RHS: "161C", RGB: "rgb(229,199,149)" },
  { RHS: "161D", RGB: "rgb(234,209,168)" },
  { RHS: "162A", RGB: "rgb(222,178,87)" },
  { RHS: "162B", RGB: "rgb(228,192,111)" },
  { RHS: "162C", RGB: "rgb(227,196,134)" },
  { RHS: "162D", RGB: "rgb(228,204,155)" },
  { RHS: "199A", RGB: "rgb(127,98,59)" },
  { RHS: "199B", RGB: "rgb(147,122,88)" },
  { RHS: "199C", RGB: "rgb(162,136,102)" },
  { RHS: "199D", RGB: "rgb(174,150,119)" },
  { RHS: "125A", RGB: "rgb(0,136,118)" },
  { RHS: "125B", RGB: "rgb(0,120,105)" },
  { RHS: "125C", RGB: "rgb(92,198,185)" },
  { RHS: "125D", RGB: "rgb(137,214,200)" },
  { RHS: "126A", RGB: "rgb(18,97,95)" },
  { RHS: "126B", RGB: "rgb(0,118,115)" },
  { RHS: "126C", RGB: "rgb(16,134,129)" },
  { RHS: "126D", RGB: "rgb(72,156,150)" },
  { RHS: "127A", RGB: "rgb(8,105,95)" },
  { RHS: "127B", RGB: "rgb(0,130,115)" },
  { RHS: "127C", RGB: "rgb(0,145,129)" },
  { RHS: "127D", RGB: "rgb(68,164,149)" },
  { RHS: "128A", RGB: "rgb(0,156,123)" },
  { RHS: "128B", RGB: "rgb(80,175,153)" },
  { RHS: "128C", RGB: "rgb(133,196,176)" },
  { RHS: "128D", RGB: "rgb(196,220,205)" },
  { RHS: "129A", RGB: "rgb(0,157,116)" },
  { RHS: "129B", RGB: "rgb(88,183,154)" },
  { RHS: "129C", RGB: "rgb(120,200,169)" },
  { RHS: "129D", RGB: "rgb(175,215,194)" },
  { RHS: "130A", RGB: "rgb(37,174,119)" },
  { RHS: "130B", RGB: "rgb(112,194,151)" },
  { RHS: "130C", RGB: "rgb(148,205,172)" },
  { RHS: "130D", RGB: "rgb(202,224,201)" },
  { RHS: "131A", RGB: "rgb(26,70,60)" },
  { RHS: "131B", RGB: "rgb(3,90,63)" },
  { RHS: "131C", RGB: "rgb(0,105,76)" },
  { RHS: "131D", RGB: "rgb(39,137,106)" },
  { RHS: "132A", RGB: "rgb(0,88,61)" },
  { RHS: "132B", RGB: "rgb(0,99,72)" },
  { RHS: "132C", RGB: "rgb(39,131,100)" },
  { RHS: "132D", RGB: "rgb(97,171,131)" },
  { RHS: "133A", RGB: "rgb(46,81,73)" },
  { RHS: "133B", RGB: "rgb(64,120,109)" },
  { RHS: "133C", RGB: "rgb(101,150,135)" },
  { RHS: "133D", RGB: "rgb(130,178,160)" },
  { RHS: "134A", RGB: "rgb(0,141,59)" },
  { RHS: "134B", RGB: "rgb(70,177,103)" },
  { RHS: "134C", RGB: "rgb(122,200,142)" },
  { RHS: "134D", RGB: "rgb(162,210,168)" },
  { RHS: "N134A", RGB: "rgb(21,85,52)" },
  { RHS: "N134B", RGB: "rgb(0,105,60)" },
  { RHS: "N134C", RGB: "rgb(0,138,82)" },
  { RHS: "N134D", RGB: "rgb(63,165,116)" },
  { RHS: "135A", RGB: "rgb(24,75,51)" },
  { RHS: "135B", RGB: "rgb(11,96,62)" },
  { RHS: "135C", RGB: "rgb(71,141,88)" },
  { RHS: "135D", RGB: "rgb(140,189,134)" },
  { RHS: "136A", RGB: "rgb(41,76,62)" },
  { RHS: "136B", RGB: "rgb(55,95,74)" },
  { RHS: "136C", RGB: "rgb(100,142,105)" },
  { RHS: "136D", RGB: "rgb(163,200,163)" },
  { RHS: "137A", RGB: "rgb(72,96,61)" },
  { RHS: "137B", RGB: "rgb(83,105,66)" },
  { RHS: "137C", RGB: "rgb(92,117,77)" },
  { RHS: "137D", RGB: "rgb(100,131,86)" },
  { RHS: "138A", RGB: "rgb(92,120,72)" },
  { RHS: "138B", RGB: "rgb(121,145,97)" },
  { RHS: "138C", RGB: "rgb(151,177,130)" },
  { RHS: "138D", RGB: "rgb(169,192,145)" },
  { RHS: "N138A", RGB: "rgb(76,110,91)" },
  { RHS: "N138B", RGB: "rgb(93,116,87)" },
  { RHS: "N138C", RGB: "rgb(118,136,113)" },
  { RHS: "N138D", RGB: "rgb(114,142,118)" },
  { RHS: "139A", RGB: "rgb(50,75,55)" },
  { RHS: "139B", RGB: "rgb(79,117,76)" },
  { RHS: "139C", RGB: "rgb(117,149,98)" },
  { RHS: "139D", RGB: "rgb(164,187,133)" },
  { RHS: "140A", RGB: "rgb(44,156,51)" },
  { RHS: "140B", RGB: "rgb(102,183,83)" },
  { RHS: "140C", RGB: "rgb(137,205,127)" },
  { RHS: "140D", RGB: "rgb(175,214,163)" },
  { RHS: "141A", RGB: "rgb(44,102,44)" },
  { RHS: "141B", RGB: "rgb(51,108,51)" },
  { RHS: "141C", RGB: "rgb(101,151,78)" },
  { RHS: "141D", RGB: "rgb(143,187,105)" },
  { RHS: "142A", RGB: "rgb(145,197,92)" },
  { RHS: "142B", RGB: "rgb(155,198,111)" },
  { RHS: "142C", RGB: "rgb(187,216,150)" },
  { RHS: "142D", RGB: "rgb(200,225,177)" },
  { RHS: "143A", RGB: "rgb(90,130,55)" },
  { RHS: "143B", RGB: "rgb(104,145,56)" },
  { RHS: "143C", RGB: "rgb(123,156,70)" },
  { RHS: "143D", RGB: "rgb(156,191,126)" },
  { RHS: "144A", RGB: "rgb(111,133,36)" },
  { RHS: "144B", RGB: "rgb(139,165,60)" },
  { RHS: "144C", RGB: "rgb(159,181,72)" },
  { RHS: "144D", RGB: "rgb(187,207,129)" },
  { RHS: "N144A", RGB: "rgb(167,169,33)" },
  { RHS: "N144B", RGB: "rgb(179,191,0)" },
  { RHS: "N144C", RGB: "rgb(144,171,29)" },
  { RHS: "N144D", RGB: "rgb(174,190,87)" },
  { RHS: "145A", RGB: "rgb(163,180,83)" },
  { RHS: "145B", RGB: "rgb(178,195,110)" },
  { RHS: "145C", RGB: "rgb(205,212,147)" },
  { RHS: "145D", RGB: "rgb(217,222,179)" },
  { RHS: "146A", RGB: "rgb(51,66,21)" },
  { RHS: "146B", RGB: "rgb(109,119,60)" },
  { RHS: "146C", RGB: "rgb(131,138,70)" },
  { RHS: "146D", RGB: "rgb(145,151,82)" },
  { RHS: "147A", RGB: "rgb(71,80,52)" },
  { RHS: "147B", RGB: "rgb(88,99,62)" },
  { RHS: "147C", RGB: "rgb(143,147,102)" },
  { RHS: "147D", RGB: "rgb(170,184,135)" },
  { RHS: "148A", RGB: "rgb(109,109,68)" },
  { RHS: "148B", RGB: "rgb(123,127,87)" },
  { RHS: "148C", RGB: "rgb(153,159,118)" },
  { RHS: "148D", RGB: "rgb(164,174,134)" },
  { RHS: "N148A", RGB: "rgb(132,135,91)" },
  { RHS: "N148B", RGB: "rgb(147,151,107)" },
  { RHS: "N148C", RGB: "rgb(156,159,117)" },
  { RHS: "N148D", RGB: "rgb(159,165,125)" },
  { RHS: "149A", RGB: "rgb(175,210,66)" },
  { RHS: "149B", RGB: "rgb(188,218,100)" },
  { RHS: "149C", RGB: "rgb(196,224,127)" },
  { RHS: "149D", RGB: "rgb(220,230,179)" },
  { RHS: "150A", RGB: "rgb(204,219,55)" },
  { RHS: "150B", RGB: "rgb(213,220,86)" },
  { RHS: "150C", RGB: "rgb(221,224,114)" },
  { RHS: "150D", RGB: "rgb(237,232,182)" },
  { RHS: "151A", RGB: "rgb(183,174,39)" },
  { RHS: "151B", RGB: "rgb(196,188,10)" },
  { RHS: "151C", RGB: "rgb(206,202,49)" },
  { RHS: "151D", RGB: "rgb(211,204,72)" },
  { RHS: "152A", RGB: "rgb(121,109,33)" },
  { RHS: "152B", RGB: "rgb(132,117,30)" },
  { RHS: "152C", RGB: "rgb(153,135,18)" },
  { RHS: "152D", RGB: "rgb(170,149,59)" },
  { RHS: "153A", RGB: "rgb(172,153,8)" },
  { RHS: "153B", RGB: "rgb(188,163,30)" },
  { RHS: "153C", RGB: "rgb(202,174,24)" },
  { RHS: "153D", RGB: "rgb(210,181,61)" },
  { RHS: "154A", RGB: "rgb(212,215,26)" },
  { RHS: "154B", RGB: "rgb(223,220,73)" },
  { RHS: "154C", RGB: "rgb(229,228,118)" },
  { RHS: "154D", RGB: "rgb(233,230,147)" },
  { RHS: "157A", RGB: "rgb(225,222,192)" },
  { RHS: "157B", RGB: "rgb(226,225,199)" },
  { RHS: "157C", RGB: "rgb(233,232,208)" },
  { RHS: "157D", RGB: "rgb(239,236,216)" },
  { RHS: "188A", RGB: "rgb(145,157,155)" },
  { RHS: "188B", RGB: "rgb(156,169,164)" },
  { RHS: "188C", RGB: "rgb(171,183,178)" },
  { RHS: "188D", RGB: "rgb(190,200,195)" },
  { RHS: "189A", RGB: "rgb(91,106,88)" },
  { RHS: "189B", RGB: "rgb(129,149,138)" },
  { RHS: "189C", RGB: "rgb(168,181,173)" },
  { RHS: "189D", RGB: "rgb(195,201,197)" },
  { RHS: "N189A", RGB: "rgb(52,67,62)" },
  { RHS: "N189B", RGB: "rgb(84,96,93)" },
  { RHS: "N189C", RGB: "rgb(124,135,133)" },
  { RHS: "N189D", RGB: "rgb(169,176,175)" },
  { RHS: "190A", RGB: "rgb(158,173,158)" },
  { RHS: "190B", RGB: "rgb(171,184,171)" },
  { RHS: "190C", RGB: "rgb(183,194,185)" },
  { RHS: "190D", RGB: "rgb(207,214,206)" },
  { RHS: "191A", RGB: "rgb(119,131,107)" },
  { RHS: "191B", RGB: "rgb(135,150,121)" },
  { RHS: "191C", RGB: "rgb(165,177,156)" },
  { RHS: "191D", RGB: "rgb(187,194,180)" },
  { RHS: "192A", RGB: "rgb(195,204,181)" },
  { RHS: "192B", RGB: "rgb(199,208,189)" },
  { RHS: "192C", RGB: "rgb(211,219,206)" },
  { RHS: "192D", RGB: "rgb(221,228,216)" },
  { RHS: "193A", RGB: "rgb(188,198,162)" },
  { RHS: "193B", RGB: "rgb(196,206,175)" },
  { RHS: "193C", RGB: "rgb(206,215,193)" },
  { RHS: "193D", RGB: "rgb(211,218,199)" },
  { RHS: "194A", RGB: "rgb(151,156,128)" },
  { RHS: "194B", RGB: "rgb(157,164,133)" },
  { RHS: "194C", RGB: "rgb(180,188,160)" },
  { RHS: "194D", RGB: "rgb(195,197,175)" },
  { RHS: "195A", RGB: "rgb(154,151,118)" },
  { RHS: "195B", RGB: "rgb(181,177,146)" },
  { RHS: "195C", RGB: "rgb(195,196,169)" },
  { RHS: "195D", RGB: "rgb(202,203,179)" },
  { RHS: "196A", RGB: "rgb(185,182,159)" },
  { RHS: "196B", RGB: "rgb(198,197,173)" },
  { RHS: "196C", RGB: "rgb(208,205,187)" },
  { RHS: "196D", RGB: "rgb(216,216,200)" },
  { RHS: "197A", RGB: "rgb(121,107,88)" },
  { RHS: "197B", RGB: "rgb(136,124,103)" },
  { RHS: "197C", RGB: "rgb(157,148,127)" },
  { RHS: "197D", RGB: "rgb(178,173,155)" },
  { RHS: "198A", RGB: "rgb(145,142,127)" },
  { RHS: "198B", RGB: "rgb(162,161,146)" },
  { RHS: "198C", RGB: "rgb(171,171,156)" },
  { RHS: "198D", RGB: "rgb(189,188,177)" },
  { RHS: "111A", RGB: "rgb(0,123,173)" },
  { RHS: "111B", RGB: "rgb(43,169,206)" },
  { RHS: "111C", RGB: "rgb(107,186,213)" },
  { RHS: "111D", RGB: "rgb(145,201,220)" },
  { RHS: "112A", RGB: "rgb(173,208,217)" },
  { RHS: "112B", RGB: "rgb(190,216,223)" },
  { RHS: "112C", RGB: "rgb(200,218,221)" },
  { RHS: "112D", RGB: "rgb(210,224,222)" },
  { RHS: "113A", RGB: "rgb(0,128,166)" },
  { RHS: "113B", RGB: "rgb(23,151,185)" },
  { RHS: "113C", RGB: "rgb(109,186,211)" },
  { RHS: "113D", RGB: "rgb(192,224,223)" },
  { RHS: "114A", RGB: "rgb(0,75,111)" },
  { RHS: "114B", RGB: "rgb(0,95,128)" },
  { RHS: "114C", RGB: "rgb(0,113,148)" },
  { RHS: "114D", RGB: "rgb(38,135,169)" },
  { RHS: "115A", RGB: "rgb(63,127,157)" },
  { RHS: "115B", RGB: "rgb(90,142,168)" },
  { RHS: "115C", RGB: "rgb(117,160,182)" },
  { RHS: "115D", RGB: "rgb(161,188,204)" },
  { RHS: "116A", RGB: "rgb(30,88,120)" },
  { RHS: "116B", RGB: "rgb(51,102,133)" },
  { RHS: "116C", RGB: "rgb(65,120,146)" },
  { RHS: "116D", RGB: "rgb(94,140,161)" },
  { RHS: "117A", RGB: "rgb(114,180,200)" },
  { RHS: "117B", RGB: "rgb(143,196,211)" },
  { RHS: "117C", RGB: "rgb(167,209,215)" },
  { RHS: "117D", RGB: "rgb(179,213,213)" },
  { RHS: "118A", RGB: "rgb(0,137,172)" },
  { RHS: "118B", RGB: "rgb(36,162,187)" },
  { RHS: "118C", RGB: "rgb(110,184,204)" },
  { RHS: "118D", RGB: "rgb(182,215,216)" },
  { RHS: "119A", RGB: "rgb(69,130,147)" },
  { RHS: "119B", RGB: "rgb(92,149,164)" },
  { RHS: "119C", RGB: "rgb(114,166,179)" },
  { RHS: "119D", RGB: "rgb(147,192,201)" },
  { RHS: "120A", RGB: "rgb(0,138,163)" },
  { RHS: "120B", RGB: "rgb(16,152,172)" },
  { RHS: "120C", RGB: "rgb(102,175,187)" },
  { RHS: "120D", RGB: "rgb(177,206,205)" },
  { RHS: "121A", RGB: "rgb(59,149,164)" },
  { RHS: "121B", RGB: "rgb(104,174,186)" },
  { RHS: "121C", RGB: "rgb(139,188,194)" },
  { RHS: "121D", RGB: "rgb(179,208,208)" },
  { RHS: "122A", RGB: "rgb(93,135,142)" },
  { RHS: "122B", RGB: "rgb(109,149,155)" },
  { RHS: "122C", RGB: "rgb(149,180,182)" },
  { RHS: "122D", RGB: "rgb(182,205,205)" },
  { RHS: "123A", RGB: "rgb(118,188,189)" },
  { RHS: "123B", RGB: "rgb(140,200,200)" },
  { RHS: "123C", RGB: "rgb(174,214,211)" },
  { RHS: "123D", RGB: "rgb(190,220,213)" },
  { RHS: "124A", RGB: "rgb(0,144,143)" },
  { RHS: "124B", RGB: "rgb(61,167,166)" },
  { RHS: "124C", RGB: "rgb(122,186,180)" },
  { RHS: "124D", RGB: "rgb(185,214,208)" },
  { RHS: "95A", RGB: "rgb(31,48,110)" },
  { RHS: "95B", RGB: "rgb(33,56,134)" },
  { RHS: "95C", RGB: "rgb(77,99,166)" },
  { RHS: "95D", RGB: "rgb(167,178,211)" },
  { RHS: "N95A", RGB: "rgb(14,49,143)" },
  { RHS: "N95B", RGB: "rgb(35,59,149)" },
  { RHS: "N95C", RGB: "rgb(61,80,165)" },
  { RHS: "N95D", RGB: "rgb(81,101,179)" },
  { RHS: "96A", RGB: "rgb(58,68,137)" },
  { RHS: "96B", RGB: "rgb(74,90,166)" },
  { RHS: "96C", RGB: "rgb(88,101,171)" },
  { RHS: "96D", RGB: "rgb(111,129,190)" },
  { RHS: "97A", RGB: "rgb(127,143,195)" },
  { RHS: "97B", RGB: "rgb(154,169,205)" },
  { RHS: "97C", RGB: "rgb(186,193,215)" },
  { RHS: "97D", RGB: "rgb(210,212,225)" },
  { RHS: "98A", RGB: "rgb(50,93,159)" },
  { RHS: "98B", RGB: "rgb(84,117,171)" },
  { RHS: "98C", RGB: "rgb(111,142,186)" },
  { RHS: "98D", RGB: "rgb(137,163,198)" },
  { RHS: "99A", RGB: "rgb(29,49,109)" },
  { RHS: "99B", RGB: "rgb(29,62,135)" },
  { RHS: "99C", RGB: "rgb(16,76,155)" },
  { RHS: "99D", RGB: "rgb(22,88,169)" },
  { RHS: "100A", RGB: "rgb(0,85,167)" },
  { RHS: "100B", RGB: "rgb(84,129,197)" },
  { RHS: "100C", RGB: "rgb(129,157,207)" },
  { RHS: "100D", RGB: "rgb(174,189,220)" },
  { RHS: "101A", RGB: "rgb(0,99,181)" },
  { RHS: "101B", RGB: "rgb(71,126,197)" },
  { RHS: "101C", RGB: "rgb(122,162,208)" },
  { RHS: "101D", RGB: "rgb(175,202,224)" },
  { RHS: "102A", RGB: "rgb(18,60,114)" },
  { RHS: "102B", RGB: "rgb(38,79,134)" },
  { RHS: "102C", RGB: "rgb(55,91,147)" },
  { RHS: "102D", RGB: "rgb(77,113,168)" },
  { RHS: "103A", RGB: "rgb(31,36,65)" },
  { RHS: "103B", RGB: "rgb(34,49,89)" },
  { RHS: "103C", RGB: "rgb(30,55,102)" },
  { RHS: "103D", RGB: "rgb(35,78,128)" },
  { RHS: "104A", RGB: "rgb(0,81,153)" },
  { RHS: "104B", RGB: "rgb(60,120,185)" },
  { RHS: "104C", RGB: "rgb(78,138,201)" },
  { RHS: "104D", RGB: "rgb(131,173,214)" },
  { RHS: "105A", RGB: "rgb(0,69,134)" },
  { RHS: "105B", RGB: "rgb(0,78,156)" },
  { RHS: "105C", RGB: "rgb(0,98,175)" },
  { RHS: "105D", RGB: "rgb(31,131,199)" },
  { RHS: "106A", RGB: "rgb(84,154,208)" },
  { RHS: "106B", RGB: "rgb(125,174,216)" },
  { RHS: "106C", RGB: "rgb(160,193,221)" },
  { RHS: "106D", RGB: "rgb(190,209,225)" },
  { RHS: "107A", RGB: "rgb(0,106,180)" },
  { RHS: "107B", RGB: "rgb(23,140,202)" },
  { RHS: "107C", RGB: "rgb(117,172,215)" },
  { RHS: "107D", RGB: "rgb(135,182,219)" },
  { RHS: "108A", RGB: "rgb(181,208,222)" },
  { RHS: "108B", RGB: "rgb(192,217,228)" },
  { RHS: "108C", RGB: "rgb(199,218,231)" },
  { RHS: "108D", RGB: "rgb(207,220,228)" },
  { RHS: "N109A", RGB: "rgb(0,99,184)" },
  { RHS: "N109B", RGB: "rgb(0,125,203)" },
  { RHS: "N109C", RGB: "rgb(37,162,219)" },
  { RHS: "N109D", RGB: "rgb(128,187,221)" },
  { RHS: "110A", RGB: "rgb(0,122,188)" },
  { RHS: "110B", RGB: "rgb(0,144,199)" },
  { RHS: "110C", RGB: "rgb(124,193,228)" },
  { RHS: "110D", RGB: "rgb(151,205,229)" },
  { RHS: "N82A", RGB: "rgb(126,75,146)" },
  { RHS: "N82B", RGB: "rgb(156,108,176)" },
  { RHS: "N82C", RGB: "rgb(170,133,190)" },
  { RHS: "N82D", RGB: "rgb(181,150,196)" },
  { RHS: "83A", RGB: "rgb(83,46,90)" },
  { RHS: "83B", RGB: "rgb(103,59,111)" },
  { RHS: "83C", RGB: "rgb(118,77,130)" },
  { RHS: "83D", RGB: "rgb(138,93,151)" },
  { RHS: "84A", RGB: "rgb(152,107,162)" },
  { RHS: "84B", RGB: "rgb(195,145,187)" },
  { RHS: "84C", RGB: "rgb(211,174,203)" },
  { RHS: "84D", RGB: "rgb(213,203,220)" },
  { RHS: "85A", RGB: "rgb(166,142,180)" },
  { RHS: "85B", RGB: "rgb(172,160,195)" },
  { RHS: "85C", RGB: "rgb(191,177,210)" },
  { RHS: "85D", RGB: "rgb(212,205,219)" },
  { RHS: "86A", RGB: "rgb(85,52,102)" },
  { RHS: "86B", RGB: "rgb(113,76,134)" },
  { RHS: "86C", RGB: "rgb(128,97,153)" },
  { RHS: "86D", RGB: "rgb(141,114,167)" },
  { RHS: "N87A", RGB: "rgb(114,70,152)" },
  { RHS: "N87B", RGB: "rgb(138,94,170)" },
  { RHS: "N87C", RGB: "rgb(161,128,192)" },
  { RHS: "N87D", RGB: "rgb(181,153,205)" },
  { RHS: "N88A", RGB: "rgb(75,52,132)" },
  { RHS: "N88B", RGB: "rgb(84,68,131)" },
  { RHS: "N88C", RGB: "rgb(148,128,188)" },
  { RHS: "N88D", RGB: "rgb(166,152,202)" },
  { RHS: "N89A", RGB: "rgb(37,29,95)" },
  { RHS: "N89B", RGB: "rgb(53,39,113)" },
  { RHS: "N89C", RGB: "rgb(77,63,145)" },
  { RHS: "N89D", RGB: "rgb(91,78,164)" },
  { RHS: "90A", RGB: "rgb(89,71,126)" },
  { RHS: "90B", RGB: "rgb(107,86,143)" },
  { RHS: "90C", RGB: "rgb(126,103,163)" },
  { RHS: "90D", RGB: "rgb(136,119,172)" },
  { RHS: "91A", RGB: "rgb(140,133,189)" },
  { RHS: "91B", RGB: "rgb(171,166,207)" },
  { RHS: "91C", RGB: "rgb(192,188,218)" },
  { RHS: "91D", RGB: "rgb(216,210,227)" },
  { RHS: "92A", RGB: "rgb(122,114,177)" },
  { RHS: "92B", RGB: "rgb(155,152,200)" },
  { RHS: "92C", RGB: "rgb(183,181,213)" },
  { RHS: "92D", RGB: "rgb(212,208,223)" },
  { RHS: "N92A", RGB: "rgb(62,42,62)" },
  { RHS: "N92B", RGB: "rgb(50,42,87)" },
  { RHS: "N92C", RGB: "rgb(47,38,72)" },
  { RHS: "N92D", RGB: "rgb(76,59,95)" },
  { RHS: "93A", RGB: "rgb(49,47,101)" },
  { RHS: "93B", RGB: "rgb(88,73,142)" },
  { RHS: "93C", RGB: "rgb(105,99,164)" },
  { RHS: "93D", RGB: "rgb(139,139,188)" },
  { RHS: "94A", RGB: "rgb(65,70,142)" },
  { RHS: "94B", RGB: "rgb(105,105,169)" },
  { RHS: "94C", RGB: "rgb(136,130,189)" },
  { RHS: "94D", RGB: "rgb(155,156,200)" },
  { RHS: "75A", RGB: "rgb(201,137,183)" },
  { RHS: "75B", RGB: "rgb(224,171,203)" },
  { RHS: "75C", RGB: "rgb(233,187,211)" },
  { RHS: "75D", RGB: "rgb(237,210,218)" },
  { RHS: "N75A", RGB: "rgb(190,124,175)" },
  { RHS: "N75B", RGB: "rgb(195,138,185)" },
  { RHS: "N75C", RGB: "rgb(201,148,193)" },
  { RHS: "N75D", RGB: "rgb(204,157,198)" },
  { RHS: "76A", RGB: "rgb(197,155,198)" },
  { RHS: "76B", RGB: "rgb(212,182,211)" },
  { RHS: "76C", RGB: "rgb(221,198,218)" },
  { RHS: "76D", RGB: "rgb(234,217,225)" },
  { RHS: "77A", RGB: "rgb(123,53,109)" },
  { RHS: "77B", RGB: "rgb(167,97,155)" },
  { RHS: "77C", RGB: "rgb(202,142,186)" },
  { RHS: "77D", RGB: "rgb(211,162,196)" },
  { RHS: "N77A", RGB: "rgb(77,42,51)" },
  { RHS: "N77B", RGB: "rgb(135,68,104)" },
  { RHS: "N77C", RGB: "rgb(129,95,114)" },
  { RHS: "N77D", RGB: "rgb(147,100,123)" },
  { RHS: "N78A", RGB: "rgb(142,41,115)" },
  { RHS: "N78B", RGB: "rgb(168,75,147)" },
  { RHS: "N78C", RGB: "rgb(179,108,167)" },
  { RHS: "N78D", RGB: "rgb(191,131,180)" },
  { RHS: "NN78A", RGB: "rgb(169,28,127)" },
  { RHS: "NN78B", RGB: "rgb(175,53,134)" },
  { RHS: "NN78C", RGB: "rgb(186,70,149)" },
  { RHS: "NN78D", RGB: "rgb(197,88,164)" },
  { RHS: "79A", RGB: "rgb(76,46,74)" },
  { RHS: "79B", RGB: "rgb(88,53,89)" },
  { RHS: "79C", RGB: "rgb(107,64,108)" },
  { RHS: "79D", RGB: "rgb(124,86,124)" },
  { RHS: "N79A", RGB: "rgb(80,32,59)" },
  { RHS: "N79B", RGB: "rgb(94,36,69)" },
  { RHS: "N79C", RGB: "rgb(125,33,86)" },
  { RHS: "N79D", RGB: "rgb(139,73,121)" },
  { RHS: "N80A", RGB: "rgb(141,57,131)" },
  { RHS: "N80B", RGB: "rgb(167,93,161)" },
  { RHS: "N80C", RGB: "rgb(184,132,181)" },
  { RHS: "N80D", RGB: "rgb(195,150,193)" },
  { RHS: "N81A", RGB: "rgb(120,47,119)" },
  { RHS: "N81B", RGB: "rgb(144,74,145)" },
  { RHS: "N81C", RGB: "rgb(169,114,175)" },
  { RHS: "N81D", RGB: "rgb(188,146,189)" },
  { RHS: "N57A", RGB: "rgb(215,4,91)" },
  { RHS: "N57B", RGB: "rgb(215,13,95)" },
  { RHS: "N57C", RGB: "rgb(228,85,137)" },
  { RHS: "N57D", RGB: "rgb(232,118,159)" },
  { RHS: "58A", RGB: "rgb(168,51,90)" },
  { RHS: "58B", RGB: "rgb(217,58,105)" },
  { RHS: "58C", RGB: "rgb(236,83,128)" },
  { RHS: "58D", RGB: "rgb(239,135,161)" },
  { RHS: "59A", RGB: "rgb(113,27,56)" },
  { RHS: "59B", RGB: "rgb(131,26,65)" },
  { RHS: "59C", RGB: "rgb(156,38,81)" },
  { RHS: "59D", RGB: "rgb(195,74,116)" },
  { RHS: "60A", RGB: "rgb(142,21,64)" },
  { RHS: "60B", RGB: "rgb(154,23,77)" },
  { RHS: "60C", RGB: "rgb(163,49,94)" },
  { RHS: "60D", RGB: "rgb(194,63,115)" },
  { RHS: "61A", RGB: "rgb(142,26,78)" },
  { RHS: "61B", RGB: "rgb(174,27,87)" },
  { RHS: "61C", RGB: "rgb(203,55,110)" },
  { RHS: "61D", RGB: "rgb(242,107,150)" },
  { RHS: "62A", RGB: "rgb(243,144,170)" },
  { RHS: "62B", RGB: "rgb(240,160,186)" },
  { RHS: "62C", RGB: "rgb(242,179,199)" },
  { RHS: "62D", RGB: "rgb(242,207,211)" },
  { RHS: "63A", RGB: "rgb(185,49,98)" },
  { RHS: "63B", RGB: "rgb(207,79,128)" },
  { RHS: "63C", RGB: "rgb(230,135,170)" },
  { RHS: "63D", RGB: "rgb(236,167,187)" },
  { RHS: "64A", RGB: "rgb(152,40,92)" },
  { RHS: "64B", RGB: "rgb(175,52,105)" },
  { RHS: "64C", RGB: "rgb(204,81,136)" },
  { RHS: "64D", RGB: "rgb(222,120,158)" },
  { RHS: "65A", RGB: "rgb(240,152,181)" },
  { RHS: "65B", RGB: "rgb(245,182,201)" },
  { RHS: "65C", RGB: "rgb(240,195,206)" },
  { RHS: "65D", RGB: "rgb(242,206,213)" },
  { RHS: "N66A", RGB: "rgb(212,8,100)" },
  { RHS: "N66B", RGB: "rgb(217,35,114)" },
  { RHS: "N66C", RGB: "rgb(225,100,153)" },
  { RHS: "N66D", RGB: "rgb(231,135,178)" },
  { RHS: "67A", RGB: "rgb(193,39,104)" },
  { RHS: "67B", RGB: "rgb(207,67,129)" },
  { RHS: "67C", RGB: "rgb(223,84,140)" },
  { RHS: "67D", RGB: "rgb(241,132,167)" },
  { RHS: "68A", RGB: "rgb(224,97,150)" },
  { RHS: "68B", RGB: "rgb(232,126,171)" },
  { RHS: "68C", RGB: "rgb(245,156,182)" },
  { RHS: "68D", RGB: "rgb(246,181,197)" },
  { RHS: "69A", RGB: "rgb(247,209,218)" },
  { RHS: "69B", RGB: "rgb(241,212,222)" },
  { RHS: "69C", RGB: "rgb(235,214,220)" },
  { RHS: "69D", RGB: "rgb(234,220,225)" },
  { RHS: "70A", RGB: "rgb(151,52,108)" },
  { RHS: "70B", RGB: "rgb(184,84,137)" },
  { RHS: "70C", RGB: "rgb(214,125,159)" },
  { RHS: "70D", RGB: "rgb(235,169,190)" },
  { RHS: "71A", RGB: "rgb(134,27,83)" },
  { RHS: "71B", RGB: "rgb(171,47,110)" },
  { RHS: "71C", RGB: "rgb(181,54,123)" },
  { RHS: "71D", RGB: "rgb(200,81,144)" },
  { RHS: "72A", RGB: "rgb(148,36,104)" },
  { RHS: "72B", RGB: "rgb(158,58,125)" },
  { RHS: "72C", RGB: "rgb(203,94,154)" },
  { RHS: "72D", RGB: "rgb(213,110,169)" },
  { RHS: "73A", RGB: "rgb(218,105,166)" },
  { RHS: "73B", RGB: "rgb(236,143,183)" },
  { RHS: "73C", RGB: "rgb(247,174,199)" },
  { RHS: "73D", RGB: "rgb(243,206,217)" },
  { RHS: "N74A", RGB: "rgb(187,42,129)" },
  { RHS: "N74B", RGB: "rgb(196,61,142)" },
  { RHS: "N74C", RGB: "rgb(209,118,177)" },
  { RHS: "N74D", RGB: "rgb(215,140,188)" },
  { RHS: "183A", RGB: "rgb(111,42,48)" },
  { RHS: "183B", RGB: "rgb(118,46,58)" },
  { RHS: "183C", RGB: "rgb(131,51,64)" },
  { RHS: "183D", RGB: "rgb(146,69,83)" },
  { RHS: "184A", RGB: "rgb(139,53,66)" },
  { RHS: "184B", RGB: "rgb(148,56,75)" },
  { RHS: "184C", RGB: "rgb(167,65,89)" },
  { RHS: "184D", RGB: "rgb(177,77,102)" },
  { RHS: "185A", RGB: "rgb(134,35,53)" },
  { RHS: "185B", RGB: "rgb(151,37,73)" },
  { RHS: "185C", RGB: "rgb(168,76,99)" },
  { RHS: "185D", RGB: "rgb(189,101,123)" },
  { RHS: "186A", RGB: "rgb(159,63,98)" },
  { RHS: "186B", RGB: "rgb(178,84,122)" },
  { RHS: "186C", RGB: "rgb(195,126,152)" },
  { RHS: "186D", RGB: "rgb(215,162,182)" },
  { RHS: "N186A", RGB: "rgb(55,49,60)" },
  { RHS: "N186B", RGB: "rgb(58,43,59)" },
  { RHS: "N186C", RGB: "rgb(86,41,55)" },
  { RHS: "N186D", RGB: "rgb(120,33,69)" },
  { RHS: "187A", RGB: "rgb(77,32,47)" },
  { RHS: "187B", RGB: "rgb(99,32,53)" },
  { RHS: "187C", RGB: "rgb(118,27,55)" },
  { RHS: "187D", RGB: "rgb(137,28,71)" },
  { RHS: "N187A", RGB: "rgb(69,50,62)" },
  { RHS: "N187B", RGB: "rgb(109,94,104)" },
  { RHS: "N187C", RGB: "rgb(145,134,142)" },
  { RHS: "N187D", RGB: "rgb(183,174,180)" },
];
