import { rhsToRgb } from "../Styles/RHStoRGB";

export const groupArrayByNumber = (array, groupSize) => {
  const groupedArray = [];
  for (let i = 0; i < array.length; i += groupSize) {
    groupedArray.push(array.slice(i, i + groupSize));
  }
  // console.log("after grouping array", array);
  return groupedArray;
};

// Function to group the array by a specific property
export const groupBy = (array, property) => {
  console.log('array', array)
   const grouped = array.reduce((result, obj) => {
    const key = obj[property];
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(obj);
    return result;
  }, {});
  console.log('grouped', grouped)
  return grouped
};

export const filterProductsByValues = (products, filters) => {
  // Filter products based on the matching values
  const filteredProducts = products.filter((product) => {
    return filters?.every((filter) => {
      // Check if the key is the same as the productAtt in shopFilters
      const productValue = product[filter.productAtt];

      // Check if the value is in selectedValues
      if (filter.type === "selection") {
        if (filter.selectedValues.length === 0) {
          return true;
        }
        return filter.selectedValues.includes(productValue);
      }
      if (filter.type === "range") {
        return filter.maxValue >= productValue && filter.minValue <= productValue;
      }
      return false;
    });
  });

  return filteredProducts;
};

export const resetFilters = (prevFilters) => {
  return prevFilters.map((f) => {
    if (f.type !== "range") {
      f.selectedValues = [];
    } else if (f.type === "range") {
      f.maxValue = f.max;
    }
    return f;
  });
};



export const findMatchRGB = (RHScolor) => {
  return rhsToRgb.find((x) => {
    // Assuming item["RHS main color flower"] is a string
    const productValue = RHScolor?.toString();

    // Remove leading 0 or letter+0
    const cleanedProductValue = productValue?.replace(/^[a-zA-Z]*0/, "");

    // Compare with x.rhs
    return cleanedProductValue === x.RHS;
  })?.RGB;
};

export const isValidCompanyName = (value) => {
  const trimmedCompanyName = value.trim();

  // Check if the company name is not empty
  if (!trimmedCompanyName) {
    return "Company name is required";
  }

  // Check if the company name is within a specific length range
  if (trimmedCompanyName.length < 3) {
    return "Company name is too short.";
  }
  // Check if the company name is within a specific length range
  if (trimmedCompanyName.length > 50) {
    return "Company name is too long.";
  }

  // Check if the company name contains only alphanumeric characters, spaces, and hyphens
  if (!/^[a-zA-Z0-9\s-.]+$/.test(trimmedCompanyName)) {
    const disallowedChar = trimmedCompanyName.match(/[^a-zA-Z0-9\s-]/);

    if (disallowedChar) {
      return `Invalid character: ${disallowedChar[0]}`;
    }
  }

  // Return true if all checks pass
  return null;
};
export const isValidName = (value) => {
  // Check if the first name is not empty
  if (!value.trim()) {
    return "First name is required.";
  }

  // Check if the first name is within a reasonable length range
  if (value.length < 2) {
    return "Name is too short, should be at least 2 letters.";
  }
  if (value.length > 30) {
    return "Entry is too long.";
  }

  // Check if the name contains only letters
  if (!/^[a-zA-Z]+$/.test(value)) {
    if (/\s/.test(value)) {
      return "Name cannot contain spaces.";
    } else {
      const invalidCharMatch = value.match(/[^a-zA-Z]/);
      return `Invalid character in name: ${invalidCharMatch[0]}`;
    }
  }

  // Return true if all checks pass
  return null;
};

export const isValidEmail = (value) => {
  // Basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value) ? null : "Please enter a valid email.";
};

export const isValidPhoneNumber = (value) => {
  // Phone number validation with length between 10 to 16 digits, allowing a leading '+'
  const phoneRegex = /^\+?\d{10,16}$/;

  if (!phoneRegex.test(value)) {
    // Check if the phone number contains letters
    if (/[a-zA-Z]/.test(value)) {
      return "Phone number cannot contain letters";
    }

    // Check if the phone number contains spaces
    if (/\s/.test(value)) {
      return "Phone number cannot contain spaces";
    }

    // Check if the phone number contains invalid characters
    const invalidCharMatch = value.match(/[^+\d]/);

    if (invalidCharMatch) {
      return `Invalid character in phone number: ${invalidCharMatch[0]}`;
    } else {
      return "Invalid phone number";
    }
  }

  return null;
};

export const isValidCompanyCode = (value) => {
  // If the value is empty, no validation is needed
  if (!value.trim()) {
    return null;
  }

  // Check if the registration number is too short
  if (value.length < 6) {
    return "Registration number is too short.";
  }

  // Check if the registration number is too long
  if (value.length > 16) {
    return "Registration number is too long.";
  }
  // Company registration number validation with length between 8 to 16 alphanumeric characters
  const registrationRegex = /^[A-Z0-9]{8,16}$/;
  if (!registrationRegex.test(value)) {
    if (/\s/.test(value)) {
      return "Name cannot contain spaces.";
    } else {
      const invalidCharMatch = value.match(/[^a-zA-Z0-9]/);
      return `Invalid character in registration number: ${invalidCharMatch[0]}`;
    }
  }
  return null;
};

export const isValidCity = (value) => {
  // Basic city name validation with length between 2 to 50 characters and max 3 spaces
  const cityRegex = /^[A-Za-z-]+(?:\s[A-Za-z-]+){0,3}$/;

  if (!cityRegex.test(value)) {
    // Find the first invalid character in the city name
    const invalidCharMatch = value.match(/[^A-Za-z\s-]/);

    if (invalidCharMatch) {
      return `Invalid character in city name: ${invalidCharMatch[0]}`;
    } else {
      return "Invalid city name";
    }
  }

  return null;
};

export const validateAddressLine1 = (addressLine1) => {
  // Check if the address is not empty
  if (!addressLine1.trim()) {
    return "Address is required.";
  }

  // Check if the address doesn't exceed a maximum length
  const maxLength = 100; // You can adjust this based on your requirements
  if (addressLine1.length > maxLength) {
    return `Address must be ${maxLength} characters or less.`;
  }
  // Check if the address doesn't exceed a maximum length
  const minLength = 5; // You can adjust this based on your requirements
  if (addressLine1.length < minLength) {
    return `Address entry is too short.`;
  }

  // Check if the address includes at least one word
  const hasWord = /[A-Za-z]+/.test(addressLine1);
  if (!hasWord) {
    return "Please add street name";
  }
  // If the address passes all checks, return null (no error)
  return null;
};

export const validatePostcode = (postcode) => {
  // Check if the postcode is not empty
  if (!postcode.trim()) {
    return "Postcode is required.";
  }
  // Check if the postcode doesn't exceed a maximum length
  const maxLength = 10; // Adjust this based on your requirements
  if (postcode.length > maxLength) {
    return `Postcode must be ${maxLength} characters or less.`;
  }

  // Check if the postcode matches the expected alphanumeric format
  const postcodeRegex = /^[a-zA-Z0-9\s]*$/;
  if (!postcode.match(postcodeRegex)) {
    // Find the first invalid character in the postcode
    const invalidCharMatch = postcode.match(/[^a-zA-Z0-9\s]/);

    if (invalidCharMatch) {
      return `Invalid character in postcode: ${invalidCharMatch[0]}`;
    } else {
      return "Invalid postcode format.";
    }
  }

  // If the postcode passes all checks, return null (no error)
  return null;
};

export const validateString = (value) => {
  if (value !== null && value.trim() !== "") {
    return null;
  }
  return "Country is required";
};
