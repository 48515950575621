import React, { useState } from "react";
import {
  Arrow,
  FlexContainer,
  GridContainer,
  InlineButton,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";
import { groupArrayByNumber } from "../../helpers/utilis";

const ShopCarousel = ({ items = [], itemsPerSlide = 12, Component = "" }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  // const [items, setVisibleItems] = useState(items);
  const slidesItems = groupArrayByNumber(items, itemsPerSlide);
  const handleClick = (value) => {
    console.log("value", value);
    console.log("visibleItems", items);
    //do or loop when first and user click left
    if (slideIndex === 0 && value === -1) {
      // return;
      return setSlideIndex(items.length - 1);
    }
    //do nothing or loop when slideIndex+1 is equal content length and user click right
    if (slideIndex + 1 === items.length && value === 1) {
      //return;
      return setSlideIndex(0);
    }
    return setSlideIndex((prev) => prev + value);
  };

  return (
    <FlexContainer
      $flexD="column"
      $align="center"
      $width="80%"
      $mWidth="100vw"
      $gap="5vh"
      // $border='2px solid red'
    >
      <GridContainer $gap="5vh" $mGap="3vh" $mColCount="repeat(1,1fr)">
        {slidesItems.length > 0
          ? slidesItems[slideIndex].map((item, index) => (
              <Component key={index} item={item} />
            ))
          : null}
      </GridContainer>
      {slidesItems.length > 1 ? (
        <FlexContainer
          $align="center"
          $gap=".5vw"
          $mGap="2vw"
          // $border="2px solid blue"
          $maxW="25vw"
          $mMaxW="75vw"
        >
          <InlineButton
            $padding="1vh"
            $bRadius="12px"
            onClick={() => handleClick(-1)}
            // $border="2px solid red"
          >
            <Arrow
              $padding=".25vw"
              $mPadding="1vw"
              $direction="left"
              $bColor={`${theme.secColor}`}
            />
          </InlineButton>
          {slidesItems.map((_, index) => (
            <InlineButton
              key={index}
              $active={index === slideIndex}
              onClick={() => setSlideIndex(index)}
              // $border="2px solid red"
              $fSize="1.5vw"
              $mFSize="5vw"
              $width="2vw"
              $mWidth="7vw"
              $color={
                slideIndex === index ? `${theme.main}` : `${theme.secColor}`
              }
              $underL={slideIndex === index}
            >
              {index + 1}
            </InlineButton>
          ))}
          <InlineButton
            $padding="1vh"
            $bRadius="12px"
            onClick={() => handleClick(1)}
            // $border="2px solid red"
          >
            <Arrow
              $padding=".25vw"
              $mPadding="1vw"
              $direction="right"
              $bColor={`${theme.secColor}`}
            />
          </InlineButton>
        </FlexContainer>
      ) : items.length === 1 ? (
        <InlineButton
          $active={true}
          $fSize="1.5vw"
          $width="2vw"
          $color={`${theme.main}`}
          $underL={true}
        >
          1
        </InlineButton>
      ) : null}
    </FlexContainer>
  );
};

export default ShopCarousel;
