import React from "react";
import { appPages } from "../../data";
import {
  AbsFlexContainer,
  CustomButton,
  FlexContainer,
  Line,
  MainButton,
  SecButton,
} from "../../Styles/Global.Styled";
import { useNavigate } from "react-router-dom";
import { theme } from "../../Styles/theme";

const MobileNavBar = ({ setOpen }) => {
  const navigate = useNavigate();
  const handleClick = (pageName) => {
    navigate(`/${pageName}`)
    return setOpen(false)
  }
  return (
    <AbsFlexContainer
      // $border="2px solid red"
      $display="none"
      $mDisplay="flex"
      $width="100vw"
      $height="100%"
      style={{ zIndex: "3" }}
    >
      <FlexContainer
        $width="25vw"
        $bgColor="shadowColor"
        $height="100vh"
        onClick={() => setOpen(false)}
      />
      <FlexContainer
        $bgColor="white"
        $gap="4vh"
        $padding="2.5vh 2vw"
        $flexD="column"
        $width="75vw"
        $height="100%"
      >
        <CustomButton
          $fSize="6vw"
          $width="15vw"
          style={{ alignSelf: "flex-end" }}
          onClick={() => setOpen(false)}
        >
          x
        </CustomButton>
        {appPages.map((page) => (
          <CustomButton
            key={page.name}
            onClick={() => handleClick(page.name)}
            $fSize="6vw"
            $padding="0 1.5vw"
            $justifyC="flex-start"
          >
            {page.uiName}
          </CustomButton>
        ))}
        <Line $bWidth="1px" $bColor={`${theme.shadowColor}`} />
        <MainButton
          $width="50vw"
          $padding=".75vh"
          $bRadius="12px"
          $fSize="5vw"
          $margin="0 auto"
        >
          Login
        </MainButton>
        <SecButton
          $width="50vw"
          $padding=".75vh"
          $bRadius="12px"
          $fSize="5vw"
          $margin="0 auto"
        >
          Join us
        </SecButton>
      </FlexContainer>
    </AbsFlexContainer>
  );
};

export default MobileNavBar;
