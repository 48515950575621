import React from "react";
import styled from "styled-components";
import flowerBlauwB from "../../assets/flowerBlauwB.jpg";
import DescrShortProduct from "./DescrShortProduct";
import { FlexContainer, H4, TxtSpan } from "../../Styles/Global.Styled";
import { useNavigate } from "react-router-dom";
import { findMatchRGB } from "../../helpers/utilis";

const Image = styled.img`
  /* border:2px solid red; */
  height: 30vh;
  width: 100%;
  background-color: white;
  border-radius: 25px 25px 0 0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

const ProductAd = ({ item }) => {
  const navigate = useNavigate();
  const matchingObject = findMatchRGB(item["RHS main color flower"]);

  return (
    <FlexContainer
      $flexD="column"
      $width="20vw"
      $mWidth="85vw"
      $bRadius="25px"
      // $height="50vh"
      $gap="1.75vh"
      $bgColor="white"
      $align="center"
      // $justifyC="space-between"
      $padding="0 0 2vh 0"
      // $border='2px solid red'
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/product/${item["Product code"]}`)}
    >
      <Image
        src={item["Product image"] ? item["Product image"] : flowerBlauwB}
      />
      <H4 $fSize=".85vw" $mFSize="4vw">
        {item["Product name"]}
      </H4>
      <FlexContainer $height="auto" $width="100%" $justifyC="space-evenly">
        <DescrShortProduct label="Code" value={item["Product code"]} />
        <DescrShortProduct
          label="Color"
          value={item["RHS main color flower"]}
          matchingObject={matchingObject}
        />
        <DescrShortProduct
          label="Height"
          value={item["Flower height"] || item["Plant height"]}
        />
        <DescrShortProduct
          label="Diameter"
          value={item["Flower height"] || item["Plant height"]}
        />
      </FlexContainer>
      <FlexContainer $width="90%" $justifyC="space-between">
        <TxtSpan $color="shadowColor" style={{ fontStyle: "italic" }}>
          Price from
        </TxtSpan>
        <TxtSpan $color="secColor">€1.99</TxtSpan>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProductAd;
