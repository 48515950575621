import React, { useState, createContext } from "react";
import { useEffect } from "react";

const initialState = JSON.parse(localStorage.getItem("cart")) || [];
export const CartContext = createContext(initialState);

export const CartContextProvider = (props) => {
  const [cart, setCart] = useState(initialState);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart: cart, setCart }}>
      {props.children}
    </CartContext.Provider>
  );
};
