import React from "react";
import NavBar from "../NavBar/NavBar";
import { Footer } from "../Footer/OriginFooter";
import { FlexContainer, H1, H2 } from "../../Styles/Global.Styled";
import ContactSection from "./SubSections/ContactSection";
import ContactForm from "../../Forms/ContactForm/ContactForm";

const ContactUsPage = ({ menuOpen, setOpen }) => {
  return (
    <FlexContainer $flexD="column">
      <NavBar />
      <FlexContainer
        $flexD="column"
        $height="25vh"
        $align="center"
        $justifyC="center"
        $bgColor="bgColor"
      >
        <H1 $fSize="4vh">Contact Us</H1>
        <H2 $fWeight="200" $fSize="2vh">
          Wholesale Blooms, Business Blooms
        </H2>
      </FlexContainer>
      <ContactSection /> 
      <FlexContainer
        $flexD="column"
        $align="center"
        $gap="2vw"
        $padding="0vh 20% 10vh 20%"
      >
        <H1 $fSize="2.5vh">Write us a message</H1>
        <ContactForm />
      </FlexContainer>
      <Footer />
    </FlexContainer>
  );
};

export default ContactUsPage;
