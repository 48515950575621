import React, { useState, createContext } from "react";
import { useEffect } from "react";
import { shopFilters } from "../data";

const initialState =
  JSON.parse(localStorage.getItem("fltr")) || shopFilters || {};
console.log("initialState", initialState);
export const FiltersContext = createContext(initialState);

export const FiltersContextProvider = (props) => {
  const [fltr, setFilters] = useState(initialState);

  useEffect(() => {
    localStorage.setItem("fltr", JSON.stringify(fltr));
  }, [fltr]);

  return (
    <FiltersContext.Provider value={{ fltr: fltr, setFilters }}>
      {props.children}
    </FiltersContext.Provider>
  );
};
