import React from "react";
import Rectangle14 from "../../../assets/flowersPictures/Rectangle14.png";
import { FlexDivWithBG, H1, Paragraph } from "../../../Styles/Global.Styled";

const PromoSection = () => {
  return (
    <FlexDivWithBG
    //   $border="4px solid red"
      // $height="75vh"
      $flexD="column"
      $padding="5% 20%"
      $mPadding="5vh 5vw"
      $margin="10vh 0"
      $bgImage={Rectangle14}
      $align="center"
      $gap='3vh'
    >
      <H1 $color="white" $fSize="4.5vh" $margin="0 0 2vh 0">Your Floral Partner</H1>
      <Paragraph $txtAlign="center" $color="white" $fSize="3vh">
        Whether you are a florist, event planner, or retailer, Dutch Florist
        Supply is your dedicated floral partner. Our commitment to excellence
        extends beyond our products to personalized customer service that
        exceeds expectations. We strive to build lasting relationships with our
        clients, understanding their unique needs and delivering floral
        solutions that captivate and inspire.
      </Paragraph>
      <Paragraph $txtAlign="center" $color="white" $fSize="3vh">
        Thank you for considering Dutch Florist Supply as your wholesale
        florist. Join us in celebrating the timeless beauty of flowers and let
        our blooms be the language through which you express your emotions,
        creativity, and style.
      </Paragraph>
    </FlexDivWithBG>
  );
};

export default PromoSection;
