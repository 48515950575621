import React from "react";
import {
  AbsTxtSpan,
  DynamicLabel,
  FlexContainer,
  Input,
  IsRequiredStar,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";

const TextInput = ({
  width,
  mWidth,
  height,
  label,
  required = true,
  setForm,
  value,
  setIsValid,
  isValid,
  errors,
  type = "text",
}) => {
  
  const runValidation = (e) => {
    if (typeof setIsValid === "function" && typeof isValid === "function") {
      setIsValid((prev) => ({
        ...prev,
        [e.target.id]: isValid(e.target.value),
      }));
    }
  };
  
  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    if (errors !== undefined) {
      runValidation(e);
    }
  };

  return (
    <FlexContainer $width={width} $mWidth={mWidth} $height={height} $padding="0 1vw">
      <Input
        $border={`1px solid ${errors ? "red" : theme.secColor}`}
        type={type}
        $width="100%"
        $mFSize='2vh'
        $padding="0 .5vw"
        $bRadius="6px"
        value={value}
        style={{ zIndex: "1" }}
        id={label}
        onChange={handleChange}
        onBlur={runValidation}
      />
      {value ? (
        <DynamicLabel
          $position="absolute"
          $height="1.5vh"
          $top="-1vh"
          $left="1.5vw"
          $fSize="1.5vh"
          $zIndex="1"
          $bgColor="white"
        >
          {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      ) : (
        <DynamicLabel $position="absolute" $height="95%" $padding="0 .55vw">
          {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      )}
      {errors ? (
        <AbsTxtSpan
          $color="red"
          $fSize="1.25vh"
          $height="1.5vh"
          $bottom="-1.9vh"
        >
          {errors}
        </AbsTxtSpan>
      ) : null}
    </FlexContainer>
  );
};

export default TextInput;
