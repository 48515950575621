import React, { useState } from "react";
import { CustomButton, FlexContainer, H3 } from "../../../Styles/Global.Styled";
import { productsSample, saleCategoriesContent } from "../../../data";
import Carousel from "../../../Components/Carousel/Carousel";
import ProductAd from "../../../Components/Product/ProductAd";
import { groupBy } from "../../../helpers/utilis";
import { theme } from "../../../Styles/theme";

const FeatureProduct = () => {
  const [productGroup] = useState(groupBy(productsSample, "Application"));
  const [selectedCat, setCategory] = useState("Cut flowers");

  return (
    <FlexContainer
      $flexD="column"
      $padding="3vh 7.5%"
      $mPadding="3vh 2.5%"
      $bgColor="bgColor"
      $gap='2vh'
    >
      <FlexContainer $mGap="2vh" $mFlexD="column">
        <H3 $fSize="38px" $mFSize="6.5vw">
          Our Featured Collection
        </H3>
        <FlexContainer $flexG="1" $justifyC="flex-end" $gap="1vw" $mGap="2vw" >
          {saleCategoriesContent.map((saleCat) => (
            <CustomButton
              key={saleCat.id}
              $fSize="22px"
              $mFSize="3.5vw"
              $color={saleCat.id === selectedCat ? `${theme.main}` : null}
              onClick={() => setCategory(saleCat.id)}
              // $border='2px solid red'
            >
              {saleCat.name}
            </CustomButton>
          ))}
        </FlexContainer>
      </FlexContainer>
      <Carousel items={productGroup[selectedCat] || []} Component={ProductAd} />
    </FlexContainer>
  );
};

export default FeatureProduct;
