import flowerBlauwB from "./assets/flowerBlauwB.jpg";
import flower130309 from "./assets/flower130309.jpg";
import flower130310 from "./assets/flower130310.jpg";
import flower130311 from "./assets/flower130311.jpg";
import flower130312 from "./assets/flower130312.jpg";
import flower130301 from "./assets/flower130301.jpg";
import flower130251 from "./assets/flower130251.jpg";
import flower130289 from "./assets/flower130289.jpg";
import flower129206 from "./assets/flower129206.jpg";
import flower130145 from "./assets/flower130145.jpg";
import rectangle2 from "./assets/flowersPictures/rectangle2.png";
import rectangle3 from "./assets/flowersPictures/rectangle3.png";
import rectangle4 from "./assets/flowersPictures/rectangle4.png";
import rectangle5 from "./assets/flowersPictures/rectangle5.png";
import Rectangle9 from "./assets/flowersPictures/Rectangle9.png";
import Rectangle10 from "./assets/flowersPictures/Rectangle10.png";
import Rectangle11 from "./assets/flowersPictures/Rectangle11.png";
import Rectangle12 from "./assets/flowersPictures/Rectangle12.png";
import Ellipse1 from "./assets/Ellipse1.png";
import Ellipse5 from "./assets/Ellipse5.png";
import Ellipse7 from "./assets/Ellipse7.png";

export const headerTabs = ["Home", "Shop", "About us", "Contact us"];

export const appPages = [
  { name: "Home", uiName: "Home", isOpen: true },
  { name: "Shop", uiName: "Shop", isOpen: false, product: {} },
  { name: "AboutUs", uiName: "About us", isOpen: false },
  { name: "ContactUs", uiName: "Contact us", isOpen: false },
];

export const saleCategories = [
  "Cut Flowers",
  "Home Plants",
  "Seeds",
  "Accessories",
];

export const locations = [
  {
    type: "Main office",
    address: `Steetname 101B,
  1000 AA, Naaldwijk,
  The Netherlands`,
  },
  {
    type: "Sales office",
    address: `101 Steetname,
    Cairo, Egypt`,
  },
  {
    type: "Support office",
    address: `101B Steetname,
  AA99 5EX, London,
  United Kingdom`,
  },
];
export const countryOptions = ["Netherlands", "Egypt", "United Kingdom"];

export const saleCategoriesContent = [
  {
    name: "Cut Flowers",
    id: "Cut flowers",
    buttonImage: rectangle2,
  },
  {
    name: "Home Plants",
    id: "Houseplants",
    buttonImage: rectangle3,
  },
  {
    name: "Seeds",
    id: "Seeds",
    buttonImage: rectangle4,
  },
  {
    name: "Accessories",
    id: "Accessories",
    buttonImage: rectangle5,
  },
];

export const sortByOptions = [
  "Relevance",
  "Best Sellers",
  "Price: High-Low",
  "Price: Low-High",
];
export const shopFilters = [
  {
    name: "Categories",
    productAtt: "Application",
    type: "selection",
    values: ["Cut flowers", "Houseplants", "Seeds", "Accessories"],
    selectedValues: [],
  },
  {
    name: "Type/Genus",
    productAtt: "Genus (type)",
    type: "selection",
    values: [
      "Hydrangea",
      "Rosa",
      "Dianthus",
      "Cymbidium",
      "Tulipa",
      "Alstroemeria",
    ],
    selectedValues: [],
  },
  {
    name: "Color",
    productAtt: "Main color flower",
    type: "selection",
    values: [
      "white",
      "pink",
      "red",
      "prange",
      "yellow",
      "green",
      "cyan",
      "blue",
      "violet",
      "purple",
      "magenta",
    ],
    selectedValues: [],
  },
  // {
  //   title: "Country of Origin",
  //   productAtt: "",
  //   values: ["Netherland", "Kenya", "Ecuador"],
  //   filteredValues:[],
  // },
  // {
  //   title: "Price",
  //   type: "range",
  //   min: 0,
  //   maxValue: 100,
  //   filteredValues:100,
  // },
  {
    name: "Height/Length",
    productAtt: "maxHeight",
    type: "range",
    min: 0,
    maxValue: 15,
    minValue: 0,
    max: 15,
  },
];
// export const products = [
//   {
//     Application: "Houseplants",
//     productCode: 655,
//     ProductName: "Hydrangea macrophylla 'Blauer Zwerg'",
//     productAbbreviation: "HYDR M BLAUER ZWERG",
//     Registrator: "Floricode",
//     registrationCode: 106577,
//     mainColorFlower: "blue",
//     RHSMainColorFlower: "100B",
//     sellingUnits: "per item",
//     availability: "[A, M]",
//     genus: "Hydrangea",
//     cultiVar: "Blauer Zwerg",
//     Group: "Hydrangea macrophylla",
//     groupCode: 20203601,
//     Color: "100B",
//     flowerType: "Single",
//     Flower color: "Blue-medium blue-100C",
//     Petal edge: "",
//     Leaf shape: "Oval / elliptic",
//     Flower diameter: "3.4 - 4 cm",
//     Flower height: "",
//     Plant height: "10 - 15 cm",
//     Leaf size: "10 -15 cm",
//     Inflorescence: "",
//     soilpHReq: "Slightly acidic (pH 4.5 - 6.5), \nAlkaline (pH > 7.5), \nNeutral (pH 6.5 - 7.5)",
//     lightConditions: "Sunny; Semi-shades",
//     leafColor: "Dark green",
//     flowerScent: "",
//     lipColor: "",
//     flowerColorDist: ""
//   },
//   {
//     Application: "Cut flowers",
//     productCode: 130309,
//     ProductName: "Rosa large flowered Renaissance",
//     productAbbreviation: "R GR RENAISSANCE",
//     Registrator: "Floricode",
//     registrationCode: 283770,
//     mainColorFlower: "pink",
//     RHSMainColorFlower: "073B",
//     sellingUnits: "per item",
//     availability: "[M]",
//     genus: "Rosa",
//     cultiVar: "Tan16142",
//     Group: "Rosa large flowered",
//     groupCode: 10100101,
//     Color: "073B",
//     flowerType: "Double (pleniflorous)",
//     Flower color: "",
//     Petal edge: "Pink-medium blue pink-073B",
//     Leaf shape: "",
//     Flower diameter: "8 - 8.5 cm",
//     Flower height: "4 - 4.5 cm",
//     Plant height: "",
//     Leaf size: "",
//     Inflorescence: "Single-flowered",
//     soilpHReq: "",
//     lightConditions: "",
//     leafColor: "Dark green",
//     flowerScent: "",
//     lipColor: "",
//     flowerColorDist: "Unicolored"
//   },
//   {
//     Application: "Cut flowers",
//     productCode: 130310,
//     ProductName: "Rosa large flowered Boardwalk",
//     productAbbreviation: "R GR BOARDWALK",
//     Registrator: "Floricode",
//     registrationCode: 283771,
//     mainColorFlower: "orange",
//     RHSMainColorFlower: "026A",
//     sellingUnits: "per item",
//     availability: "[M]",
//     genus: "Rosa",
//     cultiVar: "IPK347915",
//     Group: "Rosa large flowered",
//     groupCode: 10100101,
//     Color: "026A",
//     flowerType: "Double (pleniflorous)",
//     Flower color: "Orange-medium orange-026A",
//     Petal edge: "",
//     Leaf shape: "",
//     Flower diameter: "8 - 8.5 cm",
//     Flower height: "4 - 4.5 cm",
//     Plant height: "",
//     Leaf size: "",
//     Inflorescence: "Single-flowered",
//     soilpHReq: "",
//     lightConditions: "",
//     leafColor: "Dark green",
//     flowerScent: "",
//     lipColor: "",
//     flowerColorDist: "With trace; \nMulti-colored"
//   },
//   {
//     Application: "Houseplants",
//     productCode: 130311,
//     ProductName: "Euphorbia pulcherrima Qismas Bond",
//     productAbbreviation: "EUPHOR P QISMAS BOND",
//     Registrator: "Floricode",
//     registrationCode: 283772,
//     mainColorFlower: "red",
//     RHSMainColorFlower: "N045B",
//     sellingUnits: "per item",
//     availability: "[N , D]",
//     genus: "",
//     cultiVar: "",
//     Group: "Euphorbia pulcherrima",
//     groupCode: 20201001,
//     Color: "N045B",
//     flowerType: "",
//     Flower color: "",
//     Petal edge: "",
//     Leaf shape: "",
//     Flower diameter: "",
//     Flower height: "",
//     Plant height: "",
//     Leaf size: "",
//     Inflorescence: "",
//     soilpHReq: "",
//     lightConditions: "",
//     leafColor: "",
//     flowerScent: "",
//     lipColor: "",
//     flowerColorDist: ""
//   },
//   {
//     Application: "Cut flowers",
//     productCode: 130312,
//     ProductName: "Dianthus spray Sommelier",
//     productAbbreviation: "DI TR SOMMELIER",
//     Registrator: "Floricode",
//     registrationCode: 283774,
//     mainColorFlower: "purple",
//     RHSMainColorFlower: "N077C",
//     sellingUnits: "per item",
//     availability: "[M]",
//     genus: "Dianthus",
//     cultiVar: "Kled01v20",
//     Group: "Dianthus spray",
//     groupCode: 10100201,
//     Color: "[N077C, N066B]",
//     flowerType: "Double (pleniflorous)",
//     Flower color: "Purple-dark violet-N077C;\nPind-medium purple red-N066B",
//     Petal edge: "Sinuate",
//     Leaf shape: "",
//     Flower diameter: "5.5 - 6 cm",
//     Flower height: "4.5 - 5 cm",
//     Plant height: "",
//     Leaf size: "",
//     Inflorescence: "Raceme",
//     soilpHReq: "",
//     lightConditions: "",
//     leafColor: "Green gray",
//     flowerScent: "",
//     lipColor: "",
//     flowerColorDist: "With trace; \nBicolored"
//   },
//   {
//     Application: "Houseplants",
//     productCode: 130301,
//     ProductName: "Cymbidium 'Indian Summer'",
//     productAbbreviation: "CYMB INDIAN SUMMER",
//     Registrator: "Floricode",
//     registrationCode: 283718,
//     mainColorFlower: "brown",
//     RHSMainColorFlower: "164A",
//     sellingUnits: "per item",
//     availability: "",
//     genus: "Cymbidium",
//     cultiVar: "Indian Summer",
//     Group: "Cymbidium",
//     groupCode: 20910701,
//     Color: "[164A, 185A]",
//     flowerType: "",
//     Flower color: "Brown-light brown-164A;\nRed-dark purple red-185A",
//     Petal edge: "",
//     Leaf shape: "Linear",
//     Flower diameter: "5 - 5.5 cm",
//     Flower height: "",
//     Plant height: "",
//     Leaf size: "",
//     Inflorescence: "Raceme",
//     soilpHReq: "",
//     lightConditions: "",
//     leafColor: "Dark green",
//     flowerScent: "",
//     lipColor: "Red-dark purple red-059A",
//     flowerColorDist: "Striate;\nDotted;\nMulti-colored"
//   }
//  ]

export const productsSample = [
  {
    Application: "Houseplants",
    "Product code": 655,
    "Product name": "Hydrangea macrophylla 'Blauer Zwerg'",
    "Product abbreviation": "HYDR M BLAUER ZWERG",
    Registrator: "Floricode",
    "Registration Code": 106577,
    "Product image": flowerBlauwB,
    "Main color flower": "blue",
    "RHS main color flower": "100B",
    "Selling units": "per item",
    Availability: "[A, M]",
    "Genus (type)": "Hydrangea",
    "Cultivar (variant)": "Blauer Zwerg",
    Group: "Hydrangea macrophylla",
    "Group code": 20203601,
    Color: "100B",
    "Flower type": "Single",
    "Flower color": "Blue-medium blue-100C",
    "Petal edge": "",
    "Leaf shape": "Oval / elliptic",
    "Flower diameter": "3.4 - 4 cm",
    "Flower height": "",
    "Plant height": "10 - 15 cm",
    minHeight: 10,
    maxHeight: 15,
    "Leaf size": "10 -15 cm",
    Inflorescence: "",
    "soil PH req.":
      "Slightly acidic (pH 4.5 - 6.5), \nAlkaline (pH > 7.5), \nNeutral (pH 6.5 - 7.5)",
    "Light conditions": "Sunny; Semi-shades",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "",
  },
  {
    Application: "Cut flowers",
    "Product code": 130309,
    "Product name": "Rosa large flowered Renaissance",
    "Product abbreviation": "R GR RENAISSANCE",
    Registrator: "Floricode",
    "Registration Code": 283770,
    "Product image": flower130309,
    "Main color flower": "pink",
    "RHS main color flower": "073B",
    "Selling units": "per item",
    Availability: "[M]",
    "Genus (type)": "Rosa",
    "Cultivar (variant)": "Tan16142",
    Group: "Rosa large flowered",
    "Group code": 10100101,
    Color: "073B",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "",
    "Petal edge": "Pink-medium blue pink-073B",
    "Leaf shape": "",
    "Flower diameter": "8 - 8.5 cm",
    "Flower height": "4 - 4.5 cm",
    minHeight: 4,
    maxHeight: 4.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Single-flowered",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130310,
    "Product name": "Rosa large flowered Boardwalk",
    "Product abbreviation": "R GR BOARDWALK",
    Registrator: "Floricode",
    "Registration Code": 283771,
    "Product image": flower130310,
    "Main color flower": "orange",
    "RHS main color flower": "026A",
    "Selling units": "per item",
    Availability: "[M]",
    "Genus (type)": "Rosa",
    "Cultivar (variant)": "IPK347915",
    Group: "Rosa large flowered",
    "Group code": 10100101,
    Color: "026A",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "Orange-medium orange-026A",
    "Petal edge": "",
    "Leaf shape": "",
    "Flower diameter": "8 - 8.5 cm",
    "Flower height": "4 - 4.5 cm",
    minHeight: 4,
    maxHeight: 4.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Single-flowered",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "With trace; \nMulti-colored",
  },
  {
    Application: "Houseplants",
    "Product code": 130311,
    "Product name": "Euphorbia pulcherrima Qismas Bond",
    "Product abbreviation": "EUPHOR P QISMAS BOND",
    Registrator: "Floricode",
    "Registration Code": 283772,
    "Product image": flower130311,
    "Main color flower": "red",
    "RHS main color flower": "N045B",
    "Selling units": "per item",
    Availability: "[N , D]",
    "Genus (type)": "",
    "Cultivar (variant)": "",
    Group: "Euphorbia pulcherrima",
    "Group code": 20201001,
    Color: "N045B",
    "Flower type": "",
    "Flower color": "",
    "Petal edge": "",
    "Leaf shape": "",
    "Flower diameter": "",
    "Flower height": "",
    minHeight: 0,
    maxHeight: 0,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "",
  },
  {
    Application: "Cut flowers",
    "Product code": 130312,
    "Product name": "Dianthus spray Sommelier",
    "Product abbreviation": "DI TR SOMMELIER",
    Registrator: "Floricode",
    "Registration Code": 283774,
    "Main color flower": "purple",
    "RHS main color flower": "N077C",
    "Product image": flower130312,
    "Selling units": "per item",
    Availability: "[M]",
    "Genus (type)": "Dianthus",
    "Cultivar (variant)": "Kled01v20",
    Group: "Dianthus spray",
    "Group code": 10100201,
    Color: "[N077C, N066B]",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "Purple-dark violet-N077C;\nPind-medium purple red-N066B",
    "Petal edge": "Sinuate",
    "Leaf shape": "",
    "Flower diameter": "5.5 - 6 cm",
    "Flower height": "4.5 - 5 cm",
    minHeight: 4.5,
    maxHeight: 5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Raceme",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Green gray",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "With trace; \nBicolored",
  },
  {
    Application: "Houseplants",
    "Product code": 130301,
    "Product name": "Cymbidium 'Indian Summer'",
    "Product abbreviation": "CYMB INDIAN SUMMER",
    Registrator: "Floricode",
    "Registration Code": 283718,
    "Main color flower": "brown",
    "RHS main color flower": "164A",
    "Product image": flower130301,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Cymbidium",
    "Cultivar (variant)": "Indian Summer",
    Group: "Cymbidium",
    "Group code": 20910701,
    Color: "[164A, 185A]",
    "Flower type": "",
    "Flower color": "Brown-light brown-164A;\nRed-dark purple red-185A",
    "Petal edge": "",
    "Leaf shape": "Linear",
    "Flower diameter": "5 - 5.5 cm",
    "Flower height": "",
    minHeight: 0,
    maxHeight: 0,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Raceme",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "Red-dark purple red-059A",
    "Flower color dist": "Striate;\nDotted;\nMulti-colored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130251,
    "Product name": "Dianthus standard 'Teide'",
    "Product abbreviation": "DI ST TEIDE",
    Registrator: "Floricode",
    "Registration Code": 283610,
    "Main color flower": "red",
    "RHS main color flower": "043A",
    "Product image": flower130251,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Dianthus",
    "Cultivar (variant)": "Teide",
    Group: "Dianthus standard",
    "Group code": 10100202,
    Color: "",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "Red-medium red-043A",
    "Petal edge": "Serrate",
    "Leaf shape": "Linear",
    "Flower diameter": "4.5 - 5 cm",
    "Flower height": "7 - 7.5 cm",
    minHeight: 7,
    maxHeight: 7.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Single-flowered",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Green grey",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130289,
    "Product name": "Tulipa (Triump Grp) 'Twizzle'",
    "Product abbreviation": "TU EN TWIZZLE",
    Registrator: "Floricode",
    "Registration Code": 281648,
    "Main color flower": "red",
    // "RHS main color flower": ["N045B","009A"],
    "RHS main color flower": "009A",
    "Product image": flower130289,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Tulipa",
    "Cultivar (variant)": "Twizzle",
    Group: "Tulipa",
    "Group code": 10301101,
    Color: "",
    "Flower type": "Single",
    "Flower color": "Yellow-medium yellow-0094; Red-dark red-N045B",
    "Petal edge": "Serrate",
    "Leaf shape": "Linear",
    "Flower diameter": "",
    "Flower height": "5 - 5.5 cm",
    minHeight: 5,
    maxHeight: 5.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Bicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 129206,
    "Product name": "Tulipa (Triumph Grp) 'Wifi'",
    "Product abbreviation": "TU EN WIFI",
    Registrator: "Floricode",
    "Registration Code": 281673,
    "Main color flower": "red",
    // "RHS main color flower": ["N045B","009A"],
    "RHS main color flower": "060D",
    "Product image": flower129206,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Tulipa",
    "Cultivar (variant)": "Twizzle",
    Group: "Tulipa",
    "Group code": 10301101,
    Color: "",
    "Flower type": "Single",
    "Flower color": "Pink-medium purple-060D",
    "Petal edge": "Serrate",
    "Leaf shape": "Linear",
    "Flower diameter": "",
    "Flower height": "5.5 - 6 cm",
    minHeight: 5.5,
    maxHeight: 6,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130145,
    "Product name": "Alstroemeria Freeze",
    "Product abbreviation": "ALSTR FREEZE",
    Registrator: "Floricode",
    "Registration Code": 283395,
    "Main color flower": "white",
    // "RHS main color flower": ["N045B","009A"],
    "RHS main color flower": "NN155D",
    "Product image": flower130145,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Alstroemeria",
    "Cultivar (variant)": "Tesfreeze",
    Group: "Alstroemeria",
    "Group code": 10100301,
    Color: "",
    "Flower type": "",
    "Flower color": "White-white-NN155D",
    "Petal edge": "",
    "Leaf shape": "",
    "Flower diameter": "6 - 6.5 cm",
    "Flower height": "6.5 - 7 cm",
    minHeight: 6.5,
    maxHeight: 7,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored with trace; Striate",
  },
  {
    Application: "Houseplants",
    "Product code": 655,
    "Product name": "Hydrangea macrophylla 'Blauer Zwerg'",
    "Product abbreviation": "HYDR M BLAUER ZWERG",
    Registrator: "Floricode",
    "Registration Code": 106577,
    "Product image": flowerBlauwB,
    "Main color flower": "blue",
    "RHS main color flower": "100B",
    "Selling units": "per item",
    Availability: "[A, M]",
    "Genus (type)": "Hydrangea",
    "Cultivar (variant)": "Blauer Zwerg",
    Group: "Hydrangea macrophylla",
    "Group code": 20203601,
    Color: "100B",
    "Flower type": "Single",
    "Flower color": "Blue-medium blue-100C",
    "Petal edge": "",
    "Leaf shape": "Oval / elliptic",
    "Flower diameter": "3.4 - 4 cm",
    "Flower height": "",
    "Plant height": "10 - 15 cm",
    minHeight: 10,
    maxHeight: 15,
    "Leaf size": "10 -15 cm",
    Inflorescence: "",
    "soil PH req.":
      "Slightly acidic (pH 4.5 - 6.5), \nAlkaline (pH > 7.5), \nNeutral (pH 6.5 - 7.5)",
    "Light conditions": "Sunny; Semi-shades",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "",
  },
  {
    Application: "Cut flowers",
    "Product code": 130309,
    "Product name": "Rosa large flowered Renaissance",
    "Product abbreviation": "R GR RENAISSANCE",
    Registrator: "Floricode",
    "Registration Code": 283770,
    "Product image": flower130309,
    "Main color flower": "pink",
    "RHS main color flower": "073B",
    "Selling units": "per item",
    Availability: "[M]",
    "Genus (type)": "Rosa",
    "Cultivar (variant)": "Tan16142",
    Group: "Rosa large flowered",
    "Group code": 10100101,
    Color: "073B",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "",
    "Petal edge": "Pink-medium blue pink-073B",
    "Leaf shape": "",
    "Flower diameter": "8 - 8.5 cm",
    "Flower height": "4 - 4.5 cm",
    minHeight: 4,
    maxHeight: 4.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Single-flowered",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130310,
    "Product name": "Rosa large flowered Boardwalk",
    "Product abbreviation": "R GR BOARDWALK",
    Registrator: "Floricode",
    "Registration Code": 283771,
    "Product image": flower130310,
    "Main color flower": "orange",
    "RHS main color flower": "026A",
    "Selling units": "per item",
    Availability: "[M]",
    "Genus (type)": "Rosa",
    "Cultivar (variant)": "IPK347915",
    Group: "Rosa large flowered",
    "Group code": 10100101,
    Color: "026A",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "Orange-medium orange-026A",
    "Petal edge": "",
    "Leaf shape": "",
    "Flower diameter": "8 - 8.5 cm",
    "Flower height": "4 - 4.5 cm",
    minHeight: 4,
    maxHeight: 4.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Single-flowered",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "With trace; \nMulti-colored",
  },
  {
    Application: "Houseplants",
    "Product code": 130311,
    "Product name": "Euphorbia pulcherrima Qismas Bond",
    "Product abbreviation": "EUPHOR P QISMAS BOND",
    Registrator: "Floricode",
    "Registration Code": 283772,
    "Product image": flower130311,
    "Main color flower": "red",
    "RHS main color flower": "N045B",
    "Selling units": "per item",
    Availability: "[N , D]",
    "Genus (type)": "",
    "Cultivar (variant)": "",
    Group: "Euphorbia pulcherrima",
    "Group code": 20201001,
    Color: "N045B",
    "Flower type": "",
    "Flower color": "",
    "Petal edge": "",
    "Leaf shape": "",
    "Flower diameter": "",
    "Flower height": "",
    minHeight: 0,
    maxHeight: 0,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "",
  },
  {
    Application: "Cut flowers",
    "Product code": 130312,
    "Product name": "Dianthus spray Sommelier",
    "Product abbreviation": "DI TR SOMMELIER",
    Registrator: "Floricode",
    "Registration Code": 283774,
    "Main color flower": "purple",
    "RHS main color flower": "N077C",
    "Product image": flower130312,
    "Selling units": "per item",
    Availability: "[M]",
    "Genus (type)": "Dianthus",
    "Cultivar (variant)": "Kled01v20",
    Group: "Dianthus spray",
    "Group code": 10100201,
    Color: "[N077C, N066B]",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "Purple-dark violet-N077C;\nPind-medium purple red-N066B",
    "Petal edge": "Sinuate",
    "Leaf shape": "",
    "Flower diameter": "5.5 - 6 cm",
    "Flower height": "4.5 - 5 cm",
    minHeight: 4.5,
    maxHeight: 5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Raceme",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Green gray",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "With trace; \nBicolored",
  },
  {
    Application: "Houseplants",
    "Product code": 130301,
    "Product name": "Cymbidium 'Indian Summer'",
    "Product abbreviation": "CYMB INDIAN SUMMER",
    Registrator: "Floricode",
    "Registration Code": 283718,
    "Main color flower": "brown",
    "RHS main color flower": "164A",
    "Product image": flower130301,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Cymbidium",
    "Cultivar (variant)": "Indian Summer",
    Group: "Cymbidium",
    "Group code": 20910701,
    Color: "[164A, 185A]",
    "Flower type": "",
    "Flower color": "Brown-light brown-164A;\nRed-dark purple red-185A",
    "Petal edge": "",
    "Leaf shape": "Linear",
    "Flower diameter": "5 - 5.5 cm",
    "Flower height": "",
    "Plant height": "",
    minHeight: 0,
    maxHeight: 0,
    "Leaf size": "",
    Inflorescence: "Raceme",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "Red-dark purple red-059A",
    "Flower color dist": "Striate;\nDotted;\nMulti-colored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130251,
    "Product name": "Dianthus standard 'Teide'",
    "Product abbreviation": "DI ST TEIDE",
    Registrator: "Floricode",
    "Registration Code": 283610,
    "Main color flower": "red",
    "RHS main color flower": "043A",
    "Product image": flower130251,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Dianthus",
    "Cultivar (variant)": "Teide",
    Group: "Dianthus standard",
    "Group code": 10100202,
    Color: "",
    "Flower type": "Double (pleniflorous)",
    "Flower color": "Red-medium red-043A",
    "Petal edge": "Serrate",
    "Leaf shape": "Linear",
    "Flower diameter": "4.5 - 5 cm",
    "Flower height": "7 - 7.5 cm",
    minHeight: 7,
    maxHeight: 7.5,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "Single-flowered",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Green grey",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130289,
    "Product name": "Tulipa (Triump Grp) 'Twizzle'",
    "Product abbreviation": "TU EN TWIZZLE",
    Registrator: "Floricode",
    "Registration Code": 281648,
    "Main color flower": "red",
    // "RHS main color flower": ["N045B","009A"],
    "RHS main color flower": "009A",
    "Product image": flower130289,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Tulipa",
    "Cultivar (variant)": "Twizzle",
    Group: "Tulipa",
    "Group code": 10301101,
    Color: "",
    "Flower type": "Single",
    "Flower color": "Yellow-medium yellow-0094; Red-dark red-N045B",
    "Petal edge": "Serrate",
    "Leaf shape": "Linear",
    "Flower diameter": "",
    "Flower height": "5 - 5.5 cm",
    "Plant height": "",
    minHeight: 5,
    maxHeight: 5.5,
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Bicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 129206,
    "Product name": "Tulipa (Triumph Grp) 'Wifi'",
    "Product abbreviation": "TU EN WIFI",
    Registrator: "Floricode",
    "Registration Code": 281673,
    "Main color flower": "red",
    // "RHS main color flower": ["N045B","009A"],
    "RHS main color flower": "060D",
    "Product image": flower129206,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Tulipa",
    "Cultivar (variant)": "Twizzle",
    Group: "Tulipa",
    "Group code": 10301101,
    Color: "",
    "Flower type": "Single",
    "Flower color": "Pink-medium purple-060D",
    "Petal edge": "Serrate",
    "Leaf shape": "Linear",
    "Flower diameter": "",
    "Flower height": "5.5 - 6 cm",
    minHeight: 5.5,
    maxHeight: 6,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored",
  },
  {
    Application: "Cut flowers",
    "Product code": 130145,
    "Product name": "Alstroemeria Freeze",
    "Product abbreviation": "ALSTR FREEZE",
    Registrator: "Floricode",
    "Registration Code": 283395,
    "Main color flower": "white",
    // "RHS main color flower": ["N045B","009A"],
    "RHS main color flower": "NN155D",
    "Product image": flower130145,
    "Selling units": "per item",
    Availability: "",
    "Genus (type)": "Alstroemeria",
    "Cultivar (variant)": "Tesfreeze",
    Group: "Alstroemeria",
    "Group code": 10100301,
    Color: "",
    "Flower type": "",
    "Flower color": "White-white-NN155D",
    "Petal edge": "",
    "Leaf shape": "",
    "Flower diameter": "6 - 6.5 cm",
    "Flower height": "6.5 - 7 cm",
    minHeight: 6.5,
    maxHeight: 7,
    "Plant height": "",
    "Leaf size": "",
    Inflorescence: "",
    "soil PH req.": "",
    "Light conditions": "",
    "Leaf color": "Dark green",
    "Flower scent": "",
    "Lip color": "",
    "Flower color dist": "Unicolored with trace; Striate",
  },
];

export const aboutUsPageContent = [
  {
    title: "About Fresh Flowers Supply",
    content: `Welcome to Dutch Florist Supply, your trusted partner in floral
  excellence for over three decades. Nestled in the heart of Naaldwijk,
  the Netherlands, our journey in the wholesale floristry business began
  over 30 years ago with a passion for delivering the freshest and most
  exquisite blooms to our clients.`,
    image: Rectangle9,
  },
  {
    title: "Dutch Excellence, Global Reach",
    content: `Welcome to Dutch Florist Supply, your trusted partner in floral
  excellence for over three decades. Nestled in the heart of Naaldwijk,
  the Netherlands, our journey in the wholesale floristry business began
  over 30 years ago with a passion for delivering the freshest and most
  exquisite blooms to our clients.`,
    image: Rectangle10,
  },
  {
    title: "Unparalleled Floral Expertise",
    content: `At Dutch Florist Supply, we are more than just florists; we are floral enthusiasts dedicated to bringing the beauty of nature to your doorstep. Our team of seasoned floral experts meticulously curates a diverse selection of flowers, foliage, and botanical accessories, ensuring that each stem we offer is a masterpiece of nature's artistry.`,
    image: Rectangle11,
  },
  {
    title: "Sustainable Practices",
    content: `As stewards of the environment, we are committed to sustainable and eco-friendly practices. From responsible sourcing to eco-conscious packaging, we prioritize the well-being of our planet. We believe that every bloom should not only bring joy to those who receive it but also contribute to a healthier and more sustainable world.`,
    image: Rectangle12,
  },
];

export const clientsReviewsSample = [
  {
    reviewText:
      "Their fresh, vibrant blooms elevate our events to enchanting experiences. Consistency, reliability, and a touch of elegance define our partnership. Clients are mesmerized, and our reputation soars with each petal.",
    name: "Emma Greenfield",
    starsCount: 5,
    jobTitle: "CEO of Blooms & Beyond Events",
    image: Ellipse7,
  },
  {
    reviewText:
      "The diverse and impeccable quality of flowers transforms our floral designs. From classic arrangements to avant-garde displays, Petals Perfection never disappoints. A floral haven for discerning clients.",
    name: "Liam Blossom",
    starsCount: "",
    jobTitle: "Owner of Petals Perfection Florals",
    image: Ellipse1,
  },
  {
    reviewText:
      "Their dedication to excellence crafts an enduring partnership. Impeccable designs, timely deliveries, and a range that suits every occasion. A trusted ally in creating unforgettable moments.",
    name: "Olivia Meadows",
    starsCount: 5,
    jobTitle: "Founder of Elegant Petal Productions",
    image: Ellipse7,
  },
  {
    reviewText:
      "Each order is a canvas awaiting artistic expression. The exquisite variety sparks creativity, ensuring our designs stand out. A floral dreamland for those who seek uniqueness.",
    name: "Noah Petalton",
    starsCount: 5,
    jobTitle: "Lead designer at Enchanting Blossom Designs",
    image: Ellipse5,
  },
  {
    reviewText:
      "From opulent weddings to corporate galas, their blooms define luxury. Majestic Floral Creations curates masterpieces that elevate our brand. A partnership that epitomizes sophistication.",
    name: "Adam Bloomington",
    starsCount: 5,
    jobTitle: "CEO of Majestic Floral Creations",
    image: Ellipse1,
  },
];
