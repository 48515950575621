import React from "react";

const FacebookIcon = ({ style }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 22"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9282 12.711L11.5074 8.93807H7.88688V6.48968C7.88688 5.45768 8.39246 4.45109 10.0141 4.45109H11.6598V1.23912C11.6598 1.23912 10.1665 0.984375 8.73841 0.984375C5.75708 0.984375 3.8084 2.79168 3.8084 6.06294V8.93872H0.494141V12.7116H3.8084V21.8329H7.88688V12.7116L10.9282 12.711Z"
        fill="#292742"
      />
    </svg>
  );
};

export default FacebookIcon;
