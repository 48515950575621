import React from "react";
import { CustomButton, FlexDivWithBG, H1, H2 } from "../../../Styles/Global.Styled";
import rectangle7 from "../../../assets/flowersPictures/rectangle7.png";

const SignUpPromo = () => {
  return (
    <FlexDivWithBG
      $bgImage={rectangle7}
      $flexD="column"
      $justifyC="center"
      $align="center"
      $gap='2vh'
      $margin='10vh 0'
      $mMargin='5vh 0'
      $padding="5vh 20%"
      $mPadding="5vh 2.5%"
    >
      <H1 $color="white" $mFSize="7.5vw" $fSize="3vw">Become a Partner</H1>
      <H2 $fWeight="300" $color="white" $txtAlign="center" $fSize="1.95vw" $mFSize="5vw">
        Join us as a partner to access specialized tools for quote request
        tracking and order management. Enhance your experience and grow your
        business with our streamlined partnership program.
      </H2>
      <CustomButton
        $color="white"
        $padding="1% 5%"
        $fSize="2vw"
        $mFSize="5vw"
        $border="4px solid white"
        $bRadius='50px'
        $margin='2%'
      >
        Sign Up
      </CustomButton>
    </FlexDivWithBG>
  );
};

export default SignUpPromo;
