import React from "react";
import { FlexContainer, Line } from "../../../Styles/Global.Styled";
import Filter from "../../../Components/Filters/Filter";

const FilterSection = ({ fltr, setFilters }) => {
  return (
    <FlexContainer
      $flexD="column"
      $width="20%"
      $height="100%"
      $padding="1vh 2vw 1vh 0"
      // $border="4px solid blue"
      //   $gap="1.5vh"
      $mDisplay="none"
    >
      {fltr?.map((filterType) => (
        <>
          <Filter
            key={filterType.name}
            filterType={filterType}
            setFilters={setFilters}
          />
          <Line $bWidth="1px" $bColor="#353535" />
        </>
      ))}
    </FlexContainer>
  );
};

export default FilterSection;
