import React from "react";
import {
  FlexContainer,
  FlexImg,
  H3,
  Paragraph,
} from "../../../Styles/Global.Styled";

const AboutTheCompany = ({
  title = "",
  content = "",
  image = "",
  flexD = "row",
}) => {
  return (
    <FlexContainer
      // $height="50vh"
      $padding="5vh 7.5%"
      $mPadding="5vh 2vw"
      // $align="center"
      $justifyC="space-between"
      $flexD={flexD}
      $mFlexD='column'
      // $border='2px solid red'
    >
      <FlexContainer
        $width="60%"
        $mWidth="100%"
        $gap='4vh'
        $mGap='2vh'
        $padding='1vh 0'
        $flexD="column"
        // $border='2px solid red'
      >
        <H3 $fSize="4vh" $mFSize="2.75vh">{title}</H3>
        <Paragraph $fSize="3vh" $mFSize="2.25vh" $fWeight="400">
          {content}
        </Paragraph>
      </FlexContainer>
      <FlexImg $width="auto" $bRadius="12px" src={image} alt="image" />
    </FlexContainer>
  );
};

export default AboutTheCompany;
