import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { theme } from "./Styles/theme";
import { FiltersContextProvider } from "./appContext/FiltersContext";
import { ProductsContextProvider } from "./appContext/ProductsContext";
import { CartContextProvider } from "./appContext/CartContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FiltersContextProvider>
      <CartContextProvider>
        <ProductsContextProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ProductsContextProvider>
      </CartContextProvider>
    </FiltersContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
