import React, { useState } from "react";
import { FlexContainer, Form, MainButton } from "../../Styles/Global.Styled";
import TextInput from "../../Components/FormInputs/TextInput";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../../helpers/utilis";
import TextAreaInput from "../../Components/FormInputs/TextAreaInput";

const ContactForm = () => {
  const [formData, setForm] = useState({
    "First name": "",
    "Last name": "",
    Email: "",
    "Phone number": "",
    Message: "",
  });
  const [isValid, setIsValid] = useState({});
  return (
    <Form $gap="2vw" $align="center" $mGap='3vh'>
      <FlexContainer $mGap='3vh' $mFlexD='column'>
        <TextInput
          height="5vh"
          width="50%"
          mWidth="90vw"
          label="First name"
          value={formData["First name"]}
          setForm={setForm}
          setIsValid={setIsValid}
          isValid={isValidName}
          errors={isValid["First name"]}
        />
        <TextInput
          height="5vh"
          width="50%"
          mWidth="90vw"
          label="Last name"
          value={formData["Last name"]}
          setForm={setForm}
          setIsValid={setIsValid}
          isValid={isValidName}
          errors={isValid["Last name"]}
        />
      </FlexContainer>
      <FlexContainer $mGap='3vh' $mFlexD='column'>
        <TextInput
          height="5vh"
          width="70%"
          mWidth="90vw"
          label="Email"
          value={formData["Email"]}
          setForm={setForm}
          type="email"
          setIsValid={setIsValid}
          isValid={isValidEmail}
          errors={isValid["Email"]}
        />
        <TextInput
          height="5vh"
          width="50%"
          mWidth="90vw"
          label="Phone number"
          value={formData["Phone number"]}
          setForm={setForm}
          type="tel"
          setIsValid={setIsValid}
          isValid={isValidPhoneNumber}
          errors={isValid["Phone number"]}
        />
      </FlexContainer>
      <TextAreaInput
        height="20vh"
        width="100%"
        required
        label="Message"
        value={formData["Message"]}
        setForm={setForm}
      />
      <MainButton
        $width="40%"
        $height="5vh"
        $bRadius="50px"
        $fSize="2vh"
        $margin="1vh auto"
        type="submit"
      >
        Submit
      </MainButton>
    </Form>
  );
};

export default ContactForm;
