import React from "react";
import { AbsFlexContainer, FlexContainer } from "../../Styles/Global.Styled";
import styled from "styled-components";

const SliderInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  width: 98%;
  /* margin: 10px 0; */
  position: absolute;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: 0.5vh solid rgba(255, 255, 255, 1);
    width: 2vh;
    height: 2vh;
    background: ${({ thumbColor, theme }) => thumbColor || theme.main};
    border-radius: 50%;
    cursor: grabbing;
    position: relative;
    z-index: 2;
  }
`;

const RangeSlider = ({ min, max, minValue, maxValue, step, handleChange }) => {
  const minPos = ((minValue - 0) / (max - 0)) * 100;
  const maxPos = ((maxValue - 0) / (max - 0)) * 100;
  return (
    <FlexContainer $height="3vh" $width="100%">
      <SliderInput
        type="range"
        min={min}
        max={max}
        step={step}
        value={minValue}
        id="min"
        onChange={handleChange}
      />
      <SliderInput
        type="range"
        id="max"
        min={min}
        max={max}
        step={step}
        value={maxValue}
        onChange={handleChange}
      />
      {console.log("minPos", minPos)}
      {console.log("maxPos", maxPos)}
      <AbsFlexContainer
        $height="2vh"
        $bgColor="main"
        $width={`${maxPos - minPos - 3}%`}
        $left={`${minPos + 1}%`}
        $bRadius="12px"
        $top=".25vh"
        style={{ zIndex: "1" }}
      />
      <AbsFlexContainer
        $height="2vh"
        $bgColor="sliderShadow"
        $border="1px solid #008000"
        $width="99.5%"
        $bRadius="12px"
        $top=".25vh"
      />
    </FlexContainer>
  );
};

export default RangeSlider;
