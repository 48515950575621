import React from "react";
import {
  CompanyName,
  FlexContainer,
  H3,
  FlexImg,
  Paragraph,
  SecButton,
  H4,
} from "../../../Styles/Global.Styled";
import rectangle6 from "../../../assets/flowersPictures/rectangle6.png";
import { theme } from "../../../Styles/theme";
import { useNavigate } from "react-router-dom";

const CompanyPromo = () => {
  const navigate = useNavigate();
  return (
    <FlexContainer
      $justifyC="space-between"
      $mGap="3vh"
      $padding="10vh 7.5%"
      $mPadding="5vh 5%"
      $mFlexD="column"
    >
      <FlexContainer $width="55%" $mWidth="auto" $flexD="column" $gap="2vh">
        <H3 $fSize="36px" $mFSize="6.5vw">
          About Fresh Flowers Supply
        </H3>
        <H4 $txtAlign="left" $fSize="16px" $mFSize="4vw">
          Welcome to <CompanyName>Fresh Flowers Supply</CompanyName>, your
          trusted partner in floral excellence for over three decades.
        </H4>
        <Paragraph $fSize="18px" $mFSize="4vw">
          Nestled in the heart of Naaldwijk, the Netherlands, our journey in the
          wholesale floristry business began over 30 years ago. With a passion
          for delivering the freshest and most exquisite blooms to our clients,
          we have dedicated ourselves to providing unparalleled quality and
          service throughout our decades-long history.
        </Paragraph>
        <Paragraph $fSize="18px" $mFSize="4vw">
          Established in 1991, we take pride in our rich heritage as a
          family-owned wholesale florist company. From our humble beginnings, we
          have grown to become a cornerstone in the floral industry, supplying
          our exceptional products to clients spanning the entire Middle East.
          Our commitment to quality, reliability, and unmatched service has been
          the driving force behind our success.
        </Paragraph>
        <SecButton
          $width="20%"
          $mWidth="40%"
          $height="12.5%"
          $bRadius="25px"
          $margin="2% 0 0 0"
          $fSize="20px"
          onClick={() => navigate("/aboutus")}
        >
          Read more
        </SecButton>
      </FlexContainer>
      <FlexImg
        $width="30vw"
        $mWidth="80vw"
        $bRadius="12px"
        style={{alignSelf:'flex-end'}}
        $border={`2px solid ${theme.main}`}
        src={rectangle6}
      />
    </FlexContainer>
  );
};

export default CompanyPromo;
