import React from "react";
import { CustomButton, FlexContainer } from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";
import CheckmarkIcon from "../Icons/CheckmarkIcon";

const CheckboxInput2 = ({ value, isChecked = false, handleChange }) => {
  return (
    <CustomButton
      $color={`${theme.thirdColor}`}
      $fSize=".9vw"
      $mFSize="4vw"
      $height="1vw"
      $bRadius="6px"
      $fWeight="200"
      $gap=".25vw"
      $mGap="1.5vw"
      // $border='2px solid red'
      onClick={() => {
        console.log('clicked')
        handleChange(value, !isChecked);
      }}
    >
      <FlexContainer
        $border={`1px solid ${theme.thirdColor}`}
        $height="1vw"
        $mHeight="5vw"
        $width="1vw"
        $mWidth="5vw"
        $bRadius="6px"
        $align="center"
        $justifyC="center"
        $bgColor={isChecked ? "main" : null}
      >
        <CheckmarkIcon
          color="white"
          style={{ width: "75%", height: "75%" }}
        />
      </FlexContainer>
      {value}
    </CustomButton>
  );
};

export default CheckboxInput2;
