import React from "react";
import {
  FlexContainer,
  FlexH4,
  FlexImg,
  Paragraph,
  TxtSpan,
} from "../../../Styles/Global.Styled";
import PhoneIcon from "../../../Components/Icons/PhoneIcon";
import EnvelopeIcon from "../../../Components/Icons/EnvelopeIcon";
import { locations } from "../../../data";
import { IoBusiness } from "react-icons/io5";
import locationWorldMap from "../../../assets/locationWorldMap.jpg";

const ContactSection = () => {
  return (
    <FlexContainer
      $gap="2vw"
      $padding="10vh 15%"
      $mPadding="3vh 5vw"
      $justifyC="center"
      // $border="2px solid red"
    >
      <FlexContainer
        $justifyC="space-between"
        // $mHeight='50vh'
        $mGap="3vh"
        $flexD="column"
        // $border="2px solid red"
      >
        <FlexH4 $fSize="4vh" $mFSize="3.5vh" $gap=".5vw" $fWeight="600">
          Get in <TxtSpan $color="main">Touch</TxtSpan>
        </FlexH4>
        <Paragraph
          $fSize="2vh"
          $mFSize="1.5vh"
          style={{ margin: "-2vh 0 0 0", whiteSpace: "pre-line" }}
        >
          {`Have a question or inquiry? We'd love to hear from you! 

        Feel free to reach out to us using the contact details immediately.`}
        </Paragraph>
        <FlexContainer $align="center" $gap=".5vw">
          <PhoneIcon color="black" style={{ height: "3vh", width: "3vh" }} />
          <TxtSpan $color="main" $fWeight="600">
            +31 6 8951 28 19
          </TxtSpan>
        </FlexContainer>
        <FlexContainer $align="center" $gap=".5vw">
          <EnvelopeIcon color="black" style={{ height: "3vh", width: "3vh" }} />
          <TxtSpan $color="main" $fSize="2vh" $fWeight="600">
            cs@freshflowersupply.com
          </TxtSpan>
        </FlexContainer>
        <FlexContainer $gap="1vw" $mGap="2vh" $mFlexD="column">
          {locations.map((loc) => (
            <FlexContainer $mGap="1vw">
              <IoBusiness style={{ height: "3vh", width: "3vh" }} />
              <FlexContainer $flexD="column" $margin="0 0 0 .5vw">
                <TxtSpan $color="main" $fSize="2vh" $fWeight="600">
                  {loc.type}
                </TxtSpan>
                <TxtSpan
                  $color="secColor"
                  $fSize="1.5vh"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {loc.address}
                </TxtSpan>
              </FlexContainer>
            </FlexContainer>
          ))}
        </FlexContainer>
      </FlexContainer>
      <FlexImg
        $mDisplay="none"
        src={locationWorldMap}
        $width="15vw"
        $border="1px solid green"
        $bRadius="12px"
      />
    </FlexContainer>
  );
};

export default ContactSection;
