import React, { useContext } from "react";
import {
  AbsFlexContainer,
  CustomButton,
  FlexContainer,
  FlexImg,
  InputSearch,
  Line,
  LogoImage,
} from "../../Styles/Global.Styled";
import freshFlowerLogo from "../../assets/fresh_flower_logo_1.png";
import buyCartIcon from "../../assets/IconPictures/BuyCart.png";
import { useNavigate } from "react-router-dom";
import { appPages } from "../../data";
import { theme } from "../../Styles/theme";
import { CartContext } from "../../appContext/CartContext";
import { FaMagnifyingGlass } from "react-icons/fa6";

const NavBar = ({ setOpen }) => {
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);

  return (
    <FlexContainer
      $height="10vh"
      $align="center"
      $padding="0 7.5%"
      $mPadding="0 2.5%"
      $bShadow={`0px -2px 2px ${theme.shadowColor} inset`}
      $justifyC="space-between"
      // $border='2px solid red'
    >
      <LogoImage
        src={freshFlowerLogo}
        alt="Logo"
        onClick={() => navigate("/home")}
      />
      <FlexContainer $gap="1vw" $mGap="3vw" $height="50%" $align="center">
        <FlexContainer $justifyC="flex-end" $width="50vw" $mDisplay="none">
          {appPages.map((page) => (
            <CustomButton
              key={page.name}
              onClick={() => navigate(`/${page.name}`)}
              // $fSize="24px"
              $fSize="1.25vw"
              $padding="0 1.5vw"
              // $border="2px solid red"
            >
              {page.uiName}
            </CustomButton>
          ))}
        </FlexContainer>
        {/* <FlexContainer $justifyC="space-between" $width="15vw" $mDisplay="none">
          <MainButton $width="47.5%" $bRadius="6px" $fSize="22px">
            Login
          </MainButton>
          <SecButton $width="47.5%" $bRadius="6px" $fSize="22px">
            Sign Up
          </SecButton>
        </FlexContainer> */}
        <FlexContainer
          $border={`1px solid ${theme.main}`}
          $align="center"
          $bRadius="50px"
          $padding="0 .5vw"
          $gap=".5vw"
          $height="5vh"
          $color={`${theme.secColor}`}
          $width="10vw"
          $justifyC="center"
          // $bShadow={`0px 0px 6px 0px ${theme.shadowColor}`}
          // $border='2px solid red'
          $fSize="1.25vw"
          $mDisplay="none"
        >
          <InputSearch
            placeholder="Search"
            $padding="1%"
            // $border="4px solid red"
            $width="7vw"
          />
          <FaMagnifyingGlass
            color={`${theme.main}`}
            style={{ height: "3vh", width: "3vh" }}
          />
        </FlexContainer>
        <FlexContainer $display="none" $mDisplay="flex">
          <FaMagnifyingGlass
            color={`${theme.main}`}
            style={{ height: "3vh", width: "3vh" }}
          />
        </FlexContainer>
        <CustomButton
          $width="5vh"
          $height="5vh"
          $bRadius="12px"
          // $bShadow={`0px 0 2px ${theme.shadowColor} inset`}
          onClick={() => navigate("/cart")}
          // $border='2px solid red'
        >
          <FlexImg alt="Shopping-cart" src={buyCartIcon} $width="4vh" />
          {cart.length > 0 && (
            <AbsFlexContainer
              $fSize=".7vw"
              $mFSize="3.25vw"
              $width="1vw"
              $mWidth="4vw"
              $height="1vw"
              $mHeight="4vw"
              $top="0"
              $right="0"
              $bRadius="50%"
              $bgColor="main"
              $color="white"
            >
              {cart.length}
            </AbsFlexContainer>
          )}
        </CustomButton>
        <CustomButton
          $display="none"
          $bRadius="12px"
          $width="3vh"
          $height="4vh"
          // $bShadow={`0px 0 2px ${theme.shadowColor} inset`}
          onClick={() => setOpen(true)}
          $padding="0 1vw"
          $justifyC="space-evenly"
          $flexD="column"
        >
          {/* <SandwichIcon style={{ height: "3vh", width: "3vh" }} /> */}
          <Line $bWidth="3px" />
          <Line $bWidth="3px" />
          <Line $bWidth="3px" />
        </CustomButton>
      </FlexContainer>
    </FlexContainer>
  );
};

export default NavBar;
