import React from "react";
import { FlexContainer } from "../../Styles/Global.Styled";
import NavBar from "../NavBar/NavBar";
import { Footer } from "../Footer/OriginFooter";
import LandingSection from "./SubSections/LandingSection";
import AboutTheCompany from "./SubSections/AboutTheCompany";
import { aboutUsPageContent } from "../../data";
import OurHistorySection from "./SubSections/OurHistorySection";
import PromoSection from "./SubSections/PromoSection";
import TestimonialsSection from "./SubSections/TestimonialsSection";
import MobileNavBar from "../NavBar/MobileNavBar";

const AboutUs = ({ menuOpen, setOpen }) => {
  return (
    <>
      {menuOpen ? <MobileNavBar setOpen={setOpen} /> : null}
      <FlexContainer $flexD="column">
        <NavBar setOpen={setOpen} />
        <LandingSection />
        <AboutTheCompany
          title={aboutUsPageContent[0].title}
          content={aboutUsPageContent[0].content}
          image={aboutUsPageContent[0].image}
        />
        <AboutTheCompany
          title={aboutUsPageContent[1].title}
          content={aboutUsPageContent[1].content}
          image={aboutUsPageContent[1].image}
          flexD="row-reverse"
        />
        <OurHistorySection />
        <AboutTheCompany
          title={aboutUsPageContent[2].title}
          content={aboutUsPageContent[2].content}
          image={aboutUsPageContent[2].image}
        />
        <AboutTheCompany
          title={aboutUsPageContent[3].title}
          content={aboutUsPageContent[3].content}
          image={aboutUsPageContent[3].image}
          flexD="row-reverse"
        />
        <TestimonialsSection />
        <PromoSection />
        <Footer />
      </FlexContainer>
    </>
  );
};

export default AboutUs;
