import React from "react";
import {
  FlexDivWithBG,
  H3,
  H4,
  Paragraph,
} from "../../../Styles/Global.Styled";
import Rectangle13 from "../../../assets/flowersPictures/Rectangle13.png";

const OurHistorySection = () => {
  return (
    <FlexDivWithBG
      // $height="40vh"
      $padding='5vh 25%'
      $mPadding='5vh 4vw'
      $gap='2vh'
      $flexD="column"
      $bgImage={Rectangle13}
      $align="center"
    >
      <H4 $fSize="2vh" $color="main">
        A little story about
      </H4>
      <H3 $fSize="4vh">Our History</H3>
      <Paragraph $txtAlign='center' $fSize="3vh" $mFSize="2.25vh">
        Established in 1991, we take pride in our rich heritage as a
        family-owned wholesale florist company. From our humble beginnings, we
        have grown to become a cornerstone in the floral industry, supplying our
        exceptional products to clients spanning the entire Middle East. Our
        commitment to quality, reliability, and unmatched service has been the
        driving force behind our success.
      </Paragraph>
    </FlexDivWithBG>
  );
};

export default OurHistorySection;
