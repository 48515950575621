import React from "react";
import { FlexContainer, FlexImg, H4, Label, TxtSpan } from "../../Styles/Global.Styled";
import DescrShortProduct from "./DescrShortProduct";
import { findMatchRGB } from "../../helpers/utilis";

const ProductReview = ({ product }) => {
  const matchingObject = findMatchRGB(product["RHS main color flower"]);
  return (
    <FlexContainer $width='27vw' $mWidth='90vw'>
      <FlexImg $width="12.5vh" $height="12.5vh" src={product["Product image"]} />
      <FlexContainer $flexD="column" $gap='.5vh' $flexG='1' $justifyC='space-between'>
        <H4 $fSize='2vh' $mFSize='1.5vh' $txtAlign='start'>{product["Product name"]}</H4>
        <FlexContainer $gap='.25vw' >
          <Label $fSize='1.5vh'>Quantity:</Label>
          <TxtSpan $fSize='1.5vh'>10 items</TxtSpan>
        </FlexContainer> 
        <FlexContainer $height="auto" $width="100%" $justifyC="space-evenly">
          <DescrShortProduct label="Code" value={product["Product code"]} />
          <DescrShortProduct
            label="Color"
            value={product["RHS main color flower"]}
            matchingObject={matchingObject}
          />
          <DescrShortProduct label="Height" value={product["Flower height"] || product["Plant height"]} />
          <DescrShortProduct label="Type" value={product["Application"]} />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProductReview;
  