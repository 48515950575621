import React from "react";

const TwitterIcon = ({ style }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 19"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5889 2.87078C20.8101 3.20672 19.9627 3.44849 19.0897 3.54265C19.996 3.00423 20.6747 2.1536 20.9985 1.15037C20.148 1.65629 19.2163 2.01104 18.2448 2.1989C17.8387 1.7648 17.3476 1.41897 16.802 1.18297C16.2565 0.946964 15.6682 0.825846 15.0737 0.827159C12.6687 0.827159 10.7346 2.77661 10.7346 5.1689C10.7346 5.50483 10.7753 5.84077 10.8414 6.16398C7.24029 5.97566 4.02853 4.25525 1.89329 1.62119C1.50423 2.28573 1.30034 3.04238 1.30285 3.81242C1.30285 5.31905 2.06889 6.64753 3.23704 7.42884C2.54863 7.40173 1.87635 7.21251 1.27486 6.87658V6.93002C1.27486 9.03981 2.76622 10.7882 4.75385 11.1903C4.38065 11.2873 3.99673 11.3369 3.61115 11.3379C3.32866 11.3379 3.06143 11.3099 2.79167 11.2718C3.34138 12.9922 4.94218 14.2418 6.84837 14.2825C5.35701 15.4506 3.48899 16.1378 1.46064 16.1378C1.09671 16.1378 0.760772 16.125 0.412109 16.0843C2.33612 17.3186 4.61896 18.0312 7.07741 18.0312C15.0585 18.0312 19.4257 11.4194 19.4257 5.68044C19.4257 5.49211 19.4257 5.30378 19.4129 5.11545C20.2579 4.49702 20.9985 3.73098 21.5889 2.87078Z"
        fill="#292742"
      />
    </svg>
  );
};

export default TwitterIcon;
