import React, { useContext } from "react";
import {
  AbsButton,
  AbsFlexContainer,
  FlexContainer,
  Line,
  TxtSpan,
} from "../../../Styles/Global.Styled";
import Filter from "../../../Components/Filters/Filter";
import { FiltersContext } from "../../../appContext/FiltersContext";
import { sortByOptions } from "../../../data";
import OptionsInput from "../../../Components/FormInputs/OptionsInput";

const MobileFilterBar = ({ setOpen }) => {
  const { fltr, setFilters } = useContext(FiltersContext);
  return (
    <AbsFlexContainer
      // $border="2px solid red"
      $display="none"
      $mDisplay="flex"
      $width="100vw"
      $bgColor="white"
      $padding="5vh 1vw 10vh 1vw"
      $flexD="column"
      $justifyC="flex-start"
      $align="flex-start"
      style={{ zIndex: "3", minHeight:'100vh' }}
    >
      <AbsButton
        // $border="4px solid red"
        $width="1vh"
        $height="1vh"
        $padding="2vh"
        // $margin="2vh 2vh 0 0"
        $right="5vw"
        $top="3vw"
        $fSize="5vw"
        $bRadius="50%"
        $bgColor="main"
        $color="white"
        $fWeight="600"
        onClick={() => setOpen(false)}
      >
        X
      </AbsButton>
      <FlexContainer $flexD="column" $width="100%">
        <FlexContainer $flexD="column" $mGap="1vh">
          <FlexContainer>
            <TxtSpan
              $fSize="6vw"
              $color="secColor"
              style={{ whiteSpace: "nowrap" }}
            >
              Sort By
            </TxtSpan>
            <Line
              $bWidth="1px"
              $margin="0 0 1.5vh 0"
              $bColor="#353535"
              style={{ alignSelf: "flex-end" }}
            />
          </FlexContainer>
          <OptionsInput options={sortByOptions} selected={"Relevance"} />
        </FlexContainer>
        <FlexContainer $flexD="column" $margin="4vh 0 0 0">
          <FlexContainer>
            <TxtSpan $fSize="6vw" $color="secColor">
              Filters
            </TxtSpan>
            <Line
              $bWidth="1px"
              $margin="0 0 1.5vh 0"
              $bColor="#353535"
              style={{ alignSelf: "flex-end" }}
            />
          </FlexContainer>
          {fltr?.map((filterType) => (
            <FlexContainer $flexD="column" $width="100%" $padding="0 2vw">
              <Filter
                key={filterType.name}
                filterType={filterType}
                setFilters={setFilters}
              />
              <Line $bWidth="1px" $bColor="#353535" />
            </FlexContainer>
          ))}
        </FlexContainer>
      </FlexContainer>
      <AbsButton
        $mFSize="5vw"
        $width="90vw"
        $padding="1vh 0"
        $bRadius="50px"
        $bgColor="main"
        $color="white"
        $top='96vh'
        $left='50%'
        style={{transform:'translate(-50%, -50%)',position:'fixed'}}
      >
        Apply
      </AbsButton>
    </AbsFlexContainer>
  );
};

export default MobileFilterBar;
