import styled, { css, keyframes } from "styled-components";
import { buttonColorSchemes, fontSizes, sizes } from "./theme";

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyC }) => $justifyC || "flex-start"};
  box-sizing: border-box;
  width: ${({ $width }) => $width || "100%"};
  height: ${({ $height }) => $height || "100%"};
  gap: ${({ $gap }) => $gap || "0"};
  @media (max-width: ${sizes.tablet}) {
    width: ${({ $mwidth }) => $mwidth || null};
    height: ${({ $mheight }) => $mheight || null};
  }

  color: ${({ theme, $color }) => ($color ? theme[$color] : $color)};
  background-color: ${({ theme, $bgcolor }) =>
    $bgcolor ? theme[$bgcolor] : $bgcolor};
  font-size: ${({ $fontSize }) => ($fontSize ? fontSizes[$fontSize] : null)};
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-family: ${({ theme, $font }) => ($font ? theme[$font] : theme.font)};
  cursor: pointer;
  width: ${({ $width }) => ($width ? $width : "auto")};
  height: ${({ $height }) => ($height ? $height : "auto")};
  font-size: ${({ $fontSize }) => ($fontSize ? fontSizes[$fontSize] : null)};
  ${({ $genre }) => ($genre ? buttonColorSchemes[$genre] : null)};
  /* background-color: ${({ theme, $bgcolor }) =>
    $bgcolor ? theme[$bgcolor] : $bgcolor}; */
  ${({ $bgImage }) =>
    $bgImage
      ? css`
          background-image: ${({ $bgImage }) =>
            $bgImage ? `url(${$bgImage})` : "none"};
          background-size: cover;
          background-position: center center;
        `
      : null}
  ${({ $hidden }) =>
    $hidden
      ? css`
          display: none;
        `
      : null};
  @media (max-width: ${sizes.tablet}) {
    width: ${({ $mwidth }) => ($mwidth ? $mwidth : null)};
    height: ${({ $mheight }) => ($mheight ? $mheight : null)};
  }
  &:disabled {
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: default;
  }
  svg:first-child {
    margin: 0 2.5%;
    height: ${({ $iconheight }) => ($iconheight ? $iconheight : "75%")};
    width: ${({ $iconWidth }) => ($iconWidth ? $iconWidth : "auto")};
    align-self: center;
  }
  ${({ $isAnimated }) =>
    $isAnimated
      ? css`
          &:hover {
            background-color: ${({ theme }) => theme.color3};
            color: ${({ theme }) => theme.color1};
            font-weight: bold;
          }
          &:active {
            /* box-shadow: 4px 4px 4px grey inset, -4px -4px 4px grey inset; */
            color: ${({ theme }) => theme.color1};
            font-weight: bold;
          }
        `
      : null}
`;
export const Button2 = styled(Button)`
  color: black;
  text-decoration: none;
  position: absolute;
  top: ${({ $top }) => ($top ? $top : 0)};
  left: ${({ $left }) => ($left ? $left : 0)};
`;

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.secFont};
  font-weight: 900;
  width: ${({ $width }) => ($width ? $width : "auto")};
  height: ${({ $height }) => ($height ? $height : "auto")};
  color: ${({ $color, theme }) => ($color ? theme[$color] : $color)};
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.xLarge};
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.secFont};
  font-weight: 900;
  color: ${({ $color, theme }) => ($color ? theme[$color] : $color)};
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.xLarge};
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const Text = styled.span`
display:flex;
align-items:center;
justify-content:center;
  font-family: ${({ theme, $font }) => ($font ? theme[$font] : theme.fontMain)};
  color: ${({ $color, theme }) => ($color ? theme[$color] : $color)};
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.medium};
  white-space: ${({ $nowrap }) => ($nowrap === true ? "nowrap" : $nowrap)};
  text-align: center;
  width: ${({ $width }) => ($width ? $width : "auto")};
  height: ${({ $height }) => ($height ? $height : "auto")};
`;

export const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const BulletPoint = styled.li`
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  font-family: ${({ theme }) => theme.main};
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.large};
  &::before {
    content: "•";
    color: ${({ theme }) => theme.color1}; /* Change the color as needed */
    font-size: ${({ $fontSize }) =>
      $fontSize ? fontSizes[$fontSize] : fontSizes.large};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
`;

export const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1%;
  width: ${({ $width }) => ($width ? $width : "100%")};
  height: ${({ $height }) => ($height ? $height : "100%")};
  @media (max-width: ${sizes.tablet}) {
    width: ${({ $mwidth }) => ($mwidth ? $mwidth : null)};
    height: ${({ $mheight }) => ($mheight ? $mheight : null)};
  }
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color6};
  border: 2px solid ${({ theme }) => theme.color1};
  box-shadow: 2px 2px 3px grey, -2px -2px 3px grey;
`;

export const InputField = styled.input`
  width: ${({ $width }) => ($width ? $width : "100%")};
  height: ${({ $height }) => ($height ? $height : "100%")};
  box-sizing: border-box;
  padding: 0 2%;
  border: none;
  border: 1px solid ${({ theme }) => theme.color1};
  border-radius: 6px;
  outline: none;
  transition: border 0.3s;
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.medium};

  &:focus {
    border: 1px solid blue;
  }
`;

export const Label = styled.label`
  position: absolute;
  /* top: ${({ $isFloat, $top }) =>
    $isFloat && $top ? $top : $isFloat ? "-20%" : "30%"}; */
  top: ${({ $isFloat }) => ($isFloat ? "-12px" : null)};
  left: 2%;
  font-size: ${({ $isFloat }) =>
    $isFloat ? fontSizes.small : fontSizes.medium};
  color: ${({ $isFloat }) => ($isFloat ? "black" : "#aaa")};
  transition: 0.3s;
  z-index: 1;
  background-color: white;
  border-radius: 12px;
  padding: ${({ $isFloat }) => ($isFloat ? "0% 2%" : "0%")};
  display: ${({ $isFloat, $isHidden }) =>
    $isHidden && !$isFloat ? "none" : null};
  font-family: ${({ theme }) => theme.font};
`;

export const IsRequiredStar = styled.span`
  z-index: 2;
  color: red;
  font-weight: bold;
  &::before {
    content: "* ";
  }
`;

export const TextArea = styled.textarea`
  width: ${({ $width }) => ($width ? $width : "100%")};
  height: ${({ $height }) => ($height ? $height : "100%")};
  font-size: ${({ $fontSize }) =>
    $fontSize ? fontSizes[$fontSize] : fontSizes.medium};
  padding: 3% 0 4% 1%;
  line-height: 1.5;
  white-space: pre-line;
  box-sizing: border-box;
  outline: none;
  resize: none;
  transition: border 0.3s;
  border: 1px solid ${({ theme }) => theme.color1};
  border-radius: 6px;
  font-family: ${({ theme }) => theme.font};
  &:focus {
    border: 1px solid blue;
  }
`;

export const CharCount = styled.span`
  color: ${({ $isoverLimit }) => ($isoverLimit ? "red" : "black")};
  position: absolute;
  font-size: ${fontSizes.small};
  padding: 0.5% 1%;
  top: ${({ $top }) => ($top ? $top : 0)};
  left: ${({ $left }) => ($left ? $left : 0)};
  background-color: white;
  border-radius: 12px;
  font-family: ${({ theme }) => theme.font};
`;

export const DropDownList = styled(FlexColumn)`
  border: 2px solid ${({ theme }) => theme.color3};
  position: absolute;
  top: ${({ $top }) => ($top ? $top : 0)};
  left: ${({ $left }) => ($left ? $left : 0)};
  z-index: 3;
  border-radius: 0 6px 6px 6px;
`;

export const DropDownListButton = styled(Button)`
  z-index: 4;
  padding: 2%;
  justify-content: flex-start;
  border-radius: 0;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.color3};
    color: ${({ theme }) => theme.color1};
    font-weight: bold;
  }
  &:active {
    box-shadow: 2px 2px 2px grey inset, -2px -2px 2px grey inset;
    color: ${({ theme }) => theme.color1};
    font-weight: bold;
  }
`;

export const ItemAfter = styled.div`
  position: absolute;
  content: " ";
  width: 15%;
  height: 10%;
  top: calc(-10% - 2px);
  left: 35%;
  z-index: 0;
  border-bottom-left-radius: 50%;
  box-shadow: -5.5px 4px 0 ${({ theme }) => theme.color3};
`;

export const ItemBefore = styled.div`
  position: absolute;
  content: " ";
  /* top: 9.75%; */
  width: 50%;
  height: 50%;
  top: 50%;
  left: -50%;
  z-index: 0;
  border-bottom-right-radius: 50%;
  box-shadow: 3px 3px 0 ${({ theme }) => theme.color3};
`;

const spinnerKeyFrames = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
export const Spinner = styled.div`
  border-radius: 50%;
  height: 75%;
  aspect-ratio: 1/1;
  border: 6px solid ${(props) => props.theme.primary.main};
  animation: ${spinnerKeyFrames} 1.2s linear infinite;
  border: 5px solid ${(props) => props.theme.color1};
  border-bottom-color: ${(props) => props.theme.color3};
  display: inline-block;
  box-sizing: border-box;
`;

export const Line = styled.div`
  border: 2px solid;
  border-color: ${({ $color, theme }) => ($color ? theme[$color] : $color)};
  width: ${({ $width }) => ($width ? $width : "100%")};
`;

// const FlexColumn2 = styled.div`
// padding: 2% 0;
// background-image: url(${OpacityflowersRetails});
// background-size: 100% 100%;
// position:absolute;
// width:58.75%;
// height:100%;
// left:0;
// clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%);
// /* border-right:2px solid red; */
// `;
// const FlexColumn3 = styled.div`
//   padding: 2% 0;
//   background-image: url(${OpacityflowerWarehouse});
//   background-size: 100% 100%;
//   position:absolute;
//   width:58.75%;
//   height:100%;
//   right:0;
//   clip-path: polygon(100% 0%, 30% 0%, 0% 100%, 100% 100%);
// `;

/* ${({ $bgImage }) =>
    $bgImage
      ? css`
          ::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url(${$bgImage});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0.5;
          }
        `
      : null}; */
