import React from "react";
import { FlexContainer, H1 } from "../../Styles/Global.Styled";
import NavBar from "../NavBar/NavBar";
import { Footer } from "../Footer/OriginFooter";
import CartSection from "./SubSections/CartSection";
import QouteForm from "../../Forms/QouteForm/QouteForm";

const CartPage = () => {

  return (
    <FlexContainer $flexD="column">
      <NavBar />
      <FlexContainer $padding="5vh 0vw" $mPadding="3vh 5vw" $align="center" $flexD="column" $gap="3vh">
        <H1 $fSize="3vh" $txtAlign="center" $fWeight="400">
          Request a Qoute
        </H1>
        <FlexContainer $gap="2vh" $mGap="5vh" $mFlexD="column-reverse">
          <QouteForm />
          <CartSection />
        </FlexContainer>
      </FlexContainer>
      <Footer />
    </FlexContainer>
  );
};

export default CartPage;
