import React from "react";
import { FlexContainer, H1, H2 } from "../../Styles/Global.Styled";
import NavBar from "../NavBar/NavBar";
import ShopSections from "./SubSections/ShopSections";
import Footer from "../Footer/Footer";
import MobileNavBar from "../NavBar/MobileNavBar";
import MobileFilterBar from "./SubSections/MobileFilterBar";

const ShopPage = ({setOpen, setFltrBarOpen, menuOpen, fltrOpen}) => {
  // const [menuOpen, setOpen] = useState(false);
  // const [fltrOpen, setFltrBarOpen] = useState(false);

  // useEffect(() => {
  //   if (menuOpen || fltrOpen) {
  //     // Prevent scrolling and hide overflow when a popup is open
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     // Allow scrolling and show overflow when both popups are closed
  //     document.body.style.overflow = "visible";
  //   }
  // }, [menuOpen, fltrOpen]); // This effect depends on menuOpen and fltrOpen states

console.log('menuOpen',menuOpen)
console.log('fltrOpen',fltrOpen)
  return (
    <>
    {menuOpen ? <MobileNavBar setOpen={setOpen} /> : null}
      {fltrOpen ? <MobileFilterBar setOpen={setFltrBarOpen} /> : null}
      <FlexContainer $flexD="column" $mDisplay={fltrOpen?'none':null} >
        <NavBar setOpen={setOpen}/>
        <FlexContainer
          $flexD="column"
          $padding="5vh 0"
          $align="center"
          $justifyC="center"
          $bgColor="bgColor"
        >
          <H1 $fSize="3vw" $mFSize="7.5vw">
            Shop
          </H1>
          <H2 $fWeight="200" $fSize="1.25vw" $mFSize="4vw">
            Wholesale Blooms, Business Blooms
          </H2>
        </FlexContainer>
        <ShopSections setOpen={setFltrBarOpen}/>
        <Footer />
      </FlexContainer>
    </>
  );
};

export default ShopPage;
