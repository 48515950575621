import React, { useContext, useEffect } from "react";
import { FlexContainer } from "../../../Styles/Global.Styled";
import ShopHeader from "./ShopHeader";
import { productsSample, shopFilters } from "../../../data";
import ShopCarousel from "../../../Components/Carousel/ShopCarousel"; 
import { filterProductsByValues } from "../../../helpers/utilis";
import { FiltersContext } from "../../../appContext/FiltersContext";
import { ProductsContext } from "../../../appContext/ProductsContext";
import ProductAd from "../../../Components/Product/ProductAd";
import FilterSection from "./FilterSection";

const ShopSections = ({setOpen}) => {
  const { fltr, setFilters } = useContext(FiltersContext);
  const { products, setProducts } = useContext(ProductsContext);

  useEffect(() => {
    console.log("ShopSections useEffect");
    if (shopFilters.length > 0) {
      return setProducts(() => filterProductsByValues(productsSample, fltr));
    }
  }, [fltr, setProducts]);

  return (
    <FlexContainer
      // $height="auto"
      $flexD="column"
      $padding="0 7.5%"
      $margin="7.5vh 0"
      $mMargin="2.5vh 0"
      // $border="4px solid red"
      // $border='2px solid red'
    >
      <ShopHeader setOpen={setOpen}/>
      <FlexContainer $flexG="1" $mJustifyC='center'>
        <FilterSection setFilters={setFilters} fltr={fltr}/>
        <ShopCarousel items={products} Component={ProductAd} />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ShopSections;
