import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ShopPage from "./Pages/Shop/ShopPage";
import ProductPage from "./Pages/Product/ProductPage";
import Home from "./Pages/Home/Home";
import CartPage from "./Pages/CartPage/CartPage";
import ContactUsPage from "./Pages/ContactUs/ContactUsPage";
import ScrollToTop from "./helpers/ScrollToTop";
import { useState } from "react";
import { createGlobalStyle } from "styled-components";

// Global style to disable scrolling when a popup is open
const GlobalStyles = createGlobalStyle`
  body {
    overflow: ${({ $menuOpen, $fltrOpen }) =>
      $menuOpen || $fltrOpen ? "hidden" : "auto"};
  }
`;

function App() {
  const [mobileNavBar, setNavBarOpen] = useState(false);
  const [fltrBarOpen, setFltrBarOpen] = useState(false);
  return (
    <Router>
      <GlobalStyles $menuOpen={mobileNavBar} />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<Home menuOpen={mobileNavBar} setOpen={setNavBarOpen} />}
        />
        <Route
          path="/home"
          element={<Home menuOpen={mobileNavBar} setOpen={setNavBarOpen} />}
        />
        <Route
          path="/shop"
          element={
            <ShopPage
              menuOpen={mobileNavBar}
              fltrOpen={fltrBarOpen}
              setOpen={setNavBarOpen}
              setFltrBarOpen={setFltrBarOpen}
            />
          }
        />
        <Route path="/aboutus" element={<AboutUs menuOpen={mobileNavBar} setOpen={setNavBarOpen}/>} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/product/:productCode" element={<ProductPage />} />
      </Routes>
    </Router>
  );
}

export default App;
