import React, { useEffect, useRef } from "react";
import { ScrollContainer } from "../../Styles/Global.Styled";

const InvisibleCarousel = ({ items = [], Component = "" }) => {
  const parentRef = useRef(null);

  useEffect(() => {
    if (parentRef.current) {
      // Calculate the total width of all child elements
      const totalWidth = parentRef.current.scrollWidth;

      // Calculate the initial scroll position to center the items
      const centerScroll = (totalWidth - parentRef.current.clientWidth) / 2;

      // Set the initial scroll position
      parentRef.current.scrollLeft = centerScroll;
    }
  }, [items]);

  return (
    <ScrollContainer
      ref={parentRef}
      $width="100vw"
      style={{ overflowX: "auto" }}
      $padding="5vh 30vw"
      $align="center"
      $gap="5vw"
      // $border="4px solid green"
    >
      {items.map((item, index) => (
        <Component key={index} item={item} />
      ))}
    </ScrollContainer>
  );
};

export default InvisibleCarousel;
