import React, { useContext } from "react";
import {
  CustomButton,
  FlexContainer,
  FlexDivWithBG,
  H1,
  H2,
  MainButton,
  SecButton,
} from "../../../Styles/Global.Styled";
// import { FaMagnifyingGlass } from "react-icons/fa6";
import { saleCategoriesContent } from "../../../data";
import rectangleMain from "../../../assets/rectangleMain.png";
import { theme } from "../../../Styles/theme";
import { FiltersContext } from "../../../appContext/FiltersContext";
import { useNavigate } from "react-router-dom";
import { resetFilters } from "../../../helpers/utilis";

const SectionOne = () => {
  const navigate = useNavigate();
  const { setFilters } = useContext(FiltersContext);

  const handleClick = (e) => {
    setFilters((prevFilters) => {
      console.log("prev Filter", prevFilters);
      const index = prevFilters.findIndex((item) => item.name === "Categories");

      // Use the resetFilters function to update the filters
      const updatedFilters = resetFilters([...prevFilters]);

      if (index !== -1) {
        const selectedValues = [...updatedFilters[index].selectedValues];
        selectedValues.push(e.target.id);
        updatedFilters[index] = {
          ...updatedFilters[index],
          selectedValues: selectedValues,
        };
      }

      return updatedFilters;
    });
    return navigate("/shop");
  };

  return (
    <FlexContainer
      $height="80vh"
      $mHeight="90vh"
      $padding="3vh 7.5%"
      $mPadding="3vh 2.5%"
      $bgColor="bgColor"
      $justifyC="space-between"
      $bShadow={`0px -2px 2px ${theme.shadowColor} inset`}
      $mFlexD="column-reverse"
      // $border='2px solid red'
    >
      <FlexContainer
        $flexD="column"
        $width="30%"
        $mWidth="100%"
        $gap="2vh"
        $mGap="1vh"
        $mHeight="55vh"
        $padding="1vh"
        $mPadding="2vh 1vh 0 1vh"
        // $border="2px solid red"
      >
        {saleCategoriesContent.map((saleCat, index) => (
          <CustomButton
            key={index}
            $color="white"
            $flexD="column"
            $height="25%"
            $mHeight="12.5vh"
            $bRadius="12px"
            $fSize="38px"
            $mFSize="3vh"
            $bgImage={saleCat.buttonImage}
            id={saleCat.id}
            onClick={handleClick}
          >
            {saleCat.name}
            <FlexContainer
              $padding="1% 4%"
              $margin="1% 0 0 0"
              $fSize="18px"
              $mFSize="2vh"
              $bRadius="25px"
              $border="3px solid white"
            >
              Shop now
            </FlexContainer>
          </CustomButton>
        ))}
      </FlexContainer>
      <FlexDivWithBG
        $flexD="column"
        $align="center"
        $justifyC="center"
        $width="70%"
        $mWidth="100%"
        $mHeight="35vh"
        $bRadius="12px"
        $gap="2vh"
        $mGap="1vh"
        $bgImage={rectangleMain}
        $bShadow={`0px 0px 4px ${theme.shadowColor}`}
        // $border='2px solid red'
      >
        <H2 $fSize="1.25vw" $mFSize="2.25vw">
          Wholesale Blooms, Business Booms
        </H2>
        <H1 $fSize="2.5vw" $mFSize="4.5vw">
          Fresh Flowers Supply
        </H1>
        {/* <FlexContainer
          $width="35%"
          $height="5vh"
          $align="center"
          $border="2px solid #008000"
          $padding="0 1%"
          $margin="1% 0"
          $bRadius="6px"
        >
          <InputSearch placeholder="What are you looking for?" />
          <FaMagnifyingGlass
            color="#008000"
            style={{ height: "50%", width: "10%" }}
          />
        </FlexContainer> */}
        <FlexContainer
          $width="35%"
          $mWidth="60%"
          $height="5vh"
          $justifyC="space-between"
        >
          <MainButton
            $width="47%"
            $bRadius="25px"
            $fSize="20px"
            $mFSize="3.5vw"
            onClick={() => navigate("/shop")}
          >
            Shop now
          </MainButton>
          <SecButton $width="47%" $bRadius="25px" $fSize="20px" $mFSize="3.5vw">
            Get a Qoute
          </SecButton>
        </FlexContainer>
      </FlexDivWithBG>
    </FlexContainer>
  );
};

export default SectionOne;
