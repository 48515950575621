import React, { useState } from "react";
import {
  Arrow,
  CustomButton,
  FlexContainer,
  H4,
  Input,
  Label,
  TxtSpan,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";
import RangeSlider from "../FormInputs/RangeSlider";
import CheckboxInput2 from "../FormInputs/CheckboxInput2";

const Filter = ({ filterType, setFilters }) => {
  const [open, setOpen] = useState(filterType.selectedValues?.length > 0);

  const handleCheckboxChange = (filterValue, checked) => {
    setFilters((prev) => {
      const index = prev.findIndex((item) => item.name === filterType.name);
      // Filter type not found, return previous filters as is
      if (index === -1) {
        return prev;
      }

      const updatedFilters = [...prev];
      const selectedValues = updatedFilters[index].selectedValues;

      // Add filter value if checked and not already included
      if (checked && !selectedValues.includes(filterValue)) {
        selectedValues.push(filterValue);
      } else if (!checked) {
        // Remove filter value if unchecked
        const filterIndex = selectedValues.indexOf(filterValue);
        if (filterIndex !== -1) {
          selectedValues.splice(filterIndex, 1);
        }
      }

      // Update selectedValues in the filter
      updatedFilters[index] = {
        ...updatedFilters[index],
        selectedValues: selectedValues,
      };
      console.log("updatedFilters", updatedFilters);
      return updatedFilters;
    });
  };

  const handleFilter = (e) => {
    return setFilters((prev) => {
      console.log("prev Filter", prev);
      const prevData = [...prev];
      const index = prevData.findIndex((item) => item.name === filterType.name);

      if (index !== -1) {
        if (
          e.target.id === "max" &&
          parseInt(e.target.value) <= prevData[index].max &&
          parseInt(e.target.value) > prevData[index].minValue
        ) {
          prevData[index].maxValue = e.target.value;
        }
        if (
          e.target.id === "min" &&
          parseInt(e.target.value) >= prevData[index].min &&
          parseInt(e.target.value) < prevData[index].maxValue
        ) {
          prevData[index].minValue = e.target.value;
        }
      }

      return prevData;
    });
  };

  return (
    <FlexContainer
      $flexD="column"
      $width="100%"
      $padding="2vh 0"
      $gap="1.25vh"
      // $mGap="3vh"
      $justifyC="center"
      // $border="2px solid red"
    >
      <FlexContainer $justifyC="space-between" $align="center" >
        <H4 $color="black" $fSize="1.25vw" $mFSize="5vw" $fWeight="200">
          {filterType.name}
        </H4>
        <CustomButton onClick={() => setOpen(!open)} $padding=".5vh">
          <Arrow
            $direction={open ? "up" : "down"}
            $bColor="#2B2B2B"
            $bWidth=".25vh"
            $padding=".5vh"
          />
        </CustomButton>
      </FlexContainer>
      <FlexContainer
        $mDisplay={open ? null : "none"}
        $flexD="column"
        $gap="1.5vh"
        $mGap="3vh"
        $align="flex-start"
        $mPadding="2vh 0"
        // $border="2px solid red"
      >
        {open && filterType.type !== "range" ? (
          filterType.values?.map((filterValue) => (
            <CheckboxInput2
              key={filterValue}
              value={filterValue}
              isChecked={filterType.selectedValues.includes(filterValue)}
              handleChange={handleCheckboxChange}
            />
          ))
        ) : open && filterType.type === "range" ? (
          <FlexContainer $gap=".5vh" $flexD="column" $width="100%">
            <RangeSlider
              min={filterType.min}
              max={filterType.max}
              minValue={filterType.minValue}
              maxValue={filterType.maxValue}
              handleChange={handleFilter}
              step={0.15}
            />
            <FlexContainer $gap="1vw">
              <FlexContainer $gap=".25vw">
                <Label>From</Label>
                <Input
                  $border={`1px solid ${theme.shadowColor}`}
                  $width="2vw"
                  $bRadius="6px"
                  id="min"
                  value={filterType.minValue}
                  onChange={handleFilter}
                />
                <TxtSpan>cm</TxtSpan>
              </FlexContainer>
              <FlexContainer>
                <Label>To</Label>
                <Input
                  $border={`1px solid ${theme.shadowColor}`}
                  $width="2vw"
                  $bRadius="6px"
                  id="max"
                  value={filterType.maxValue}
                  onChange={handleFilter}
                />
                cm
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        ) : null}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Filter;
