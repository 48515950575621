import React, { useContext, useState } from "react";
import {
  Dot,
  FlexContainer,
  FlexH4,
  H4,
  InlineButton,
  Input,
  MainButton,
  SecButton,
  TxtSpan,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";
import { CartContext } from "../../appContext/CartContext";
import { FaRegHeart } from "react-icons/fa6";

const ProductPricing = ({ product }) => {
  const { setCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  return (
    <FlexContainer
      $width="20vw"
      $mWidth="90vw"
      $height="max-content"
      $bRadius="12px"
      $flexD="column"
      $margin="5vh 0"
      $mMargin="0"
      $padding="1vh .5vw"
      $mPadding="2vh 2vw"
      $gap="2vh"
      $border="2px solid green"
      $mBorder="none"
      $bShadow={`0 0 4px ${theme.shadowColor}`}
    >
      <FlexH4 $justifyC="space-between">
        <TxtSpan $fStyle="italic" $fSize="3vh" $mFSize="5vw">
          Starts from
        </TxtSpan>
        <FlexContainer $gap=".25vw">
          <TxtSpan $fSize="3vh" $mFSize="3.5vw" style={{ marginTop: "-1vh" }}>
            €
          </TxtSpan>
          <TxtSpan $fSize="3vh" $mFSize="6.5vw">
            1.99
          </TxtSpan>
          <TxtSpan
            $fSize="1.5vh"
            $mFSize="2.5vw"
            $color="secColor"
            style={{ marginTop: "1vh" }}
          >
            per item
          </TxtSpan>
        </FlexContainer>
      </FlexH4>
      <FlexContainer
        $flexD="column"
        $gap=".5vh"
        $padding="1vh 1vw"
        $mPadding="1vh 7vw"
        // $border="2px solid yellow"
      >
        <FlexContainer
          $align="center"
          $justifyC="center"
          $mJustifyC="space-between"
          $gap="1vw"
          // $border="2px solid blue"
        >
          <H4 $fSize="2.5vh" $mFSize="4.5vw">
            Quantity:
          </H4>
          <FlexContainer $align="center" $gap=".25vw" $mGap="3vw">
            <InlineButton
              $fSize="2.5vh"
              $mFSize="5vw"
              disabled={parseInt(quantity) === 1}
              onClick={() => setQuantity(quantity - 1)}
            >
              -
            </InlineButton>
            <Input
              type="number"
              $width="2.5vw"
              $mWidth="10vw"
              $mFSize="5vw"
              $bRadius="3px"
              $border={`1px solid ${theme.shadowColor}`}
              $bgColor="bgColor"
              value={quantity}
              style={{ textAlign: "center" }}
              onChange={(e) => setQuantity(e.target.value)}
            />
            <InlineButton
              $fSize="2.5vh"
              $mFSize="5vw"
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </InlineButton>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer $align="center" $justifyC="center">
          <Dot $width=".5vh" $height=".5vh" />
          <TxtSpan $fSize=".65vw" $mFSize="3.5vw" $width="6.5vw" $mWidth="40vw">
            Min order quantity:
          </TxtSpan>
          <TxtSpan $fSize=".65vw" $mFSize="3.5vw">
            10 items
          </TxtSpan>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer $flexD="column" $align="center" $gap="1vh">
        <MainButton
          $bRadius="12px"
          $fSize="2vh"
          $mFSize="5vw"
          $padding=".5vh 0"
          $width="15vw"
          $mWidth="75vw"
          onClick={() => setCart((prev) => [...prev, product])}
        >
          Add to Qoute
        </MainButton>
        <SecButton
          $bRadius="12px"
          $fSize=".65vw"
          $mFSize="5vw"
          $padding=".5vh 0vw"
          $width="10vw"
          $mWidth="75vw"
          $gap=".25vw"
          $mGap="1vw"
        >
          Save <FaRegHeart />
        </SecButton>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProductPricing;
